<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <span class="title-text h2">
      Add Translator
    </span>
  </div>

  <div class="white-box">
    <form fxLayout="column" [formGroup]="addTranslatorForm" name="addTranslatorForm">
      <div class="boxBody">
        <div class="boxBodyInn">
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field   fxFlex="100">
              <mat-label class="font-size-12">Email*</mat-label>
              <input  matInput formControlName="email">
              <mat-error *ngIf="addTranslatorForm.get('email').hasError('required')">
                Email is required
              </mat-error>
              <mat-error *ngIf="!addTranslatorForm.get('email').hasError('required') &&
                addTranslatorForm.get('email').hasError('email')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div>

          <h4>Personal Information</h4>
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field   fxFlex="80">
              <mat-label class="font-size-12">First Name*</mat-label>
              <input matInput formControlName="first_name">
              <mat-error *ngIf="addTranslatorForm.get('first_name').hasError('required')">
                First Name is required!
              </mat-error>
              <mat-error *ngIf="!addTranslatorForm.get('first_name').hasError('required') &&
            addTranslatorForm.get('first_name').hasError('maxlength')">
                First Name can have 30 characters Maximum!
              </mat-error>
            </mat-form-field>
            <mat-form-field   fxFlex="80">
              <mat-label class="font-size-12">Last Name*</mat-label>
              <input matInput formControlName="last_name">
              <mat-error *ngIf="addTranslatorForm.get('last_name').hasError('required')">
                Last Name is required!
              </mat-error>
              <mat-error *ngIf="!addTranslatorForm.get('last_name').hasError('required') &&
            addTranslatorForm.get('last_name').hasError('maxlength')">
                Last Name can have 30 characters Maximum!
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="boxBody">
        <div class="boxBodyInn">
          <h4>Language Combinations</h4>
          <mat-table [dataSource]="dataSource" class="tableGrey bdr1">
            <!-- Position Column -->
            <!-- Name Column -->
            <ng-container matColumnDef="langPair">
              <mat-header-cell *matHeaderCellDef> Language Pair </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination">
                {{lang_combination.sourcelanguage.data.lang_name }} {{lang_combination.bi_direction}}
                {{lang_combination.targetlanguage.data.lang_name}}
              </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="translationRate">
              <mat-header-cell *matHeaderCellDef> Translation Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.translation_rate}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="proofindRate">
              <mat-header-cell *matHeaderCellDef> Proofreading Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.proofreading_rate}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="dtpRate">
              <mat-header-cell *matHeaderCellDef> Dtp Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.dtp_rate}} </mat-cell>
            </ng-container>
            <!-- Symbol Column -->
            <ng-container matColumnDef="qaRate">
              <mat-header-cell *matHeaderCellDef> MTPE Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.qa_rate}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="speed">
              <mat-header-cell *matHeaderCellDef> Speed* </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.speed}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef><span *ngIf="type == 'Update'">Actions</span> </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination; let i=index;" fxHide fxShow.gt-xs>
                <button mat-stroked-button class="bicon" (click)="editData(lang_combination)" *ngIf="type == 'Update'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>
                </button>
                <button mat-stroked-button class="bicon" (click)="deleteData(lang_combination,i)" >
                  <mat-icon matTooltip="Click here to Delete">
                    delete
                  </mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let lang_combination; columns: displayedColumns;"></mat-row>
          </mat-table>
          <div class="input-row pt-20 pb-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center"
            fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
            <mat-form-field   fxFlex="20">
              <mat-label class="font-size-12">Source Language*</mat-label>
              <mat-select [(ngModel)]="data.source_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getSourceName($event)">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let res of languageData.data" [value]="res.id">
                  {{res.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field   fxFlex="20">
              <mat-label class="font-size-12">Target Language*</mat-label>
              <mat-select [(ngModel)]="data.target_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getTargetName($event)">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let language_res of languageData.data" [value]="language_res.id">
                  {{language_res.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field   fxFlex="12">
              <mat-label class="font-size-12">Translation Rate* </mat-label>
              <input matInput type="number" [(ngModel)]="data.translation_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field   fxFlex="12">
              <mat-label class="font-size-12">Proofreading Rate*</mat-label>
              <input matInput type="number" [(ngModel)]="data.proofreading_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field   fxFlex="12">
              <mat-label class="font-size-12">Dtp Rate</mat-label>
              <input matInput type="number" [(ngModel)]="data.dtp_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field   fxFlex="12">
              <mat-label class="font-size-12">MTPE Rate</mat-label>
              <input matInput type="number" [(ngModel)]="data.qa_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field   fxFlex="8">
              <mat-label class="font-size-12">Speed</mat-label>
              <input matInput type="number" [(ngModel)]="data.speed" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">Per Day</mat-hint>
            </mat-form-field>

            <div fxFlex="15" class="pt-16 text-center">
              <mat-checkbox [(ngModel)]="data.bi_direction" [value]="data.bi_direction"
                [ngModelOptions]="{standalone: true}" class="mt-8">Vice-versa</mat-checkbox>
            </div>
            <div fxFlex="5" class="pt-16">
              <button mat-flat-button color="accent" class="bicon" type="button" *ngIf="type == 'Add'"
                (click)="addLanguageTable()"> 
                <!-- <i class="material-icons">add</i> -->
                  Save
                   </button>
              <button mat-flat-button color="accent" type="button" class="bicon" *ngIf="type == 'Update'"
                (click)="addLanguageTableApi()">Save
                 <!-- <i class="material-icons"> add</i>  -->
                 </button>
            </div>
          </div>
        </div>
      </div>
      <div class="actWraper">
        <button mat-flat-button color="accent" type="button" (click)="submit()" [disabled]="addTranslatorForm.invalid"
          class="mr-8">
          <mat-label class="Text_Color">{{type}}</mat-label>
        </button>
        <button mat-flat-button (click)="Reset()">Cancel</button>
      </div>
    </form>
  </div>
</div>