<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
    <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
        <button
            color="accent"
            (click)="back()"
            matTooltip="Back"
            mat-icon-button
            class="mr-12 mat-back"
        >
            <mat-icon>keyboard_backspace</mat-icon>
        </button>

        <span class="title-text h1">
            {{ response_data.title }} {{ response_data.first_name }}
            {{ response_data.middle_name }} {{ response_data.last_name }}
            <span class="prfInfo">
                <span class="pr-8">Existing Application</span>
                <span class="prfo">
                    <span class="hText hYellow">HR Approved</span>
                </span>
                <span class="prfo">
                    <span class="pl-8 mr-10">{{response_data?.category_level}}</span>
                </span>
            </span>
        </span>
        <span class="title-text pt-40">
            <span class="prfInfo mr-10">
                <span class="pr-8 padding-none">AVT Status:</span>
                <span class="prfo border-none padding-none ">{{response_data?.avt_status}}</span>
                <span class="mr-10" *ngIf="response_data?.avt_status==null"> NA</span>
            </span>
        </span>
        <span class="title-text pt-40">
            <span class="prfInfo">
                <span class="pr-8 padding-none">Terping Status:</span>
                <span class="prfo border-none padding-none ">{{response_data?.treping_status}}</span>
                <span class="mr-10" *ngIf="response_data?.treping_status==null"> NA</span>
            </span>
        </span>
    </div>

    <div class="white-box">
        <div class="profHeader p20 bb1">
            <div
                class="container"
                fxLayout
                fxLayout.xs="column"
                fxLayoutAlign="left"
                fxLayoutGap="10px"
                fxLayoutGap.xs="0"
            >
                <div class="item item-1" fxFlex="auto">
                    <div class="profImage">
                        <img
                            [src]="vendor_file_data"
                            onerror="this.src='assets/images/avatars/profile.jpg'"
                            width="120"
                        /><br />
                    </div>
                    <h2 class="aHead">
                        Native in:
                        <span class="txt-green">{{response_data?.vendornativelanguage?.data?.lang_name}}</span>
                    </h2>
                </div>

                <div class="item item-2" fxFlex="auto">
                    <div class="invAdd">
                        <h2>
                            {{ response_data.title }}
                            {{ response_data.first_name }}
                            {{ response_data.middle_name }}
                            {{ response_data.last_name }}
                        </h2>
                        <div
                            class="rowcon"
                            fxLayout
                            fxLayout.xs="column"
                            fxLayoutAlign="left"
                            fxLayoutGap="10px"
                            fxLayoutGap.xs="0"
                        >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Address:</b></p>
                            </div>

                            <div class="item text-left" fxFlex="50%">
                                <p>
                                    {{ address_test }},
                                    {{ response_data.city }},
                                    {{ response_data?.state }},
                                    {{ response_data?.postal_code }},
                                    {{ response_data?.country }}
                                </p>
                            </div>
                        </div>
                        <div
                            class="rowcon"
                            fxLayout
                            fxLayout.xs="column"
                            fxLayoutAlign="left"
                            fxLayoutGap="10px"
                            fxLayoutGap.xs="0"
                        >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Email:</b></p>
                            </div>

                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data.email }}</p>
                            </div>
                        </div>

                        <div
                            class="rowcon"
                            fxLayout
                            fxLayout.xs="column"
                            fxLayoutAlign="left"
                            fxLayoutGap="10px"
                            fxLayoutGap.xs="0"
                        >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Skype ID:</b></p>
                            </div>

                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data.skype_id }}</p>
                            </div>
                        </div>

                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Phone:</b></p>
                            </div>

                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.phone }}</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Avt Status:</b></p>
                            </div>

                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.avt_status }}</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Terping Status:</b></p>
                            </div>

                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.treping_status }}</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Important notes:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.important_note }}</p>
                                <p *ngIf="response_data?.important_note==null">NA</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Minimum Fee:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{response_data?.info}}</p>
                                <p *ngIf="response_data?.info==null">NA</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>PR Scores:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.pr_scores }}</p>
                                <p *ngIf="response_data?.pr_scores==null">NA</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Minimum time:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.minimum_time }}</p>
                                <p *ngIf="response_data?.minimum_time==null">NA</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Travel cost:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.travel_cost }}</p>
                                <p *ngIf="response_data?.travel_cost==null">NA</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Distance willing to travel:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.distance_willing_to_travel }}</p>
                                <p *ngIf="response_data?.distance_willing_to_travel==null">NA</p>
                            </div>
                        </div>
                        <div class="rowcon" fxLayout fxLayout.xs="column" fxLayoutAlign="left" fxLayoutGap="10px" fxLayoutGap.xs="0" >
                            <div class="item item-2 text-right" fxFlex="50%">
                                <p><b>Cancellation cost:</b></p>
                            </div>
                            <div class="item text-left" fxFlex="50%">
                                <p>{{ response_data?.cancellation_cost }}</p>
                                <p *ngIf="response_data?.cancellation_cost==null">NA</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="boxBody">
            <div class="boxBodyInn">
                <h4>TRANSLATION SERVICE</h4>

                <div class="lanPirInfo">
                    <div class="content">
                        <table class="simple lan-table">
                            <thead>
                                <tr>
                                    <th>Language Combinations</th>
                                    <th class="text-right">Translation<span class="smlText pt-0">Rate(USD) / Word</span></th>
                                    <th class="text-right">Proofreading<span class="smlText pt-0">Rate (USD) / Word</span></th>
                                    <th class="text-right">DTP <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                                    <th class="text-right">MTPE <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                                    <th class="text-right">Speed <span class="smlText pt-0"> Words / Day</span></th>
                                    <th class="text-right">Sworn </th>
                                    <th class="text-right">Ata certified</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let langs of response_data?.vendortranslationlangs?.data">
                                    <td><p *ngIf="langs.lang_data != null">{{ langs.lang_data }}</p></td>
                                    <td class="text-right"><p *ngIf="langs.translation_rate != null">${{ langs.translation_rate }} USD Per word</p></td>
                                    <td class="text-right"><p *ngIf="langs.proofreading_rate != null" >${{ langs.proofreading_rate }} USD Per word</p></td>
                                    <td class="text-right"><p *ngIf="langs.dtp_rate != null" >${{ langs.dtp_rate }} USD Per word</p></td>
                                    <td class="text-right"><p *ngIf="langs.qa_rate != null" >${{ langs.qa_rate }} USD Per word</p></td>
                                    <td class="text-right"><p *ngIf="langs.speed != null" >{{ langs.speed }} words per day</p></td>
                                    <td class="text-right">
                                        <p *ngIf='langs.sworn=="1"'>Yes</p>
                                        <p *ngIf='langs.sworn=="0"'>No</p>
                                    </td>
                                    <td class="text-right">
                                        <p *ngIf='langs.ata_certified=="1"'>Yes</p>
                                        <p *ngIf='langs.ata_certified=="0"'>No</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h4>AVT</h4>
                        <table class="simple lan-table">
                            <thead>
                                <tr>
                                    <th>Language Combinations</th>
                                    <th>Service</th>
                                    <th>Rate<span class="smlText pt-0">Rate (USD) / minute</span></th>
                                    <th>Rate<span class="smlText pt-0">Rate (USD) / word</span></th>
                                    <th>Speed<span class="smlText pt-0">Words / Day</span></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let subtitlerslang of response_data?.vendorSubtitler?.data">
                                <td>
                                    <p *ngIf="subtitlerslang.subtiterslang_data!=null">
                                        {{subtitlerslang.subtiterslang_data}}
                                    </p>
                                </td>
                                <td>
                                    <p *ngIf="subtitlerslang.skillservice.data.name!=null">{{subtitlerslang.skillservice.data.name}}</p>
                                </td>
                                <td>
                                    <p *ngIf="subtitlerslang.subtitle_rate!=null">
                                        ${{subtitlerslang.subtitle_rate}} USD Per minute
                                    </p>
                                </td>
                                <td>
                                    <p *ngIf="subtitlerslang.per_word!=null">
                                        ${{subtitlerslang.per_word}} USD Per word
                                    </p>
                                </td>
                                <td>
                                    <p *ngIf="subtitlerslang.speed!=null">
                                        {{subtitlerslang.speed}}
                                        <span *ngIf="subtitlerslang.skill_service_id==68">words/minutes per day</span>
                                        <span *ngIf="subtitlerslang.skill_service_id!=68">minutes per day</span>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                        </table>

                        <h4>Terping</h4>
                        <table class="simple lan-table">
                            <thead>
                                <tr>
                                    <th>Language Combinations</th>
                                    <th>Service</th>
                                    <th>Rate<span class="smlText pt-0">Rate (USD) / hours</span></th>
                                    <th>Rate<span class="smlText pt-0">Rate (USD) / minute</span></th>
                                    <th>Speed<span class="smlText pt-0">Words / Day</span></th>
                                    <th>Flexible rate <span class="smlText pt-0"> </span></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let trepinglang of response_data?.vendorTreping?.data">
                                <td>
                                    <p *ngIf="trepinglang.treplang!=null">
                                        {{trepinglang.treplang}}
                                    </p>
                                </td>
                                <td>
                                    <p *ngIf="trepinglang.trepingSkillservice.data.name!=null">{{trepinglang.trepingSkillservice.data.name}}</p>
                                </td>
                                <td>
                                    <p *ngIf="trepinglang.rate_hour!=null">
                                        ${{trepinglang.rate_hour}} USD Per hour
                                    </p>
                                </td>
                                <td>
                                    <p *ngIf="trepinglang.rate_min!=null">
                                        ${{trepinglang.rate_min}} USD Per minute
                                    </p>
                                </td>
                                <td>
                                    <p *ngIf="trepinglang.speed!=null">
                                        {{trepinglang.speed}}
                                    </p>
                                </td>
                                <td>
                                    <div *ngIf="trepinglang.flexible_rate!=null">
                                        <p *ngIf='trepinglang.flexible_rate=="1"'>Yes</p>
                                        <p *ngIf='trepinglang.flexible_rate=="0"'>No</p>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div class="box-blank">
                            <p><b>Speciality</b></p>
                            <p>
                                {{ specialities_test }}
                            </p>
                        </div>
                        <div class="box-blank" *ngIf="skills">
                            <p><b>Skills</b></p>
                            <p>
                                {{skills}}
                            </p>
                        </div>
                    </div>

                    <h4>Technology</h4>

                    <p>
                        <b>Do you use CAT (Computer Assisted Translation) tools?</b>
                        <span>
                            <p *ngIf="response_data.translation_tools == 1">
                                No
                            </p>
                            <p *ngIf="response_data.translation_tools == 2">
                                Yes
                            </p>
                            <p *ngIf="response_data?.translation_tools==3">
                                Yes, but I will not use them unless requested
                            </p>
                        </span>
                        <b>Valid license :</b>
                        <span>
                            <p *ngIf="response_data?.tool_licence == '1'">
                                Yes
                            </p>
                            <p *ngIf="response_data?.tool_licence == '0'">
                                No
                            </p>
                        </span>

                        <b>Tool Used:</b>
                        <span>
                            <p *ngIf="response_data?.tool_names">
                                {{response_data?.tool_names}}
                            </p>
                            <p *ngIf="response_data?.tool_names== null">
                                NA
                            </p>
                        </span>

                        <b>Program/Software used for subtitles:</b>
                        <span>
                            <p *ngIf="response_data?.subtitles_program_software">
                                {{response_data?.subtitles_program_software}}
                            </p>
                            <p *ngIf="response_data?.subtitles_program_software== null">
                                NA
                            </p>
                        </span>
                    </p>
                        <h4 *ngIf="response_data?.payment_method || response_data?.payment_method_id">Financials</h4>
                        <p *ngIf="response_data?.payment_method"><b>Payment Method :</b> {{ response_data?.payment_method }}</p>
                        <p *ngIf="response_data?.payment_method_id"><b>Payment Method Email:</b> {{ response_data?.payment_method_id}}</p>



                        <h4>Days Available</h4>

                        <p *ngIf="day_name_test!=null || day_name_test!=''"> {{ day_name_test }}</p>
                        <p *ngIf="day_name_test==null || day_name_test==''"> NA</p>

                        <h4>Notes yourself</h4>
                        <p *ngIf="response_data?.education!=null || response_data?.education!=''"> {{ response_data?.education }}</p>
                        <p *ngIf="response_data?.education==null || response_data?.education==''"> NA</p>

                        <h4>Detailed cover letter</h4>
                        <p *ngIf="response_data?.cover_letter!=null || response_data?.cover_letter!=''"> {{response_data?.cover_letter }}</p>
                        <p *ngIf="response_data?.cover_letter==null || response_data?.cover_letter==''"> NA</p>


                        <h4>Resume</h4>
                        <div class="box-blank" *ngIf="vendor_resume_name!=null">
                            <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank" download>
                                    <mat-icon>vertical_align_bottom</mat-icon>
                                </a>
                            </p>
                        </div>
                        <p *ngIf="vendor_resume_name==null"> No record <a>
                                <mat-icon>vertical_align_bottom</mat-icon>
                            </a>
                        </p>
                        <h4>Contract</h4>
                        <!-- <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data" target="_blank"
                                download>
                                <mat-icon>vertical_align_bottom</mat-icon>
                            </a>
                        </p> -->
                       
                        <div class="box-blank" *ngIf="vendor_contract_name!=null">
                            <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
                                    target="_blank" download>
                                    <mat-icon>vertical_align_bottom</mat-icon>
                                </a>
                            </p>
                        </div>
                        <p *ngIf="vendor_contract_name==null"> No record</p>

                        <h4>Coa Files</h4>

                        <div class="box-blank" *ngIf="coa_array?.length>0">
                            <span *ngFor="let resCoa of coa_array">
                                <p>{{resCoa.name}} <a [href]="resCoa.url" target="_blank" download>
                                        <mat-icon>vertical_align_bottom</mat-icon>
                                    </a></p>
                                <p *ngIf="coa_array?.length ==0"> No record
                                </p>
                            </span>
                        </div>

                        <h4>Extra Document</h4>
                        <div class="box-blank" *ngIf="vendor_exta_document_name!=null">
                            <p *ngIf="vendor_exta_document_name!=null"> {{vendor_exta_document_name}} <a [href]="vendor_exta_document_data"
                                    target="_blank" download>
                                    <mat-icon>vertical_align_bottom</mat-icon>
                                </a>
                            </p>
                        </div>


                        <!-- <b>Important Notes :</b>
                        <span>
                            <p>
                                {{ response_data?.important_note }}
                            </p>
                            <p *ngIf="response_data?.important_note == null">
                                NA
                            </p>
                        </span> -->
                    

                    <!-- <h4>Financials</h4> -->

                    <!-- <p>
                        <b>Payment Method :</b>
                        {{ response_data.payment_method }}
                    </p> -->
                </div>

                <!-- <h4>Days Available</h4>

                <p>{{ day_name_test }}</p> -->

                <!-- <h4>Notes yourself</h4>

                <p>{{ response_data.education }}</p> -->

                <!-- <h4>Detailed cover letter</h4>

                <p>{{ response_data.cover_letter }}</p> -->

                <!-- <h4 *ngIf="vendor_resume_data">Resume</h4>
                <p *ngIf="vendor_resume_data">
                    {{ vendor_resume_name }}
                    <a [href]="vendor_resume_data" target="_blank" download>
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </a>
                </p>
                <h4 *ngIf="vendor_contract_data">Contract</h4>

                <p *ngIf="vendor_contract_data">
                    {{ vendor_contract_name }}
                    <a [href]="vendor_contract_data" target="_blank" download>
                        <mat-icon>vertical_align_bottom</mat-icon>
                    </a>
                </p> -->
                <!-- <p> <b>Terms and User agreement acceptence:</b> </p> -->

                <h4>Statics</h4>

                <h4>Comments History</h4>
                <div class="boxBody2" *ngIf="vendorcomment_data?.length != 0">
                    <div class="boxBodyInn2">
                        <div class="commentDiv" *ngFor="let res of vendorcomment_data">
                            <div  class="quesDiv" *ngIf="res.user.data.user_group_id == 'Vendor'" >
                                <p>
                                    <b>
                                        {{ res.user.data.fullname }} ({{
                                            res.user.data.user_group_id
                                        }})
                                    </b>
                                    <span class="tStamp">
                                        {{
                                            res.created_at.date | date: "short"
                                        }}
                                    </span>
                                </p>
                                <p>{{ res.comment }}</p>
                            </div>

                            <div class="ansDiv" *ngIf="res.user.data.user_group_id != 'Vendor'" >
                                <div class="divinn">
                                    <p>
                                        <b>
                                            {{ res.user.data.fullname }} ({{
                                                res.user.data.user_group_id
                                            }})
                                        </b>
                                        <span class="tStamp">
                                            {{
                                                res.created_at.date
                                                    | date: "short"
                                            }}
                                        </span>
                                    </p>
                                    <p>{{ res.comment }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="actWraper">
            <h4 class="text-left">Action</h4>

            <form name="Form" [formGroup]="Form">
                <mat-form-field>
                    <mat-label>Special Comments if any</mat-label>
                    <textarea
                        matInput
                        rows="5"
                        formControlName="comment_admin"
                    ></textarea>
                </mat-form-field>

                <p>
                    <button
                        matTooltip="Approve"
                        mat-raised-button
                        type="submit"
                        (click)="submitDataApprove(6)"
                        [disabled]="Form.invalid"
                        class="green-bg mr-8"
                    >
                        <mat-label class="Text_Color">Approve</mat-label>
                    </button>

                    <button
                        color="warn"
                        mat-stroked-button
                        matTooltip="Reject"
                        (click)="submitDataReject(8)"
                        [disabled]="Form.invalid"
                    >
                        Reject
                    </button>
                </p>
            </form>
        </div>
    </div>
</div>
