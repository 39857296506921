<div class="page-layout w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back" [routerLink]="'/pms/freelancer/'">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>


    <span class="title-text h1">
      {{profileData?.title}} {{profileData?.first_name}} {{profileData?.middle_name}} {{profileData?.last_name}}
      <span class="prfInfo">
        <span><b>Native Language:</b>
          <span> {{profileData?.vendornativelanguage?.data?.lang_name}} </span>
        </span>
    
        <span class="prfo" *ngIf="profileData?.category_level!=null">
          <span  class="mr-10 pl-8 hText {{profileData?.category_level | lowercase}}" >
            {{profileData?.category_level}}
          </span>
        </span>
      </span>
    </span>

    <span class="title-text pt-40">
      <span class="prfInfo">
        <span><b>AVT Status:</b></span>
        <span class="prfo no-border" *ngIf="profileData?.avt_status!=null">
          <span  class="mr-10 pl-8 hText {{profileData?.avt_status | lowercase}}" >
            {{profileData?.avt_status}}
          </span>
        </span>
        <span class="mr-10" *ngIf="profileData?.avt_status==null">NA</span>
      </span>
    </span>
    <span class="title-text pt-40">
      <span class="prfInfo">
        <span><b>Terping Status:</b></span>
        <span class="prfo no-border" *ngIf="profileData?.treping_status!=null">
          <span  class="mr-10 pl-8 hText {{profileData?.treping_status | lowercase}}" >
            {{profileData?.treping_status}}
          </span>
        </span>
        <span class="mr-10" *ngIf="profileData?.treping_status==null">NA</span>
      </span>
    </span>
  </div>





  <div class="white-box">

    <div class="boxBody">


      <div class="boxBodyInn custInfo">

        <div class="container" fxLayout fxLayoutAlign="start" fxLayoutGap="10px">

          <div class="frlncrPic" fxFlex="15">
            <img [src]="vendor_file_data" onerror="this.src='assets/images/avatars/profile.jpg'" width="120" /><br />
          </div>


          <div class="item item-1" fxFlex>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Name: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{profileData?.title}} {{profileData?.first_name}} {{profileData?.middle_name}}
                    {{profileData?.last_name}}</b></p>
              </div>

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Email: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{profileData?.email}}</b></p>
              </div>

            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Secondary email: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>
                  <b *ngIf="profileData?.email_sec !=''">{{profileData.email_sec}}</b>
                </p>
                  <p *ngIf="profileData?.email_sec ==''">NA</p>
              </div>

            </div>

            
            
            <!-- <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Timezone: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{profileData?.timezone}}</b></p>
              </div>
            </div> -->


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Services: </p>
              </div>
              <div class="item item-1" fxFlex>
              <p *ngIf="skill_name!=''"><b>{{skill_name}}</b></p> 
              <p *ngIf="skill_name==''">NA</p> 
              </div>

            </div>


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Phone: </p>
              </div>
              <div class="item item-1" fxFlex>

                <p><b *ngIf="profileData?.phone">{{profileData.phone}}</b> <b *ngIf="profileData?.phone_sec">,{{profileData.phone_sec}}</b> </p>
              </div>

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Address: </p>
              </div>
              <div class="item item-1" fxFlex>
                {{profileData?.address1}} {{profileData?.address2}} {{profileData?.country}}
              </div>

            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Timezone: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="profileData?.timezone !='' && profileData?.timezone !=null">{{profileData?.timezone}}</p>
                <p *ngIf="profileData?.timezone =='' || profileData?.timezone ===null">Na</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>VO sample: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="profileData?.vo_sample !='' && profileData?.vo_sample !=null">
                  <a [href]="profileData?.vo_sample" target="_blank">Sample link here
                    <!-- <mat-icon>vertical_align_bottom</mat-icon> -->
                  </a>
                </p>
                <p *ngIf="profileData?.vo_sample =='' || profileData?.vo_sample===null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Voiceover Category: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="profileData?.voiceover_category !='' && profileData?.voiceover_category !=null">
                  {{profileData?.voiceover_category}}
                </p>
                <p *ngIf="profileData?.voiceover_category =='' || profileData?.voiceover_category===null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Voiceover Notes: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="profileData?.voiceover_notes !='' && profileData?.voiceover_notes !=null">
                  {{profileData?.voiceover_notes}}
                </p>
                <p *ngIf="profileData?.voiceover_notes =='' || profileData?.voiceover_notes===null">NA</p>
              </div>
            </div>
            <b>Rates for client:</b>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Untimed VO Recording: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="profileData?.untimed_vo_recording !='' && profileData?.untimed_vo_recording !=null">
                  <b>${{profileData?.untimed_vo_recording | number : '1.2-2'}} per minute</b>
                </p>
                <!-- <rating [(ngModel)]="profileData.untimed_vo_recording" [disabled]="false" [readonly]="true"
                  [titles]="['one', 'two', 'three', 'four', 'five']"></rating> -->
                <p *ngIf="profileData?.untimed_vo_recording =='' || profileData?.untimed_vo_recording===null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Timed VO Recording: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="profileData?.timed_vo_recording !='' && profileData?.timed_vo_recording !=null">
                  <b>${{profileData?.timed_vo_recording | number : '1.2-2'}} per minute</b>
                </p>
                <!-- regular rating -->
                <!-- <rating [(ngModel)]="profileData.timed_vo_recording" [disabled]="false"  [readonly]="true" [titles]="['one', 'two', 'three', 'four', 'five']"></rating> -->
                <p *ngIf="profileData?.timed_vo_recording =='' || profileData?.timed_vo_recording===null">NA</p>
              </div>
            </div>
            

          </div>

          <div class="item item-2" fxFlex>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>PR Scores: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.pr_scores}}</p>
                <p *ngIf="profileData?.pr_scores==null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Important Notes: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.important_note}}</p>
                <p *ngIf="profileData?.important_note==null">NA</p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Minimum Fee: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.info}}</p>
                <p *ngIf="profileData?.info==null">NA</p>
              </div>
            </div>
            <!-- <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>AVT Status: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.avt_status}}</p>
                <p *ngIf="profileData?.avt_status==null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Treping Status: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.treping_status}}</p>
                <p *ngIf="profileData?.treping_status==null">NA</p>
              </div>
            </div> -->
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Minimum time: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.minimum_time}}</p>
                <p *ngIf="profileData?.minimum_time==null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Travel cost: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.travel_cost}}</p>
                <p *ngIf="profileData?.travel_cost==null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="25px">
              <div class="text-right mr-5" fxFlex="31">
                <p>Distance willing to travel: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.distance_willing_to_travel}}</p>
                <p *ngIf="profileData?.distance_willing_to_travel==null">NA</p>
              </div>
            </div>
            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Cancellation cost: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>{{profileData?.cancellation_cost}}</p>
                <p *ngIf="profileData?.cancellation_cost==null">NA</p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Account Type: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p>NA</p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Days Available: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="day_name_test!=''"><b>{{ day_name_test }}</b></p>
                <p *ngIf="day_name_test==''">NA</p>
              </div>
            </div>


            <!-- <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Category </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b>{{profileData?.type}}</b></p>
              </div>
            </div> -->

            <!-- <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Status: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p><b class="bdg blank">{{profileData?.stage?.data?.name}}</b></p>
              </div>

            </div> -->


            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Total Earned: </p>
              </div>
              <div class="item item-1" fxFlex>
              NA
              </div>

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">

              <div class="text-right" fxFlex="30">
                <p>Contract: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="vendor_contract_name!=null"> {{vendor_contract_name}} <a [href]="vendor_contract_data"
                    target="_blank" download>
                    <mat-icon>vertical_align_bottom</mat-icon>
                  </a>
                </p>
                <p *ngIf="vendor_contract_name==null">No data available


                </p>
              </div>

            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Resume: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="vendor_resume_name!=null"> {{vendor_resume_name}} <a [href]="vendor_resume_data"
                    target="_blank" download>
                    <mat-icon>vertical_align_bottom</mat-icon>
                  </a>
                </p>
                <p *ngIf="vendor_resume_name==null">No data available</p>
              </div>
            </div>

            <div class="container" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="text-right" fxFlex="30">
                <p>Extra document: </p>
              </div>
              <div class="item item-1" fxFlex>
                <p *ngIf="vendor_exta_document_name!=null"> {{vendor_exta_document_name}} <a [href]="vendor_exta_document_data"
                    target="_blank" download>
                    <mat-icon>vertical_align_bottom</mat-icon>
                  </a>
                </p>
                <p *ngIf="vendor_exta_document_name==null">No data available</p>
              </div>
            </div>


          </div>



        </div>


        <h2> Language Information</h2>
        <p><b>Native Language:</b> {{profileData?.vendornativelanguage?.data?.lang_name}}</p>
        <div class="lanPirInfo">
          <div class="content">


            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Language Combinations</th>
                  <th class="text-right">Translation <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                  <th class="text-right">Proofreading <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                  <th class="text-right">DTP <span class="smlText pt-0"> Rate(USD) / Word</span></th>
                  <th class="text-right">MTPE <span class="smlText pt-0"> Rate (USD) / Word </span></th>
                  <th class="text-right">Speed <span class="smlText pt-0"> Words / Day</span></th>
                  <th class="text-right">Sworn</th>
                  <th class="text-right">ATA certified</th>


                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let langs of data?.vendortranslationlangs?.data">
                  <td>
                    <p *ngIf="langs.lang_data!=null">
                      {{langs.lang_data}}
                    </p>
                  </td>

                  <td class="text-right">
                    <p *ngIf="langs.translation_rate!=null">
                      ${{langs.translation_rate}} USD Per word
                    </p>

                  </td>
                  <td class="text-right">
                    <p *ngIf="langs.proofreading_rate!=null">
                      ${{langs.proofreading_rate}} USD Per word
                    </p>
                  </td>
                  <td class="text-right">
                    <p *ngIf="langs.dtp_rate!=null">
                      ${{langs.dtp_rate}} USD Per word
                    </p>
                  </td>
                  <td class="text-right">
                    <p *ngIf="langs.qa_rate!=null">
                      ${{langs.qa_rate}} USD Per word
                    </p>

                  </td>
                  <td class="text-right">
                    <p *ngIf="langs.speed!=null">
                      {{langs.speed}} words per day
                    </p>
                  </td>
                  <td class="text-right">
                    <p *ngIf='langs.sworn=="1"'>Yes</p>
                    <p *ngIf='langs.sworn=="0"'>No</p>
                  </td>
                  <td class="text-right">
                    <p *ngIf='langs.ata_certified=="1"'>Yes</p>
                    <p *ngIf='langs.ata_certified=="0"'>No</p>
                  </td>

                </tr>

              </tbody>
            </table>


            <p><b>AVT</b></p>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Language Combinations</th>
                    <th>Service</th>
                    <th>Rate <span class="smlText pt-0"> Rate (USD) / minute </span></th>
                    <th>Rate <span class="smlText pt-0"> Rate (USD) / word </span></th>
                    <th>Speed <span class="smlText pt-0"> Words / Day</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subtitlerslang of data?.vendorSubtitler?.data">
                    
                    <td>
                      <p *ngIf="subtitlerslang.subtiterslang_data!=null">
                        {{subtitlerslang.subtiterslang_data}}
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.skillservice!=null">
                        {{subtitlerslang.skillservice.data.name}}
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.subtitle_rate!=null">
                        ${{subtitlerslang.subtitle_rate}} USD Per minute
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.per_word!=null">
                        ${{subtitlerslang.per_word}} USD Per word
                      </p>
                    </td>
                    <td>
                      <p *ngIf="subtitlerslang.speed!=null">
                        {{subtitlerslang.speed}}
                        <span *ngIf="subtitlerslang.skill_service_id==68">words/minutes per day</span>
                        <span *ngIf="subtitlerslang.skill_service_id!=68">minutes per day</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>


            <h4>Terping</h4>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Language Combinations</th>
                  <th>Service</th>
                  <th>Rate<span class="smlText pt-0">Rate (USD) / hours</span></th>
                  <th>Rate<span class="smlText pt-0">Rate (USD) / minute</span></th>
                  <!-- <th>Speed<span class="smlText pt-0">Words / Day</span></th> -->
                  <th>Flexible rate <span class="smlText pt-0"> </span></th>
                </tr>
              </thead>
              <tr *ngFor="let trepinglang of data?.vendorTreping?.data">
                <td>
                  <p *ngIf="trepinglang.treplang!=null">
                    {{trepinglang.treplang}}
                  </p>
                </td>
                <td>
                  <p *ngIf="trepinglang.trepingSkillservice.data.name!=null">{{trepinglang.trepingSkillservice.data.name}}</p>
                </td>
                <td>
                  <p *ngIf="trepinglang.rate_hour!=null">
                    ${{trepinglang.rate_hour}} USD Per hour
                  </p>
                </td>
                <td>
                  <p *ngIf="trepinglang.rate_min!=null">
                    ${{trepinglang.rate_min}} USD Per minute
                  </p>
                </td>
                <!-- <td>
                  <p *ngIf="trepinglang.speed!=null">
                    {{trepinglang.speed}} words per day
                  </p>
                </td> -->
                <td>
                  <div *ngIf="trepinglang.flexible_rate!=null">
                    <p *ngIf='trepinglang.flexible_rate=="1"'>Yes</p>
                    <p *ngIf='trepinglang.flexible_rate=="0"'>No</p>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="box-blank">
              <p><b>Speciality</b></p>
              <p>
                {{specialities_test}}
            </div>


          </div>

          <h4>Technology</h4>

          <p *ngIf="selected_tools"><b>Tools using: </b> {{selected_tools}}</p>


          <b>Boostlingo Affiliation :</b>
          <span>
            <p *ngIf="profileData?.boostlingo_affiliation=='1'">
              Yes
            </p>
            <p *ngIf="profileData?.boostlingo_affiliation=='0'">
              No
            </p>
          </span>


          <p><b>License: </b>{{profileData?.tool_licence}}</p>


          <h4>Financials</h4>

            <p><b> Skrill ID :</b>
            <span>{{profileData?.payment_method}}</span>
            <span *ngIf="profileData?.payment_method==null"> NA</span>
              <br />
            <b>Payment Email :</b> 
            <span>{{profileData?.payment_method_id}}</span>
            <span *ngIf="profileData?.payment_method_id==null"> NA</span>
           <br />
            
          </p>

          <h4>Personal information</h4>
            <p><b> Gender : </b>
            <span *ngIf="profileData?.gender==1">Male</span>
            <span *ngIf="profileData?.gender==2">Female</span>

            <p><b> Age : </b>
            <span>{{age}}</span>

            <p><b> Billing Address : </b>
            <span>{{profileData?.address1}} {{profileData?.address2}}</span>

            <p><b> Total Earned : </b>
            <span>NA</span>

            <p><b> Company Website: </b>
            <span>NA</span>

            <p><b> Important Note: </b>
            <span>{{profileData?.important_note}}</span>
        </div>
      </div>
    </div>
  </div>


  <div class="white-box">
    <div class="tHeader">
      <div fxLayout="row wrap">
        <div fxFlex>
          <h2>Project History</h2>
        </div>
        <div fxFlex class="text-right">
          Total <b>{{total_record}}</b> Records Available

          <!-- <button mat-stroked-button>Assign Project</button> -->
        </div>

      </div>

    </div>


    <div class="boxBody">
      <div class="boxInn" *ngIf="responseData.length>0">

        <table mat-table [dataSource]="dataSource" class="table">
          <!-- Name Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="pl-24"> ID </th>
            <td mat-cell *matCellDef="let element">
              <a routerLink="/pms/project/details/{{element?.uuid }}" title="View Details"> {{element?.ref}} </a>
            </td>
          </ng-container>


          <!-- Weight Column -->
          <ng-container matColumnDef="leadSrouce">
            <th mat-header-cell *matHeaderCellDef> Lead Srouce </th>
            <td mat-cell *matCellDef="let element"> {{element?.lead_source}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="projservices">
            <th mat-header-cell *matHeaderCellDef> Services</th>
            <td mat-cell *matCellDef="let element"> {{element?.service?.title}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="languagePair">
            <th mat-header-cell *matHeaderCellDef> Language Pair </th>
            <td mat-cell *matCellDef="let element">
              <p class="price text-truncate">
                <span *ngFor="let res of element.lang_array">
                  <p>{{res}} </p>
                </span>
              </p>
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="projStartDate">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let element"> {{element?.start_date | date: 'yyyy-MM-dd'}} </td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="projEndDate">
            <th mat-header-cell *matHeaderCellDef> End Date </th>
            <td mat-cell *matCellDef="let element"> {{element?.due_date | date: 'yyyy-MM-dd'}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="projStatus">
            <th mat-header-cell *matHeaderCellDef> Current Status </th>
            <td mat-cell *matCellDef="let element">
              <span class="ml-8" *ngIf="element.project_status!=''">
                {{ element.project_status }}

              </span>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total" [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[ 10, 15, 20]" (page)='paginateData($event)'>
    
        </mat-paginator>
      </div>
      <div *ngIf="total_record==0" class="noDataFound"> No data available!!
      </div>
      <!-- <div class="boxInn" *ngIf="responseData.length==0">
        <div class="mopt p-20 ">
          <p >No data available!!</p>
        </div>
    </div> -->
  </div>


  <div class="white-box" *ngIf="false">
    <div class="tHeader">
      <h2> COMMENTS / COMMUNICATION / SPECIAL INSTRUCTIONS</h2>
    </div>
    <div class="boxBody">

      <div class="boxBodyInn">
        <div class="commentDiv">
          <div class="quesDiv">
            <p><b>Dr. Winston Choi:</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
            <p>I have some question regarding this project. Can we put somme comment here and you reply. I really need
              to clarify something.</p>
          </div>
          <div class="ansDiv">
            <div class="divinn">
              <p><b>Ms Basama (DayPM):</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
              <p>Yes sure please share your question, I will glad to answer all of your questions.</p>
            </div>
          </div>

          <div class="quesDiv">
            <p><b>Dr. Winston Choi:</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
            <p>Thats really great, I am getting reply promptly.</p>
          </div>
          <div class="ansDiv">
            <div class="divinn">
              <p><b>Ms Seltiper (DayPM):</b> <span class="tStamp"> 11:00 am, 27 May 19</span> </p>
              <p>You are very welcome!</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>