<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div fxFlex>
      <div class="card card-stats">
        <div class="card-header hHeader">
          <p class="card-category">Total Portal Users</p>
          <h3 class="card-title">{{userCount?.total_portal_user}}</h3>
        </div>
          <div class="lWrpaer">
          <ul class="nav nav-stacked">
            <li><div>Project Managers <span class="pull-right badge bg-blue">
              {{userCount?.project_manager}}</span></div></li>
            <li><div>Admin <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>Human resources <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>Finance Manager <span class="pull-right badge bg-blue">-</span></div></li>
          </ul>
        </div>

        

        <div class="card-footer">
          <div class="stats">
            <button mat-raised-button class="mr-8" [routerLink]="'/user-management/add-user'">
              <mat-icon>person_add</mat-icon>Add New User
            </button>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex>
      <div class="card card-stats">
        <div class="card-header hHeader">
          <p class="card-category">Total Freelancer</p>
          <h3 class="card-title">-</h3>
        </div>
          <div class="lWrpaer">
          <ul class="nav nav-stacked">
            <li><div>Translators <span class="pull-right badge bg-blue">
              {{userCount?.translator}}</span></div></li>
            <li><div>Proofreader <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>Subtitler <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>MTPE <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>DTP <span class="pull-right badge bg-blue">-</span></div></li>
          </ul>
        </div>

        

       
      </div>
    </div>


    <div fxFlex>
      <div class="card card-stats">
        <div class="card-header hHeader">
          <p class="card-category">Approval Pending</p>
          <h3 class="card-title">{{userCount?.approval_pending}}</h3>
        </div>
          <div class="lWrpaer">
          <ul class="nav nav-stacked">
            <li><div>Translator Approved <span class="pull-right badge bg-blue">
              {{userCount?.approved}}</span></div></li>
            <li><div>Proofreader <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>Subtitler <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>MTPE <span class="pull-right badge bg-blue">-</span></div></li>
            <li><div>DTP <span class="pull-right badge bg-blue">-</span></div></li>
          </ul>
        </div>

        

       
      </div>
    </div>

  </div>

  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="20">
      <div class="white-box plainBox">
        <div class="tHeader">
          <h2><i class="material-icons">filter_list</i> Filter Portal users</h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="100">
                <mat-form-field  >
                  <mat-label>Search by ID/Name/Email</mat-label>
                  <input matInput (keyup)="onFilter($event.target.value,'search')">
                </mat-form-field>
              </div>
            </div>

            <div>
              <div>
                <div><b>Status is</b> </div>
              </div>
              <div>
                <mat-form-field  >
                  <mat-label>Select Field</mat-label>
                  <mat-select (selectionChange)="onFilter($event,'status')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option [value]="'1'">
                      Active
                    </mat-option>
                    <mat-option [value]="'0'">
                      Inactive
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div>
              <div>
                <div><b>Role/Position is</b> </div>
              </div>
              <div>
                <mat-form-field  >
                  <mat-label>Select Field</mat-label>
                  <mat-select (selectionChange)="onFilter($event,'role')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let role of roleData" [value]="role.id">
                      {{role.id}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div>
              <div>
                <div><b>Portal Access is</b> </div>
              </div>
              <div>
                <mat-form-field  >
                  <mat-label>Select</mat-label>
                  <mat-select (selectionChange)="onFilter($event,'portal_access')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option value="1">Admin</mat-option>
                    <mat-option value="2">Hr</mat-option>
                    <mat-option value="3">PMS</mat-option>
                    <mat-option value="4">Finance</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="78">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
              <h2> Total <b>{{total_user}}</b> Records Available</h2>
            </div>
            <div fxFlex class="text-right">
              <button mat-stroked-button class="mr-8" [routerLink]="'/user-management/add-user'">
                <mat-icon>add</mat-icon>Add New User
              </button>
              <button mat-stroked-button (click)="userMangement()">View All</button>
            </div>
          </div>
          <div class="filterTag pt-12">
            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArray" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>


        <div class="content-card" *ngIf="paginate?.total>0">
          <mat-table class="products-table" #table [dataSource]="dataSource" matSort (matSortChange)="sortUser($event)"
            fusePerfectScrollbar>
            <ng-container matColumnDef="fullname">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="24">Name</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="24">
                <span>
                  {{product.fullname}}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="24">Email</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="24">
                <span> {{product.email}} </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="portal_access">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="20">Portal Access</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                <span>
                  {{product.user_portal}}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user_group_id">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md fxFlex="10">Role/Position</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-md fxFlex="10">
                <span>
                  {{product.user_group_id}}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="last_login">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="15">Last Login
              </mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="15">
                <span>
                  {{product.last_login}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="8">Status</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="8">
                <span class="bdg rdb" *ngIf="product.status=='0'">
                  InActive
                </span>
                <span class="bdg grn" *ngIf="product.status=='1'">
                  Active
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef fxFlex="8">Actions</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="8">
                <button class="bicon" mat-stroked-button (click)="editData(product)">
                  <mat-icon matTooltip="Edit">
                    edit
                  </mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="active">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Active</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
                <mat-icon *ngIf="product.active" class="active-icon green-600 s-16">check</mat-icon>
                <mat-icon *ngIf="!product.active" class="active-icon red-500 s-16">close</mat-icon>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
            <mat-row *matRowDef="let product; columns: displayedColumns;" class="product">
            </mat-row>
          </mat-table>
          <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
            [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[10,20,30,40,200,500,1000]"
            (page)='paginateData($event)'>
          </mat-paginator>
        </div>
        <div  *ngIf="paginate?.total==0" class="noDataFound">
          No data available!!
        </div>
      </div>
    </div>
  </div>

  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="20">
      <div class="white-box plainBox">
        <div class="tHeader">
          <h2><i class="material-icons">filter_list</i> Filter Approval Pending</h2>
        </div>
        <div class="boxBody">
          <div class="boxBodyInn">
            <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
              <div fxFlex="100">
                <mat-form-field  >
                  <mat-label>Search by ID/Name/Email</mat-label>
                  <input matInput (keyup)="onFilterSpeciality($event.target.value,'search')">
                </mat-form-field>
              </div>
            </div>

            <div>
              <div>
                <div><b>Native Language is</b> </div>
              </div>
              <div>
                <mat-form-field  >
                  <mat-label>Select</mat-label>
                  <mat-select (selectionChange)="onFilterSpeciality($event,'native_lang')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.id">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div>
              <div>
                <div><b>Source Language is</b> </div>
              </div>
              <div>
                <mat-form-field  >
                  <mat-label>Select</mat-label>
                  <mat-select (selectionChange)="onFilterSpeciality($event,'s_lang')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div>
              <div>
                <div><b>Target Language is</b> </div>
              </div>
              <div>
                <mat-form-field  >
                  <mat-label>Select</mat-label>
                  <mat-select (selectionChange)="onFilterSpeciality($event,'t_lang')">
                    <mat-option [value]="''">
                      Any
                    </mat-option>
                    <mat-option *ngFor="let language of languageData" [value]="language.lang_name">
                      {{language.lang_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="78">
      <div class="white-box">
        <div class="tHeader">
          <div fxLayout="row wrap">
            <div fxFlex>
              <h2>Total <b>{{total_vendor}}</b> Records Available</h2>
            </div>
            <div fxFlex class="text-right">
              <button mat-stroked-button (click)="Approval()">View All</button>
            </div>
          </div>
          <div class="filterTag pt-12">
            <mat-chip-list #chipList aria-label="QuotesChip selection">
              <mat-chip *ngFor="let QuotesChip of fChipsArrayApprove" [selectable]="selectable" [removable]="removable">
                {{QuotesChip}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div class="content-card" *ngIf="paginate1?.total>0">
          <mat-table class="products-table" #table [dataSource]="dataSource1" matSort
            (matSortChange)="sortFreelancer($event)" fusePerfectScrollbar>
            <ng-container matColumnDef="first_name">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="24">Name</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="24">

                <a routerLink="/approval/review-translator/{{ product.id }}">
                  {{product.first_name}} {{product.last_name}}
                </a>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm fxFlex="20">Email</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="20">
                <span class="email text-truncate">
                  {{product.email}}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="native_language">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-sm fxFlex="12">Native Language</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm fxFlex="12">
                <span *ngIf="product.vendornativelanguage!=null">
                  {{product.vendornativelanguage.data.lang_name}}
                </span>
                <span *ngIf="product.vendornativelanguage==null">
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="language">
              <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md fxFlex="32">Language Pair</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-md fxFlex="32">
                <span class="price text-truncate">
                  <span *ngFor="let res of product.lang_array">
                    <p>{{res}}</p>
                  </span></span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="stage">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="13">Status
              </mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="13">
                <span class="bdg ylbg" *ngIf="product.stage!=null">
                  {{product.stage.data.name}}
                </span>
                <span class="bdg ylbg" *ngIf="product.stage==null">
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs fxFlex="5">Active</mat-header-cell>
              <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs fxFlex="5">
                <mat-icon *ngIf="product.active" class="active-icon green-600 s-16">check</mat-icon>
                <mat-icon *ngIf="!product.active" class="active-icon red-500 s-16">close</mat-icon>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="VendordisplayedColumns; sticky:true"></mat-header-row>
            <mat-row *matRowDef="let product; columns: VendordisplayedColumns;" class="product">
            </mat-row>
          </mat-table>
          <mat-paginator #paginator2 *ngIf="paginate1?.total != 0" [length]="paginate1?.total"
            [pageIndex]="paginate1?.current_page - 1" [pageSize]="paginate1?.per_page" [pageSizeOptions]="[10,20,30,40,200,500,1000]"
            (page)='paginateData_approve($event)'>
          </mat-paginator>
        </div>
        <div class="noDataFound" *ngIf="paginate1?.total==0">
          No data available!!
        </div>
      </div>
    </div>
  </div>

















</div>