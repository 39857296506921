import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CrmService } from 'app/main/Services/crm.service'; // Replace with your actual service path
import { TableUtil } from 'app/utils/tableUtil'; // Replace with your actual utility path
var GclidComponent = /** @class */ (function () {
    function GclidComponent(crmService) {
        this.crmService = crmService;
        this.totalRecords = 0;
        this.pageSize = 10;
        this.currentPage = 0;
        this.sortDirection = '';
        this.sortField = '';
        this.selectedType = 'ld';
        this.types = [
            { value: 'ld', viewValue: 'Lead' },
            { value: 'op', viewValue: 'Opportunity' }
        ];
        this.filterfieldMapping = { 'amount': "amount", "date_entered": "date_entered", "gclid": "gclid_c", "msclkid": "msclkid_c", "payment_status": "payment_status_c", "currency": "currencyName", "utm_medium_c": "utm_medium_c", "utm_source_c": "utm_source_c" };
        this.ApprovaldisplayedColumns = ['type', 'gclid', 'msclkid', 'utm_medium_c', 'utm_source_c', 'currency', 'amount', 'payment_status', 'tracking_description_c', 'date_entered'];
    }
    GclidComponent.prototype.ngOnInit = function () {
        this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
    };
    GclidComponent.prototype.getGclids = function (page, size, sortField, sortDirection) {
        var _this = this;
        if (sortField === void 0) { sortField = ''; }
        if (sortDirection === void 0) { sortDirection = ''; }
        var queryParams = "?page=" + (page + 1) + "&per_page=" + size;
        if (this.selectedType) {
            queryParams += "&type=" + this.selectedType;
        }
        if (sortField && sortDirection) {
            queryParams += "&sort_by=" + sortField + "&sort_direction=" + sortDirection;
        }
        this.crmService.getAllGclid(queryParams).subscribe(function (response) {
            _this.totalRecords = response.total; // Assuming your backend returns total record count
            _this.dataSource1 = response.data; // Assuming your backend returns paginated data in "data"
            _this.dataSource = new MatTableDataSource(_this.dataSource1);
            _this.dataSource.sort = _this.sort;
        });
    };
    GclidComponent.prototype.applyFilter = function () {
        // Reset to first page when filter is applied
        this.currentPage = 0;
        this.paginator.firstPage();
        this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
    };
    GclidComponent.prototype.onPageChange = function (event) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
    };
    GclidComponent.prototype.onSortChange = function (sort) {
        this.sortField = this.filterfieldMapping[sort.active];
        this.sortDirection = sort.direction;
        this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
    };
    GclidComponent.prototype.export = function () {
        var data = [['Type', 'Google Click ID (GCLID)', 'Microsoft Click ID (MSCLKID)', 'UTM Medium', 'UTM Source', 'Currency', 'Amount', 'Payment Status', 'Date Entered']];
        this.dataSource1.forEach(function (record) {
            var paymentDetail = "Method: " + record.payment_method_c + " Status: " + record.payment_status_c + " / " + record.approval_payment_dd_c;
            data.push([
                record.type == 'ld' ? 'Lead' : 'Opportunity',
                record.gclid_c,
                record.msclkid_c,
                record.utm_medium_c,
                record.utm_source_c,
                record.currencyName ? record.currencyName + ':' + record.currencySymbol : 'US Dollars : $',
                record.type == 'ld' ? record.opportunity_amount : record.amount,
                paymentDetail,
                record.date_entered
            ]);
        });
        TableUtil.exportArrayToExcel(data);
    };
    return GclidComponent;
}());
export { GclidComponent };
