<div class="page-layout blank p-24 bg-white" fusePerfectScrollbar>
    <div class="white-box">
        <div class="tHeader">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
                fxLayoutAlign.gt-sm="space-between center">
                <h2>
                    <mat-icon class="ml-24">group</mat-icon>GCLID Record
                </h2>
                <div fxFlex class="text-right">
                    <button mat-raised-button color="accent" (click)="export()" class="mr-8 text-right">
                        <i class="material-icons">group</i>Export
                    </button>
                </div>
            </div>
        </div>

        <!-- Dropdown Filter -->
        <div class="p20 " fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="32">
                <mat-form-field appearance="fill">
                    <mat-select placeholder="Filter by Type" [(ngModel)]="selectedType" (selectionChange)="applyFilter()">
                        <mat-option *ngFor="let type of types" [value]="type.value">{{ type.viewValue }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="content-card">
            <mat-table class="products-table" id="gclid-material-table" [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">

                <!-- Type Column -->
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef > Type </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        {{product.type == 'ld' ? 'Lead' : 'Opportunity'}}
                    </mat-cell>
                </ng-container>

                <!-- Payment Status Column -->
                <ng-container matColumnDef="payment_status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Payment Status </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        <div fxLayout="column">
                            <div fxFlex>
                                <ng-container *ngIf="product.payment_method_c">
                                    Method: {{product.payment_method_c}}
                                    <br />
                                </ng-container>
                                <ng-container *ngIf="product.payment_status_c || product.approval_payment_dd_c">
                                    Status: {{product.payment_status_c}} / {{product.approval_payment_dd_c}}
                                </ng-container>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- GCLID Column -->
                <ng-container matColumnDef="gclid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Google Click ID (GCLID) </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                {{product.gclid_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- All Values Column -->
                <ng-container matColumnDef="tracking_description_c">
                    <mat-header-cell *matHeaderCellDef> All Values </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                {{product.tracking_description_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- MSCLKID Column -->
                <ng-container matColumnDef="msclkid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Microsoft's Click ID (MSCLKID) </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                {{product.msclkid_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- UTM Medium Column -->
                <ng-container matColumnDef="utm_medium_c">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> UTM Medium </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                {{product.utm_medium_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- UTM Source Column -->
                <ng-container matColumnDef="utm_source_c">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>UTM Source </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        <div fxLayout="column" style="word-break: break-all;">
                            <div fxFlex>
                                {{product.utm_source_c}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Currency Column -->
                <ng-container matColumnDef="currency">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Currency </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        {{product.currencyName ? product.currencyName + ':'+ product.currencySymbol : 'US Dollars : $'}}
                    </mat-cell>
                </ng-container>

                <!-- Date Entered Column -->
                <ng-container matColumnDef="date_entered">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Date Entered </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        {{product.date_entered}}
                    </mat-cell>
                </ng-container>

                <!-- Amount Column -->
                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
                    <mat-cell *matCellDef="let product">
                        {{product.type == 'ld' ? product.opportunity_amount : product.amount}}
                    </mat-cell>
                </ng-container>

                <!-- Table Header and Rows -->
                <mat-header-row *matHeaderRowDef="ApprovaldisplayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let product; columns: ApprovaldisplayedColumns;" class="product fList"></mat-row>

            </mat-table>

            <!-- Paginator -->
            <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[20, 50, 100]"
                (page)="onPageChange($event)" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>
