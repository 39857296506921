<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon routerLink="/masterdata">keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1">
      Language Master
    </span>
  </div>
  <div class="container" fxLayout="row wrap" fxLayoutGap="15px">
    <div fxFlex="100">
      <div class="white-box" id="scrolTo">
        <form name="addlanguageForm" [formGroup]="addlanguageForm">
          <div class="profHeader p20 " fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="32">
              <p class="dLabel">Languages (to or from English)*</p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput formControlName="lang_name">
                  <mat-error
                    *ngIf="addlanguageForm.get('lang_name').invalid && addlanguageForm.get('lang_name').touched">
                    <span *ngIf="addlanguageForm.get('lang_name').errors.required">Language is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>
            <div fxFlex="32">
              <p class="dLabel">Language Code*</p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput formControlName="lang">
                  <mat-error *ngIf="addlanguageForm.get('lang').invalid && addlanguageForm.get('lang').touched">
                    <span *ngIf="addlanguageForm.get('lang').errors.required">Language Code is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>
          </div>

          <div class="p20" fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="18">
              <p class="dLabel">Translation Rate*<span class="block">(0-2000 Words)</span></p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput type="number" mask="0.000" formControlName="rate">
                  <mat-error *ngIf="addlanguageForm.get('rate').invalid && addlanguageForm.get('rate').touched">
                    <span *ngIf="addlanguageForm.get('rate').errors.required">Translation Rate (0-2000 Words) is
                      required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>

            <div fxFlex="18">
              <p class="dLabel">Translation Rate*<span class="block">(2001-5000 Words)</span></p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput type="number" mask="0.000" formControlName="rate_tier2">
                  <mat-error
                    *ngIf="addlanguageForm.get('rate_tier2').invalid && addlanguageForm.get('rate_tier2').touched">
                    <span *ngIf="addlanguageForm.get('rate_tier2').errors.required">Translation Rate (2001-5000 Words)
                      is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>

            <div fxFlex="18">
              <p class="dLabel">Translation Rate*<span class="block">(5,001-10,000 Words)</span></p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput type="number" mask="0.000" formControlName="rate_tier3">
                  <mat-error
                    *ngIf="addlanguageForm.get('rate_tier3').invalid && addlanguageForm.get('rate_tier3').touched">
                    <span *ngIf="addlanguageForm.get('rate_tier3').errors.required">Translation Rate (5,001-10,000
                      Words) is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>

            <div fxFlex="18">
              <p class="dLabel">Translation Rate*<span class="block">(10,001-20,000 Words)</span></p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput type="number" mask="0.000" formControlName="rate_tier4">
                  <mat-error
                    *ngIf="addlanguageForm.get('rate_tier4').invalid && addlanguageForm.get('rate_tier4').touched">
                    <span *ngIf="addlanguageForm.get('rate_tier4').errors.required">Translation Rate (10,001-20,000
                      Words) is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>

            <div fxFlex="18">
              <p class="dLabel">Translation Rate*<span class="block">(Over 20,000 Words)</span></p>
              <p class="dValue">
                <mat-form-field  >
                  <input matInput type="number" mask="0.000" formControlName="rate_tier5">
                  <mat-error
                    *ngIf="addlanguageForm.get('rate_tier5').invalid && addlanguageForm.get('rate_tier5').touched">
                    <span *ngIf="addlanguageForm.get('rate_tier5').errors.required">Translation Rate (Over 20,000 Words)
                      is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>
          </div>
          <div class="p20 " fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="32">
              <p class="dLabel">Language Margin*<span class="block"></span></p>
              <p class="dValue ">
                <mat-form-field  >
                  <input matInput type="number" mask="0.000" formControlName="margin">
                  <mat-error *ngIf="addlanguageForm.get('margin').invalid && addlanguageForm.get('margin').touched">
                    <span *ngIf="addlanguageForm.get('margin').errors.required">Language Margin is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>
            <div fxFlex="32">
              <p class="dLabel">Language Type*<span class="block"></span> </p>
              <p class="dValue">
                <mat-form-field  >
                  <mat-select formControlName="type">
                    <mat-option [value]="''">Select</mat-option>
                    <mat-option value="0">Regular</mat-option>
                    <mat-option value="1">Rare</mat-option>
                    <mat-option value="2"> Rarest</mat-option>
                  </mat-select>
                  <mat-error *ngIf="addlanguageForm.get('type').invalid && addlanguageForm.get('type').touched">
                    <span *ngIf="addlanguageForm.get('type').errors.required">Language type is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>
            <div fxFlex="32">
              <p class="dLabel">Status*<span class="block"></span> </p>
              <p class="dValue">
                <mat-form-field  >
                  <mat-select placeholder="Select Status" formControlName="is_active">
                    <mat-option [value]="">Select</mat-option>
                    <mat-option [value]="0">InActive</mat-option>
                    <mat-option [value]="1">Active</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="addlanguageForm.get('is_active').invalid && addlanguageForm.get('is_active').touched">
                    <span *ngIf="addlanguageForm.get('is_active').errors.required">Status is required</span>
                  </mat-error>
                </mat-form-field>
              </p>
            </div>
          </div>
        </form>
        <div class="actWraper">
          <button matTooltip="Approve" mat-raised-button class="green-bg mr-8" [disabled]="addlanguageForm.invalid"
            (click)="submit()">
            <mat-label class="Text_Color">{{type}}</mat-label>
          </button>
        </div>
      </div>
     
        
        <div fxLayout="row wrap" fxLayoutGap="10px">
        
        
        <div fxFlex="28">
          <div class="white-box plainBox">
            <div class="tHeader">
              <h2><i class="material-icons">filter_list</i> Sort by</h2>
            </div>
            <div class="boxBody">
              <div class="boxBodyInn">
                <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="79">
                    <mat-form-field  >
                      <mat-label>Search by ID/Language Code/Language Name</mat-label>
                      <input matInput [(ngModel)]="search.value" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                  </div>
                  <div fxFlex="20">
                    <button mat-stroked-button color="primary"  (click)="SubmitSearch()"><i
                        class="material-icons">search</i></button>
                  </div>
                </div>
                <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="30">
                    <div class="text-right"><b>Status is</b> </div>
                  </div>
                  <div fxFlex="69">
                    <mat-form-field  >
                      <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'status')">
                        <mat-option [value]="''">
                          Any
                        </mat-option>
                        <mat-option [value]="'1'">
                          Active
                        </mat-option>
                        <mat-option [value]="'0'">
                          Inactive
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="30">
                    <div class="text-right"><b>Category is</b> </div>
                  </div>
                  <div fxFlex="69">
                    <mat-form-field  >
                      <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'category_is')">
                        <mat-option [value]="''">
                          Any
                        </mat-option>
                        <mat-option [value]="'0'">
                          Regular
                        </mat-option>
                        <mat-option [value]="'1'">
                          Rare
                        </mat-option>
                        <mat-option [value]="'2'">
                          Rarest
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxFlexLayout="row wrap" fxLayoutAlign="left center" fxLayoutGap="10px">
                  <div fxFlex="30">
                    <div class="text-right"><b>Margin is</b> </div>
                  </div>
                  <div fxFlex="69">
                    <mat-form-field  >
                      <mat-select placeholder="Select Field" (selectionChange)="onFilter($event,'margin_is')">
                        <mat-option [value]="''">
                          Any
                        </mat-option>
                        <mat-option [value]="'10'">10</mat-option>
                        <mat-option [value]="'20'">20</mat-option>
                        <mat-option [value]="'30'">30</mat-option>
                        <mat-option [value]="'40'">40</mat-option>
                        <mat-option [value]="'50'">50</mat-option>
                        <mat-option [value]="'60'">60</mat-option>
                        <mat-option [value]="'70'">70</mat-option>
                        <mat-option [value]="'80'">80</mat-option>
                        <mat-option [value]="'90'">90</mat-option>
                        <mat-option [value]="'100'">100</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="68" >
          <div class="white-box">

          <div class="tHeader">
            <h4>Languages</h4>
            <div *ngIf="paginate?.total==0" class="noDataFound">
              <p> No Data available!!</p>
             </div>
          </div>
          <table mat-table [dataSource]="dataSource" *ngIf="paginate?.total>0">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="lang_name">
              <th mat-header-cell *matHeaderCellDef> Language Name </th>
              <td mat-cell *matCellDef="let element"> {{element.lang_name}} </td>
            </ng-container>
            <ng-container matColumnDef="lang">
              <th mat-header-cell *matHeaderCellDef> Language Code </th>
              <td mat-cell *matCellDef="let element"> {{element.lang}} </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef> Language Rate </th>
              <td mat-cell *matCellDef="let element"> {{element.rate}} </td>
            </ng-container>
            <ng-container matColumnDef="margin">
              <th mat-header-cell *matHeaderCellDef> Language Margin </th>
              <td mat-cell *matCellDef="let element"> {{element.margin}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Language Type </th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.type==0">Regular</span>
                <span *ngIf="element.type==1">Rare</span>
                <span *ngIf="element.type==2">Rarest</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element">
                <span class="bdg rdb" *ngIf="element.is_active==0">InActive</span>
                <span class="bdg grn" *ngIf="element.is_active==1">Active</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element; let i=index">
                <button mat-stroked-button class="bicon" title="Edit" (click)="editData(i)"><i class="material-icons">
                    edit
                  </i></button> </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #paginator *ngIf="paginate?.total != 0" [length]="paginate?.total"
            [pageIndex]="paginate?.current_page - 1" [pageSize]="paginate?.per_page" [pageSizeOptions]="[10,20,30,40,200,500,1000]"
            (page)='paginateData($event)'>
          </mat-paginator>
        
       

        </div>

     
</div>


      </div>

      
      
    </div>
  </div>
</div>