import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { environment } from 'environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-add-temp-translator',
  templateUrl: './add-temp-translator.component.html',
  styleUrls: ['./add-temp-translator.component.scss']
})
export class AddTempTranslatorComponent implements OnInit {
  type: string = 'Add';
  addTranslatorForm: any;
  stage_id: any;
  table_data: any = [];
  final_table_data: any = [];
  lang_name_update = [];
  today: Date = new Date();
  minDate = this.today;
  lang_combination_id: number;
  bulk_selected: boolean = false;
  displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'dtpRate', 'qaRate', 'speed', 'actions'];
  languageData: any = [];
  speciality_new_ids: any = [];
  speciality_new_data: any = [];
  specialityData: any = [];
  language: any = [];
  fileError: string = '';
  file_id: number;
  file_multiple_data: any = [];
  file_id_array: any = [];
  uploaded_files_array: any = [];
  selectedItems = [];
  // Coa start
  file_multiple_data1: any = [];
  fileError1: string = '';
  fileSuccess1: string;
  uploaded_coa_array: any = [];
  coa_id_array: any = [];
  //Coa end
  data: any =
    {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      translation_rate: '',
      proofreading_rate: '',
      dtp_rate: '',
      qa_rate: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
    };
  coa_array: any = [];
  dataSource: any;
  selected_tools: any = [];
  selected_days: any = [];
  final_data: any = {};
  fileSuccess: string;
  checked: boolean = true;
  id: number;
  selected_skills: any = [];
  dropdownSettings = {};
  dropdownList: any = [];
  BaseUrl_file: any;
  vendor_resume_data: string;
  coa_url: string
  coa_name: string;
  vendor_resume_name: string;
  constructor(private fb: FormBuilder,private dialogRef: MatDialogRef<AddTempTranslatorComponent>, private projectsService: ProjectsService, private router: Router, private vendorService: VendorService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private location: Location) { this.BaseUrl_file = environment.baseUrl_file; }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {

      this.addTranslatorForm = this.fb.group({
        first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        email: ['', [Validators.required, Validators.email]],
      });

      this.getTranslatorData();
      this.getLanguage();
      this.getSpeciality();
      this.getTranslatorStatus();
    }
    else {
      this.getLanguage();
      this.getSpeciality();
      // this.country_code=Dial.response;
      this.addTranslatorForm = this.fb.group({
        first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        email: ['', [Validators.required, Validators.email]],
      });
    }

    this.dropdownSettings = {
      singleSelection: false,
      textId: 'id',
      textField: 'name',
      allowSearchFilter: true
    };
  }

  get f() {
    return this.addTranslatorForm.controls;
  }

  submit() {
    if (this.type == 'Add') {
      this.addData();
    } else {
      this.updateData();
    }
  }


  getTranslatorData() {
    this.coa_array = [];
    this.table_data = [];
    this.speciality_new_ids = [];
    this.coa_id_array = [];
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          this.dataSource = [];
          this.getSpeciality();
          this.type = 'Update';
          this.stage_id = response.data.stage_id;
          if (response.data.tool_names) {
            this.selected_tools = response.data.tool_names.split(',');
          }
          response.data.vendorskill.data.forEach(element => {
            this.selected_skills.push(element.skill_id);
          });
          response.data.vendortranslationlangs.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            this.table_data.push(element);
          });
          this.dataSource = new MatTableDataSource(this.table_data);
          response.data.vendoravailabilities.data.forEach(element => {
            this.selected_days.push(element.day_name);
          });

          response.data.vendorspecialities.data.forEach(element => {
            this.speciality_new_ids.push(element.specialities.data);

          });

          if (response.data.vendorresume.data.length != 0) {
            response.data.vendorresume.data.forEach(element => {
              this.vendor_resume_data = this.BaseUrl_file + element.path;
              let resume_name = element.path.split('/');
              this.vendor_resume_name = resume_name[2];
            });
          }

          if (response.data.vendorcoafiles.data.length != 0) {
            response.data.vendorcoafiles.data.forEach(element => {
              this.coa_url = this.BaseUrl_file + element.coafile.data.path;
              let coa_name = element.coafile.data.path.split('/');
              this.coa_name = coa_name[2];
              let coa_obj = {
                id: element.id,
                url: this.coa_url,
                name: this.coa_name,
                coa_file_id: element.coa_file_id
              }
              // this.coa_id_array()
              this.coa_id_array.push(element.coa_file_id);
              this.coa_array.push(coa_obj);
            });
          }

          this.addTranslatorForm.patchValue({
            address1: response.data.address1,
            email: response.data.email,
            email_sec: response.data.email_sec,
            city: response.data.city,
            country: response.data.country,
            cover_letter: response.data.cover_letter,
            education: response.data.education,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            middle_name: response.data.middle_name,
            native_language_id: response.data.native_language_id,
            payment_method: response.data.payment_method,
            payment_method_id: response.data.payment_method_id,
            phone: response.data.phone,
            phone_sec: response.data.phone_sec,
            postal_code: response.data.postal_code,
            skype_id: response.data.skype_id,
            state: response.data.state,
            title: response.data.title,
            specialities: this.speciality_new_ids,
            important_note: response.data.important_note,
            category_level: response.data.category_level,
            on_boarding_date: response.data.on_boarding_date
          });

          if (response.data.translation_tools != null) {
            this.addTranslatorForm.patchValue({
              translation_tools: response.data.translation_tools.toString(),
            });
          }

          if (response.data.tool_licence != null) {
            this.addTranslatorForm.patchValue({
              tool_licence: response.data.tool_licence.toString(),
            });
          }

        }
      });
  }

  edit_getTranslatorData() {
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          this.dataSource = [];
          this.type = 'Update';
          response.data.vendortranslationlangs.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            this.table_data.push(element);
          });
          this.dataSource = new MatTableDataSource(this.table_data);
        }
      });
  }

  editData(value) {
    this.data.source_lang_id = value.sourcelanguage.data.id;
    this.data.target_lang_id = value.targetlanguage.data.id;
    this.data.translation_rate = value.translation_rate;
    this.data.proofreading_rate = value.proofreading_rate;
    this.data.dtp_rate = value.dtp_rate;
    this.data.qa_rate = value.qa_rate;
    this.data.speed = value.speed;
    this.lang_combination_id = value.id;
    if (value.bi_direction == '>') {
      this.data.bi_direction = false;
    }
    else {
      this.data.bi_direction = true;
    }
  }

  updateData() {
    this.final_data = {};
    this.final_data = this.addTranslatorForm.value;
    this.final_data.translation_tools = this.addTranslatorForm.value.translation_tools;
    this.final_data.on_boarding_date = this.dateFormat(this.addTranslatorForm.value.on_boarding_date);
    this.final_data.specialities = this.getSpecialityId(this.addTranslatorForm.value.specialities);
    if (this.selected_tools.length > 0) {
      this.final_data.tool_names = this.selected_tools.toString();
    } else {
      this.final_data.tool_names = this.selected_tools;
    }
    this.final_data.vendor_skill = this.selected_skills;
    if (this.selected_days.length > 0) {
      this.final_data.availability = this.selected_days.toString();
    }
    else {
      this.final_data.availability = this.selected_days;
    }
    this.final_data.vendor_skill = this.selected_skills;
    if (this.file_id_array.length > 0) {
      this.final_data.resume_id = this.file_id_array;
    }
    if (this.coa_id_array.length > 0) {
      this.final_data.coa_files = this.coa_id_array;
    } else {
      this.final_data.coa_files = [];
    }
    this.final_data.file_id = [];
    this.final_data.stage_id = this.stage_id;

    this.vendorService.updateTranslator(this.id, this.final_data).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success('Translator updated successfully');
          this.location.back();
        }
      }
    );

  }

  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return year + '-' + month + '-' + dt;
  }

  getSourceName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.data.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getTargetName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.data.targetlanguage.data.lang_name = element.lang_name;
      }
    });
  }

  addLanguageTable() {
    let bi_direction: String;
    this.dataSource = [];
    if (this.data.bi_direction == false) {
      bi_direction = '>'
      this.data.bi_direction = bi_direction;
    } else {
      bi_direction = '<>'
      this.data.bi_direction = bi_direction;
    }
    if (this.data.sourcelanguage.data.lang_name == '') {
      this.toastr.error('please select source language');
      return false;
    }

    if (this.data.targetlanguage.data.lang_name != '' && this.data.sourcelanguage.data.lang_name != '') {
      if (this.data.sourcelanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
        this.toastr.error('please select unique language pair !!');
        return false;
      }

    }
    if (this.data.targetlanguage.data.lang_name == '') {
      this.toastr.error('please select target language');
      return false;
    }
    if (this.data.translation_rate == '') {
      this.toastr.error('please select translation rate');
      return false;
    }
    if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
      this.toastr.error('Translation rate never be negative or zero');
      return false;
    }
    if (this.data.proofreading_rate == '') {
      this.toastr.error('please select proofreading rate');
      return false;
    }

    if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
      this.toastr.error('Proofreading rate never be negative or zero');
      return false;
    }

    // if (this.data.dtp_rate == '') {
    //   this.toastr.error('please select Dtp rate');
    //   return false;
    // }

    // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
    //   this.toastr.error('Dtp rate never be negative or zero');
    //   return false;
    // }

    // if (this.data.qa_rate == '') {
    //   this.toastr.error('please select Dtp rate');
    //   return false;
    // }

    // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
    //   this.toastr.error('MTPE rate never be negative or zero');
    //   return false;
    // }
    if (this.data.speed == '') {
      this.toastr.error('please select speed');
      return false;
    }
    this.language.push(this.data);
    this.dataSource = new MatTableDataSource(this.language);
    this.data = {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      translation_rate: '',
      dtp_rate: '',
      proofreading_rate: '',
      qa_rate: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
    };
  }

  addLanguageTableApi() {
    if (this.lang_combination_id) {

      this.table_data = [];
      if (this.data) {
        this.vendorService.updateLanguage(this.lang_combination_id, this.data)
          .subscribe((response) => {
            if (response) {
              this.lang_combination_id = null;
              this.edit_getTranslatorData();
              this.data = {
                source_lang_id: '',
                sourcelanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                targetlanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                target_lang_id: '',
                target_lang_name: '',
                translation_rate: '',
                proofreading_rate: '',
                qa_rate: '',
                dtp_rate: '',
                speed: '',
                bi_direction: false,
                bi_direction_test: ''
              };
            }
          }, (error) => {
            if (error) {
              this.toastr.error('Please first select all fields.');
            }
          });
      }

    }
    else {
      this.table_data = [];
      if (this.data.sourcelanguage.data.lang_name == '') {
        this.toastr.error('please select source language');
        return false;
      }
      if (this.data.targetlanguage.data.lang_name == '') {
        this.toastr.error('please select target language');
        return false;
      }
      if (this.data.translation_rate == '') {
        this.toastr.error('please select translation rate');
        return false;
      }
      if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
        this.toastr.error('Translation rate never be negative or zero');
        return false;
      }
      if (this.data.proofreading_rate == '') {
        this.toastr.error('please select proofreading rate');
        return false;
      }

      if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
        this.toastr.error('Proofreading rate never be negative or zero');
        return false;
      }

      // if (this.data.dtp_rate == '') {
      //   this.toastr.error('please select Dtp rate');
      //   return false;
      // }

      // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
      //   this.toastr.error('Dtp rate never be negative or zero');
      //   return false;
      // }

      // if (this.data.qa_rate == '') {
      //   this.toastr.error('please select Dtp rate');
      //   return false;
      // }

      // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
      //   this.toastr.error('MTPE rate never be negative or zero');
      //   return false;
      // }
      if (this.data.speed == '') {
        this.toastr.error('please select speed');
        return false;
      }

      if (this.data.speed < 0 || this.data.speed == 0) {
        this.toastr.error('Speed never be negative or zero');
        return false;
      }
      this.data.vendor_id = this.id;
      this.data.table_data = this.table_data;
      this.vendorService.addLanguage_edit(this.data)
        .subscribe((response) => {
          if (response) {
            this.edit_getTranslatorData();
            this.data = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              target_lang_id: '',
              target_lang_name: '',
              translation_rate: '',
              proofreading_rate: '',
              dtp_rate: '',
              qa_rate: '',
              speed: '',
              bi_direction: false,
              bi_direction_test: ''
            };
          }
        });
    }
  }

  deleteData(obj: any, index: any) {
    if (this.id) {
      this.vendorService.deleteVendorLanguage(obj.id)
        .subscribe((response) => {
          this.toastr.success("delete successfully");
          this.getTranslatorData();

          this.data = {
            source_lang_id: '',
            sourcelanguage: {
              data: {
                lang_name: ''
              }
            },
            targetlanguage: {
              data: {
                lang_name: ''
              }
            },
            target_lang_id: '',
            target_lang_name: '',
            translation_rate: '',
            proofreading_rate: '',
            qa_rate: '',
            dtp_rate: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
          };
        });
    }
    else {
      this.language.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.language);

    }


  }

  tools(evt: any) {
    if (evt.checked && this.selected_tools.indexOf(evt.source.value == -1)) {
      this.selected_tools.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_tools.indexOf(evt.source.value != -1)) {
      let index = this.selected_tools.indexOf(evt.source.value);
      this.selected_tools.splice(index, 1);
    }
  }

  Available_days(evt: any) {
    if (evt.checked && this.selected_days.indexOf(evt.source.value == -1)) {
      this.selected_days.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_days.indexOf(evt.source.value != -1)) {
      let index = this.selected_days.indexOf(evt.source.value);
      this.selected_days.splice(index, 1);
    }
  }

  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res) => {
        if (res) {
          this.languageData = res;
        }
      });
  }

  getSpeciality() {
    this.vendorService.getSpeciality()
      .subscribe((res) => {
        if (res) {
          this.specialityData = res;
          this.dropdownList = res;
        }
      });
  }

  getTranslatorStatus() {
    this.vendorService.getTranslatorStatus(this.id)
      .subscribe((res) => {
        if (res) {
          this.addTranslatorForm.patchValue({
            is_active: res.user_detail.status.toString()
          });
        }
      });
  }

  addData() {
    this.final_data = {};
    this.final_data = this.addTranslatorForm.value;
    this.final_data.language = this.language;
    if (this.language.length == 0) {
      this.toastr.error("Please add language pair!!");
      return;
    }
    this.projectsService.add(this.final_data).subscribe(
      resposne => {
        if (resposne) {
          if (resposne.status == 409) {
            this.toastr.error(resposne.message);
          }
          else {
            this.toastr.success(resposne.message);
            this.Onclose();
          }
        }
      }
    );
  }

  Onclose() {
    this.dialogRef.close(true);
  }

  getSpecialityId(res: any) {
    if (res.constructor === String) {
      return res;
    }
    else if (res.length > 0 && res.constructor === Array) {
      let id = '';
      res.forEach(element => {
        id += element.id + ', ';
      });
      id = id.slice(0, -2);
      return id;
    }

  }

  onFileUpload() {
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = 'File size should not be more than 20MB.';
            return false;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);
    };
    fileUpload.click();
  }

  addDocument(file) {
    this.fileSuccess = '';
    this.uploaded_files_array = [];
    this.file_id_array = [];
    const uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }

    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_files_array.push(response.data);
        response.data.forEach(element => {
          this.file_id_array.push(element.id);
        });
        this.fileSuccess = 'Uploaded successfully!!';
      });
  }

  // COA FILE UPLOAD
  onCoaUpload() {
    this.file_multiple_data1 = [];
    const fileUpload = document.getElementById('coaUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError1 = 'File size should not be more than 20MB.';
            return;
          }
          this.file_multiple_data1.push(file);
        }
        else {
          this.fileError1 = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addCoa(this.file_multiple_data1);

    };

    fileUpload.click();
  }

  // addCoa
  addCoa(file) {
    this.fileSuccess1 = '';
    // this.uploaded_coa_array = [];
    // this.coa_id_array = [];
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }
    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {

        response.data.forEach(element => {
          if (this.id) {
            this.coa_array.push(element);
          } else {
            this.uploaded_coa_array.push(element);
          }

          this.coa_id_array.push(element.id);
        });

        if (this.id) {
          this.vendorCoaFile(this.coa_id_array);
        }
        this.fileSuccess1 = 'Uploaded successfully!!';
      });
  }

  vendorCoaFile(coaFileId: any) {
    let obj = {
      coa_files: coaFileId
    }
    this.vendorService.VendorsCoaFile(this.id, obj)
      .subscribe((response) => {
        this.getTranslatorData();
      });
  }

  deleteCoa(id: any) {
    const coa_array_index = this.uploaded_coa_array.findIndex(
      (x) => x.id === id
    );
    if (coa_array_index !== -1) {
      this.uploaded_coa_array.splice(coa_array_index, 1);
    }

    const coa_id_index = this.coa_id_array.findIndex(
      (x) => x === id
    );
    if (coa_id_index !== -1) {
      this.coa_id_array.splice(coa_id_index, 1);
    }
  }

  deleteCoaEdit(obj: any) {
    this.vendorService.deleteCoaFile(obj.id)
      .subscribe((response) => {
        this.toastr.success("delete successfully");
        this.deleteCoaEditArray(obj);
      });
  }

  deleteCoaEditArray(obj: any) {
    const coa_array_index = this.coa_array.findIndex(
      (x) => x.id === obj.id
    );
    if (coa_array_index !== -1) {
      this.coa_array.splice(coa_array_index, 1);
    }

    const coa_id_index = this.coa_id_array.findIndex(
      (x) => x === obj.coa_file_id
    );
    if (coa_id_index !== -1) {
      this.coa_id_array.splice(coa_id_index, 1);
    }
  }

  // COA END
  back() {
    this.location.back();
  }

  Reset() {
    this.addTranslatorForm.reset();
    this.location.back();
  }


  Skills(evt: any) {
    if (evt.checked && this.selected_skills.indexOf(evt.source.value == -1)) {
      this.selected_skills.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_skills.indexOf(evt.source.value != -1)) {
      let index = this.selected_skills.indexOf(evt.source.value);
      this.selected_skills.splice(index, 1);
    }
  }

  onItemSelect(item: any) {

  }
  onSelectAll(items: any) {

  }

  onItemDeSelect(items: any) {

  }

  onDeSelectAll(items: any) {
  }
}


export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }
  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirm_password');
  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }
  return { passwordsNotMatching: true };
};
