ˀ<div class="page-layout profilePage w85 p-24 bg-white" fusePerfectScrollbar>
  <div class="logo mb-24" fxLayout="row" fxLayoutAlign="start center">
    <button color="accent" (click)="back()" matTooltip="Back" mat-icon-button class="mr-12 mat-back">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <span class="title-text h1" *ngIf="type == 'Update'"> Edit Freelancer</span>
    <span class="title-text h1" *ngIf="type == 'Add'">
      Add new freelancer
    </span>
  </div>

  <div class="white-box">
    <form fxLayout="column" [formGroup]="addTranslatorForm" name="addTranslatorForm">
      <div class="boxBody">
        <div class="boxBodyInn">
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Email*</mat-label>
              <input matInput formControlName="email">
              <mat-error *ngIf="addTranslatorForm.get('email').hasError('required')">
                Email is required
              </mat-error>
              <mat-error *ngIf="!addTranslatorForm.get('email').hasError('required') &&
                addTranslatorForm.get('email').hasError('email')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12" *ngIf="type == 'Add'">Password*</mat-label>
              <mat-label class="font-size-12" *ngIf="type == 'Update'">Password</mat-label>
              <input matInput type="password" formControlName="password">
              <mat-error *ngIf="addTranslatorForm.get('password').hasError('required')">
                Password is required
              </mat-error>
              <mat-error *ngIf="addTranslatorForm.get('password').hasError('minlength')">
                Minimum 6 characters required!
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12" *ngIf="type == 'Add'">Confirm Password*</mat-label>
              <mat-label class="font-size-12" *ngIf="type == 'Update'">Confirm Password</mat-label>
              <input matInput formControlName="confirm_password" type="password">
              <mat-error *ngIf="addTranslatorForm.get('confirm_password').hasError('required')">
                Confirm Password is required
              </mat-error>
              <mat-error *ngIf="addTranslatorForm.get('confirm_password').hasError('minlength')">
                Minimum 6 characters required!
              </mat-error>
              <mat-error *ngIf="
            !addTranslatorForm.get('confirm_password').hasError('required') &&
            addTranslatorForm.get('confirm_password').hasError('passwordsNotMatching')
          ">
                Passwords must match
              </mat-error>
            </mat-form-field>
          </div>

          <h4>Personal Information</h4>
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Title</mat-label>
              <mat-select placeholder="Select Title" formControlName="title">
                <mat-option value="Mr">Mr</mat-option>
                <mat-option value="Mrs">Mrs</mat-option>
                <mat-option value="Ms">MS</mat-option>
                <mat-option value="Dr">Dr</mat-option>
              </mat-select>
              <mat-error *ngIf="addTranslatorForm.get('title').hasError('required')">
                Title is required!
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">First Name*</mat-label>
              <input matInput formControlName="first_name">
              <mat-error *ngIf="addTranslatorForm.get('first_name').hasError('required')">
                First Name is required!
              </mat-error>
              <mat-error *ngIf="!addTranslatorForm.get('first_name').hasError('required') &&
            addTranslatorForm.get('first_name').hasError('maxlength')">
                First Name can have 30 characters Maximum!
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Middle Name</mat-label>
              <input matInput formControlName="middle_name">
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Last Name*</mat-label>
              <input matInput formControlName="last_name">
              <mat-error *ngIf="addTranslatorForm.get('last_name').hasError('required')">
                Last Name is required!
              </mat-error>
              <mat-error *ngIf="!addTranslatorForm.get('last_name').hasError('required') &&
            addTranslatorForm.get('last_name').hasError('maxlength')">
                Last Name can have 30 characters Maximum!
              </mat-error>
            </mat-form-field>
          </div>

          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="33">
              <mat-label class="font-size-12">Gender</mat-label>
              <mat-select placeholder="Select Gender" formControlName="gender">
                <mat-option value="">Select</mat-option>
                <mat-option value=1>Male</mat-option>
                <mat-option value=2>Female</mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field fxFlex="33">
              <mat-label class="font-size-12">Date of Birth</mat-label>
              <input matInput (click)="dp2.open()" [matDatepicker]="dp2" formControlName="dob">
              <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>



            <!-- <mat-form-field fxFlex="25">
              <mat-label>Timezone</mat-label>
              <mat-select formControlName="timezone">
                <mat-option value="Asia/Kabul">Afghanistan UTC +04:30</mat-option>
                <mat-option value="Europe/Tirane">Albania UTC +01:00</mat-option>
                <mat-option value="Africa/Algiers">Algeria UTC +01:00</mat-option>
                <mat-option value="Pacific/Pago_Pago">American Samoa UTC -11:00</mat-option>
                <mat-option value="Europe/Andorra">Andorra UTC +01:00</mat-option>
                <mat-option value="Africa/Luanda">Angola UTC +01:00</mat-option>
                <mat-option value="America/Anguilla">Anguilla UTC -04:00</mat-option>
                <mat-option value="Antarctica/Casey">Antarctica UTC +11:00</mat-option>
                <mat-option value="Antarctica/Davis">Antarctica UTC +07:00</mat-option>
                <mat-option value="Antarctica/DumontDUrville">Antarctica UTC +10:00</mat-option>
                <mat-option value="Antarctica/Mawson">Antarctica UTC +05:00</mat-option>
                <mat-option value="Antarctica/McMurdo">Antarctica UTC +13:00</mat-option>
                <mat-option value="Antarctica/Palmer">Antarctica UTC -03:00</mat-option>
                <mat-option value="Antarctica/Rothera">Antarctica UTC -03:00</mat-option>
                <mat-option value="Antarctica/Syowa">Antarctica UTC +03:00</mat-option>
                <mat-option value="Antarctica/Troll">Antarctica UTC</mat-option>
                <mat-option value="Antarctica/Vostok">Antarctica UTC +06:00</mat-option>
                <mat-option value="America/Antigua">Antigua and Barbuda UTC -04:00</mat-option>
                <mat-option value="America/Argentina/Buenos_Aires">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Catamarca">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Cordoba">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Jujuy">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/La_Rioja">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Mendoza">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Rio_Gallegos">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Salta">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/San_Juan">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/San_Luis">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Tucuman">Argentina UTC -03:00</mat-option>
                <mat-option value="America/Argentina/Ushuaia">Argentina UTC -03:00</mat-option>
                <mat-option value="Asia/Yerevan">Armenia UTC +04:00</mat-option>
                <mat-option value="America/Aruba">Aruba UTC -04:00</mat-option>
                <mat-option value="Antarctica/Macquarie">Australia UTC +11:00</mat-option>
                <mat-option value="Australia/Adelaide">Australia UTC +10:30</mat-option>
                <mat-option value="Australia/Brisbane">Australia UTC +10:00</mat-option>
                <mat-option value="Australia/Broken_Hill">Australia UTC +10:30</mat-option>
                <mat-option value="Australia/Darwin">Australia UTC +09:30</mat-option>
                <mat-option value="Australia/Eucla">Australia UTC +08:45</mat-option>
                <mat-option value="Australia/Hobart">Australia UTC +11:00</mat-option>
                <mat-option value="Australia/Lindeman">Australia UTC +10:00</mat-option>
                <mat-option value="Australia/Lord_Howe">Australia UTC +11:00</mat-option>
                <mat-option value="Australia/Melbourne">Australia UTC +11:00</mat-option>
                <mat-option value="Australia/Perth">Australia UTC +08:00</mat-option>
                <mat-option value="Australia/Sydney">Australia UTC +11:00</mat-option>
                <mat-option value="Europe/Vienna">Austria UTC +01:00</mat-option>
                <mat-option value="Asia/Baku">Azerbaijan UTC +04:00</mat-option>
                <mat-option value="America/Nassau">Bahamas UTC -05:00</mat-option>
                <mat-option value="Asia/Bahrain">Bahrain UTC +03:00</mat-option>
                <mat-option value="Asia/Dhaka">Bangladesh UTC +06:00</mat-option>
                <mat-option value="America/Barbados">Barbados UTC -04:00</mat-option>
                <mat-option value="Europe/Minsk">Belarus UTC +03:00</mat-option>
                <mat-option value="Europe/Brussels">Belgium UTC +01:00</mat-option>
                <mat-option value="America/Belize">Belize UTC -06:00</mat-option>
                <mat-option value="Africa/Porto-Novo">Benin UTC +01:00</mat-option>
                <mat-option value="Atlantic/Bermuda">Bermuda UTC -04:00</mat-option>
                <mat-option value="Asia/Thimphu">Bhutan UTC +06:00</mat-option>
                <mat-option value="America/La_Paz">Bolivia, Plurinational State of UTC -04:00</mat-option>
                <mat-option value="America/Kralendijk">Bonaire, Sint Eustatius and Saba UTC -04:00</mat-option>
                <mat-option value="Europe/Sarajevo">Bosnia and Herzegovina UTC +01:00</mat-option>
                <mat-option value="Africa/Gaborone">Botswana UTC +02:00</mat-option>
                <mat-option value="America/Araguaina">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Bahia">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Belem">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Boa_Vista"> Brazil UTC -04:00</mat-option>
                <mat-option value="America/Campo_Grande">Brazil UTC -04:00</mat-option>
                <mat-option value="America/Cuiaba">Brazil UTC -04:00</mat-option>
                <mat-option value="America/Eirunepe">Brazil UTC -05:00</mat-option>
                <mat-option value="America/Fortaleza">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Maceio">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Manaus">Brazil UTC -04:00</mat-option>
                <mat-option value="America/Noronha">Brazil UTC -02:00</mat-option>
                <mat-option value="America/Porto_Velho">Brazil UTC -04:00</mat-option>
                <mat-option value="America/Recife">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Rio_Branco">Brazil UTC -05:00</mat-option>
                <mat-option value="America/Santarem">Brazil UTC -03:00</mat-option>
                <mat-option value="America/Sao_Paulo">Brazil UTC -03:00</mat-option>
                <mat-option value="Indian/Chagos">British Indian Ocean Territory UTC +06:00</mat-option>
                <mat-option value="Asia/Brunei">Brunei Darussalam UTC +08:00</mat-option>
                <mat-option value="Europe/Sofia">Bulgaria UTC +02:00</mat-option>
                <mat-option value="Africa/Ouagadougou">Burkina Faso UTC</mat-option>
                <mat-option value="Africa/Bujumbura">Burundi UTC +02:00</mat-option>
                <mat-option value="Asia/Phnom_Penh">Cambodia UTC +07:00</mat-option>
                <mat-option value="Africa/Douala">Cameroon UTC +01:00</mat-option>
                <mat-option value="America/Atikokan">Canada UTC -05:00</mat-option>
                <mat-option value="America/Blanc-Sablon">Canada UTC -04:00</mat-option>
                <mat-option value="America/Cambridge_Bay">Canada UTC -07:00</mat-option>
                <mat-option value="America/Creston">Canada UTC -07:00</mat-option>
                <mat-option value="America/Dawson">Canada UTC -07:00</mat-option>
                <mat-option value="America/Dawson_Creek">Canada UTC -07:00</mat-option>
                <mat-option value="America/Edmonton">Canada UTC -07:00</mat-option>
                <mat-option value="America/Fort_Nelson">Canada UTC -07:00</mat-option>
                <mat-option value="America/Glace_Bay">Canada UTC -04:00</mat-option>
                <mat-option value="America/Goose_Bay">Canada UTC -04:00</mat-option>
                <mat-option value="America/Halifax">Canada UTC -04:00</mat-option>
                <mat-option value="America/Inuvik">Canada UTC -07:00</mat-option>
                <mat-option value="America/Iqaluit">Canada UTC -05:00</mat-option>
                <mat-option value="America/Moncton">Canada UTC -04:00</mat-option>
                <mat-option value="America/Nipigon">Canada UTC -05:00</mat-option>
                <mat-option value="America/Pangnirtung">Canada UTC -05:00</mat-option>
                <mat-option value="America/Rainy_River">Canada UTC -06:00</mat-option>
                <mat-option value="America/Rankin_Inlet">Canada UTC -06:00</mat-option>
                <mat-option value="America/Regina">Canada UTC -06:00</mat-option>
                <mat-option value="America/Resolute">Canada UTC -06:00</mat-option>
                <mat-option value="America/St_Johns">Canada UTC -03:30</mat-option>
                <mat-option value="America/Swift_Current">Canada UTC -06:00</mat-option>
                <mat-option value="America/Thunder_Bay">Canada UTC -05:00</mat-option>
                <mat-option value="America/Toronto">Canada UTC -05:00</mat-option>
                <mat-option value="America/Vancouver">Canada UTC -08:00</mat-option>
                <mat-option value="America/Whitehorse">Canada UTC -07:00</mat-option>
                <mat-option value="America/Winnipeg">Canada UTC -06:00</mat-option>
                <mat-option value="America/Yellowknife">Canada UTC -07:00</mat-option>
                <mat-option value="Atlantic/Cape_Verde">Cape Verde UTC -01:00</mat-option>
                <mat-option value="America/Cayman">Cayman Islands UTC -05:00</mat-option>
                <mat-option value="Africa/Bangui">Central African Republic UTC +01:00</mat-option>
                <mat-option value="Africa/Ndjamena">Chad UTC +01:00</mat-option>
                <mat-option value="America/Punta_Arenas">Chile UTC -03:00</mat-option>
                <mat-option value="America/Santiago">Chile UTC -03:00</mat-option>
                <mat-option value="Pacific/Easter">Chile UTC -05:00</mat-option>
                <mat-option value="Asia/Shanghai">China UTC +08:00</mat-option>
                <mat-option value="Asia/Urumqi">China UTC +06:00</mat-option>
                <mat-option value="Indian/Christmas">Christmas Island UTC +07:00</mat-option>
                <mat-option value="Indian/Cocos">Cocos (Keeling) Islands UTC +06:30</mat-option>
                <mat-option value="America/Bogota">Colombia UTC -05:00</mat-option>
                <mat-option value="Indian/Comoro">Comoros UTC +03:00</mat-option>
                <mat-option value="Africa/Brazzaville">Congo UTC +01:00</mat-option>
                <mat-option value="Africa/Kinshasa">Congo, the Democratic Republic of the UTC +01:00</mat-option>
                <mat-option value="Africa/Lubumbashi">Congo, the Democratic Republic of the UTC +02:00</mat-option>
                <mat-option value="Pacific/Rarotonga">Cook Islands UTC -10:00</mat-option>
                <mat-option value="America/Costa_Rica">Costa Rica UTC -06:00</mat-option>
                <mat-option value="Europe/Zagreb">Croatia UTC +01:00</mat-option>
                <mat-option value="America/Havana">Cuba UTC -05:00</mat-option>
                <mat-option value="America/Curacao">Curacao UTC -04:00</mat-option>
                <mat-option value="Asia/Famagusta">Cyprus UTC +02:00</mat-option>
                <mat-option value="Asia/Nicosia">Cyprus UTC +02:00</mat-option>
                <mat-option value="Europe/Prague">Czech Republic UTC +01:00</mat-option>
                <mat-option value="Africa/Abidjan">Cote d'Ivoire UTC</mat-option>
                <mat-option value="Europe/Copenhagen">Denmark UTC +01:00</mat-option>
                <mat-option value="Africa/Djibouti">Djibouti UTC +03:00</mat-option>
                <mat-option value="America/Dominica">Dominica UTC -04:00</mat-option>
                <mat-option value="America/Santo_Domingo">Dominican Republic UTC -04:00</mat-option>
                <mat-option value="America/Guayaquil">Ecuador UTC -05:00</mat-option>
                <mat-option value="Pacific/Galapagos">Ecuador UTC -06:00</mat-option>
                <mat-option value="Africa/Cairo">Egypt UTC +02:00</mat-option>
                <mat-option value="America/El_Salvador">El Salvador UTC -06:00</mat-option>
                <mat-option value="Africa/Malabo">Equatorial Guinea UTC +01:00</mat-option>
                <mat-option value="Africa/Asmara">Eritrea UTC +03:00</mat-option>
                <mat-option value="Europe/Tallinn">Estonia UTC +02:00</mat-option>
                <mat-option value="Africa/Addis_Ababa">Ethiopia UTC +03:00</mat-option>
                <mat-option value="Atlantic/Stanley">Falkland Islands (Malvinas) UTC -03:00</mat-option>
                <mat-option value="Atlantic/Faroe">Faroe Islands UTC</mat-option>
                <mat-option value="Pacific/Fiji">Fiji UTC +12:00</mat-option>
                <mat-option value="Europe/Helsinki">Finland UTC +02:00</mat-option>
                <mat-option value="Europe/Paris">France UTC +01:00</mat-option>
                <mat-option value="America/Cayenne">French Guiana UTC -03:00</mat-option>
                <mat-option value="Pacific/Gambier">French Polynesia UTC -09:00</mat-option>
                <mat-option value="Pacific/Marquesas">French Polynesia UTC -09:30</mat-option>
                <mat-option value="Pacific/Tahiti">French Polynesia UTC -10:00</mat-option>
                <mat-option value="Indian/Kerguelen">French Southern Territories UTC +05:00</mat-option>
                <mat-option value="Africa/Libreville">Gabon UTC +01:00</mat-option>
                <mat-option value="Africa/Banjul">Gambia UTC</mat-option>
                <mat-option value="Asia/Tbilisi">GE Georgia UTC +04:00</mat-option>
                <mat-option value="Europe/Berlin">Germany UTC +01:00</mat-option>
                <mat-option value="Europe/Busingen">Germany UTC +01:00</mat-option>
                <mat-option value="Africa/Accra">Ghana UTC</mat-option>
                <mat-option value="Europe/Gibraltar">Gibraltar UTC +01:00</mat-option>
                <mat-option value="Europe/Athens">Greece UTC +02:00</mat-option>
                <mat-option value="America/Danmarkshavn">Greenland UTC</mat-option>
                <mat-option value="America/Nuuk">Greenland UTC -03:00</mat-option>
                <mat-option value="America/Scoresbysund">Greenland UTC -01:00</mat-option>
                <mat-option value="America/Thule">Greenland UTC -04:00</mat-option>
                <mat-option value="America/Grenada">Grenada UTC -04:00</mat-option>
                <mat-option value="America/Guadeloupe">Guadeloupe UTC -04:00</mat-option>
                <mat-option value="Pacific/Guam">Guam UTC +10:00</mat-option>
                <mat-option value="America/Guatemala">Guatemala UTC -06:00</mat-option>
                <mat-option value="Europe/Guernsey">Guernsey UTC</mat-option>
                <mat-option value="Africa/Conakry">Guinea UTC</mat-option>
                <mat-option value="Africa/Bissau">Guinea-Bissau UTC</mat-option>
                <mat-option value="America/Guyana">Guyana UTC -04:00</mat-option>
                <mat-option value="America/Port-au-Prince">Haiti UTC -05:00</mat-option>
                <mat-option value="Europe/Vatican">Holy See (Vatican City State) UTC +01:00</mat-option>
                <mat-option value="America/Tegucigalpa">Honduras UTC -06:00</mat-option>
                <mat-option value="Asia/Hong_Kong">Hong Kong UTC +08:00</mat-option>
                <mat-option value="Europe/Budapest">Hungary UTC +01:00</mat-option>
                <mat-option value="Atlantic/Reykjavik">Iceland UTC</mat-option>
                <mat-option value="Asia/Kolkata">India UTC +05:30</mat-option>
                <mat-option value="Asia/Jakarta">Indonesia UTC +07:00</mat-option>
                <mat-option value="Asia/Jayapura">Indonesia UTC +09:00</mat-option>
                <mat-option value="Asia/Makassar">Indonesia UTC +08:00</mat-option>
                <mat-option value="Asia/Pontianak">Indonesia UTC +07:00</mat-option>
                <mat-option value="Asia/Tehran">Iran, Islamic Republic of UTC +03:30</mat-option>
                <mat-option value="Asia/Baghdad">Iraq UTC +03:00</mat-option>
                <mat-option value="Europe/Dublin">Ireland UTC</mat-option>
                <mat-option value="Europe/Isle_of_Man">Isle of Man UTC</mat-option>
                <mat-option value="Asia/Jerusalem">Israel UTC +02:00</mat-option>
                <mat-option value="Europe/Rome">Italy UTC +01:00</mat-option>
                <mat-option value="America/Jamaica">Jamaica UTC -05:00</mat-option>
                <mat-option value="Asia/Tokyo">Japan UTC +09:00</mat-option>
                <mat-option value="Europe/Jersey">Jersey UTC</mat-option>
                <mat-option value="Asia/Amman">Jordan UTC +02:00</mat-option>
                <mat-option value="Asia/Almaty">Kazakhstan UTC +06:00</mat-option>
                <mat-option value="Asia/Aqtau">Kazakhstan UTC +05:00</mat-option>
                <mat-option value="Asia/Aqtobe">Kazakhstan UTC +05:00</mat-option>
                <mat-option value="Asia/Atyrau">Kazakhstan UTC +05:00</mat-option>
                <mat-option value="Asia/Oral">Kazakhstan UTC +05:00</mat-option>
                <mat-option value="Asia/Qostanay">Kazakhstan UTC +06:00</mat-option>
                <mat-option value="Asia/Qyzylorda">Kazakhstan UTC +05:00</mat-option>
                <mat-option value="Africa/Nairobi">Kenya UTC +03:00</mat-option>
                <mat-option value="Pacific/Kanton">Kiribati UTC +13:00</mat-option>
                <mat-option value="Pacific/Kiritimati">Kiribati UTC +14:00</mat-option>
                <mat-option value="Pacific/Tarawa">Kiribati UTC +12:00</mat-option>
                <mat-option value="Asia/Pyongyang">Korea, Democratic People's Republic of UTC +09:00</mat-option>
                <mat-option value="Asia/Seoul">Korea, Republic of UTC +09:00</mat-option>
                <mat-option value="Asia/Kuwait">Kuwait UTC +03:00</mat-option>
                <mat-option value="Asia/Bishkek">Kyrgyzstan UTC +06:00</mat-option>
                <mat-option value="Asia/Vientiane">Lao People's Democratic Republic UTC +07:00</mat-option>
                <mat-option value="Europe/Riga">Latvia UTC +02:00</mat-option>
                <mat-option value="Asia/Beirut">Lebanon UTC +02:00</mat-option>
                <mat-option value="Africa/Maseru">Lesotho UTC +02:00</mat-option>
                <mat-option value="Africa/Monrovia">Liberia UTC</mat-option>
                <mat-option value="Africa/Tripoli">Libya UTC +02:00</mat-option>
                <mat-option value="Europe/Vaduz">Liechtenstein UTC +01:00</mat-option>
                <mat-option value="Europe/Vilnius">Lithuania UTC +02:00</mat-option>
                <mat-option value="Europe/Luxembourg">Luxembourg UTC +01:00</mat-option>
                <mat-option value="Asia/Macau">Macao UTC +08:00</mat-option>
                <mat-option value="Europe/Skopje">Macedonia, the Former Yugoslav Republic of UTC +01:00</mat-option>
                <mat-option value="Indian/Antananarivo">Madagascar UTC +03:00</mat-option>
                <mat-option value="Africa/Blantyre">Malawi UTC +02:00</mat-option>
                <mat-option value="Asia/Kuala_Lumpur">Malaysia UTC +08:00</mat-option>
                <mat-option value="Asia/Kuching">Malaysia UTC +08:00</mat-option>
                <mat-option value="Indian/Maldives">Maldives UTC +05:00</mat-option>
                <mat-option value="Africa/Bamako">Mali UTC</mat-option>
                <mat-option value="Europe/Malta">Malta UTC +01:00</mat-option>
                <mat-option value="Pacific/Kwajalein">Marshall Islands UTC +12:00</mat-option>
                <mat-option value="Pacific/Majuro">Marshall Islands UTC +12:00</mat-option>
                <mat-option value="America/Martinique">Martinique UTC -04:00</mat-option>
                <mat-option value="Africa/Nouakchott">Mauritania UTC</mat-option>
                <mat-option value="Indian/Mauritius">Mauritius UTC +04:00</mat-option>
                <mat-option value="Indian/Mayotte">Mayotte UTC +03:00</mat-option>
                <mat-option value="America/Bahia_Banderas">Mexico UTC -06:00</mat-option>
                <mat-option value="America/Cancun">Mexico UTC -05:00</mat-option>
                <mat-option value="America/Chihuahua">Mexico UTC -07:00</mat-option>
                <mat-option value="America/Hermosillo">Mexico UTC -07:00</mat-option>
                <mat-option value="America/Matamoros">Mexico UTC -06:00</mat-option>
                <mat-option value="America/Mazatlan">Mexico UTC -07:00</mat-option>
                <mat-option value="America/Merida">Mexico UTC -06:00</mat-option>
                <mat-option value="America/Mexico_City">Mexico UTC -06:00</mat-option>
                <mat-option value="America/Monterrey">Mexico UTC -06:00</mat-option>
                <mat-option value="America/Ojinaga">Mexico UTC -07:00</mat-option>
                <mat-option value="America/Tijuana">Mexico UTC -08:00</mat-option>
                <mat-option value="Pacific/Chuuk">Micronesia, Federated States of UTC +10:00</mat-option>
                <mat-option value="Pacific/Kosrae">Micronesia, Federated States of UTC +11:00</mat-option>
                <mat-option value="Pacific/Pohnpei">Micronesia, Federated States of UTC +11:00</mat-option>
                <mat-option value="Europe/Chisinau">Moldova, Republic of UTC +02:00</mat-option>
                <mat-option value="Europe/Monaco">Monaco UTC +01:00</mat-option>
                <mat-option value="Asia/Choibalsan">Mongolia UTC +08:00</mat-option>
                <mat-option value="Asia/Hovd">Mongolia UTC +07:00</mat-option>
                <mat-option value="Asia/Ulaanbaatar">Mongolia UTC +08:00</mat-option>
                <mat-option value="Europe/Podgorica">Montenegro UTC +01:00</mat-option>
                <mat-option value="America/Montserrat">Montserrat UTC -04:00</mat-option>
                <mat-option value="Africa/Casablanca">Morocco UTC +01:00</mat-option>
                <mat-option value="Africa/Maputo">Mozambique UTC +02:00</mat-option>
                <mat-option value="Asia/Yangon">Myanmar UTC +06:30</mat-option>
                <mat-option value="Africa/Windhoek">Namibia UTC +02:00</mat-option>
                <mat-option value="Pacific/Nauru">Nauru UTC +12:00</mat-option>
                <mat-option value="Asia/Kathmandu">Nepal UTC +05:45</mat-option>
                <mat-option value="Europe/Amsterdam">Netherlands UTC +01:00</mat-option>
                <mat-option value="Pacific/Noumea">New Caledonia UTC +11:00</mat-option>
                <mat-option value="Pacific/Auckland">New Zealand UTC +13:00</mat-option>
                <mat-option value="Pacific/Chatham">New Zealand UTC +13:45</mat-option>
                <mat-option value="America/Managua">Nicaragua UTC -06:00</mat-option>
                <mat-option value="Africa/Niamey">Niger UTC +01:00</mat-option>
                <mat-option value="Africa/Lagos">Nigeria UTC +01:00</mat-option>
                <mat-option value="Pacific/Niue">Niue UTC -11:00</mat-option>
                <mat-option value="Pacific/Norfolk">Norfolk Island UTC +12:00</mat-option>
                <mat-option value="Pacific/Saipan">Northern Mariana Islands UTC +10:00</mat-option>
                <mat-option value="Europe/Oslo">Norway UTC +01:00</mat-option>
                <mat-option value="Asia/Muscat">Oman UTC +04:00</mat-option>
                <mat-option value="Asia/Karachi">Pakistan UTC +05:00</mat-option>
                <mat-option value="Pacific/Palau">Palau UTC +09:00</mat-option>
                <mat-option value="Asia/Gaza">Palestine, State of UTC +02:00</mat-option>
                <mat-option value="Asia/Hebron">Palestine, State of UTC +02:00</mat-option>
                <mat-option value="America/Panama">Panama UTC -05:00</mat-option>
                <mat-option value="Pacific/Bougainville">Papua New Guinea UTC +11:00</mat-option>
                <mat-option value="Pacific/Port_Moresby">Papua New Guinea UTC +10:00</mat-option>
                <mat-option value="America/Asuncion">Paraguay UTC -03:00</mat-option>
                <mat-option value="America/Lima">Peru UTC -05:00</mat-option>
                <mat-option value="Asia/Manila">Philippines UTC +08:00</mat-option>
                <mat-option value="Pacific/Pitcairn">Pitcairn UTC -08:00</mat-option>
                <mat-option value="Europe/Warsaw">Poland UTC +01:00</mat-option>
                <mat-option value="Atlantic/Azores">Portugal UTC -01:00</mat-option>
                <mat-option value="Atlantic/Madeira">Portugal UTC</mat-option>
                <mat-option value="Europe/Lisbon">Portugal UTC</mat-option>
                <mat-option value="America/Puerto_Rico">Puerto Rico UTC -04:00</mat-option>
                <mat-option value="Asia/Qatar">Qatar UTC +03:00</mat-option>
                <mat-option value="Europe/Bucharest">Romania UTC +02:00</mat-option>
                <mat-option value="Asia/Anadyr">Russian Federation UTC +12:00</mat-option>
                <mat-option value="Asia/Barnaul">Russian Federation UTC +07:00</mat-option>
                <mat-option value="Asia/Chita">Russian Federation UTC +09:00</mat-option>
                <mat-option value="Asia/Irkutsk">Russian Federation UTC +08:00</mat-option>
                <mat-option value="Asia/Kamchatka">Russian Federation UTC +12:00</mat-option>
                <mat-option value="Asia/Khandyga">Russian Federation UTC +09:00</mat-option>
                <mat-option value="Asia/Krasnoyarsk">Russian Federation UTC +07:00</mat-option>
                <mat-option value="Asia/Magadan">Russian Federation UTC +11:00</mat-option>
                <mat-option value="Asia/Novokuznetsk">Russian Federation UTC +07:00</mat-option>
                <mat-option value="Asia/Novosibirsk">Russian Federation UTC +07:00</mat-option>
                <mat-option value="Asia/Omsk">Russian Federation UTC +06:00</mat-option>
                <mat-option value="Asia/Sakhalin">Russian Federation UTC +11:00</mat-option>
                <mat-option value="Asia/Srednekolymsk">Russian Federation UTC +11:00</mat-option>
                <mat-option value="Asia/Tomsk">Russian Federation UTC +07:00</mat-option>
                <mat-option value="Asia/Ust-Nera">Russian Federation UTC +10:00</mat-option>
                <mat-option value="Asia/Vladivostok">Russian Federation UTC +10:00</mat-option>
                <mat-option value="Asia/Yakutsk">Russian Federation UTC +09:00</mat-option>
                <mat-option value="Asia/Yekaterinburg">Russian Federation UTC +05:00</mat-option>
                <mat-option value="Europe/Astrakhan">Russian Federation UTC +04:00</mat-option>
                <mat-option value="Europe/Kaliningrad">Russian Federation UTC +02:00</mat-option>
                <mat-option value="Europe/Kirov">Russian Federation UTC +03:00</mat-option>
                <mat-option value="Europe/Moscow">Russian Federation UTC +03:00</mat-option>
                <mat-option value="Europe/Samara">Russian Federation UTC +04:00</mat-option>
                <mat-option value="Europe/Saratov">Russian Federation UTC +04:00</mat-option>
                <mat-option value="Europe/Ulyanovsk">Russian Federation UTC +04:00</mat-option>
                <mat-option value="Europe/Volgograd">Russian Federation UTC +03:00</mat-option>
                <mat-option value="Africa/Kigali">Rwanda UTC +02:00</mat-option>
                <mat-option value="Indian/Reunion">Reunion UTC +04:00</mat-option>
                <mat-option value="America/St_Barthelemy">Saint Barthélemy UTC -04:00</mat-option>
                <mat-option value="Atlantic/St_Helena">Saint Helena, Ascension and Tristan da Cunha UTC</mat-option>
                <mat-option value="America/St_Kitts">Saint Kitts and Nevis UTC -04:00</mat-option>
                <mat-option value="America/St_Lucia">Saint Lucia UTC -04:00</mat-option>
                <mat-option value="America/Marigot">Saint Martin (French part) UTC -04:00</mat-option>
                <mat-option value="America/Miquelon">Saint Pierre and Miquelon UTC -03:00</mat-option>
                <mat-option value="America/St_Vincent">Saint Vincent and the Grenadines UTC -04:00</mat-option>
                <mat-option value="Pacific/Apia">Samoa UTC +13:00</mat-option>
                <mat-option value="Europe/San_Marino">San Marino UTC +01:00</mat-option>
                <mat-option value="Africa/Sao_Tome">Sao Tome and Principe UTC</mat-option>
                <mat-option value="Asia/Riyadh">Saudi Arabia UTC +03:00</mat-option>
                <mat-option value="Africa/Dakar">Senegal UTC</mat-option>
                <mat-option value="Europe/Belgrade">Serbia UTC +01:00</mat-option>
                <mat-option value="Indian/Mahe">Seychelles UTC +04:00</mat-option>
                <mat-option value="Africa/Freetown">Sierra Leone UTC</mat-option>
                <mat-option value="Asia/Singapore">Singapore UTC +08:00</mat-option>
                <mat-option value="America/Lower_Princes">Sint Maarten (Dutch part) UTC -04:00</mat-option>
                <mat-option value="Europe/Bratislava">Slovakia UTC +01:00</mat-option>
                <mat-option value="Europe/Ljubljana">Slovenia UTC +01:00</mat-option>
                <mat-option value="Pacific/Guadalcanal">Solomon Islands UTC +11:00</mat-option>
                <mat-option value="Africa/Mogadishu">Somalia UTC +03:00</mat-option>
                <mat-option value="Africa/Johannesburg">South Africa UTC +02:00</mat-option>
                <mat-option value="Atlantic/South_Georgia">South Georgia and the South Sandwich Islands UTC -02:00
                </mat-option>
                <mat-option value="Africa/Juba">South Sudan UTC +02:00</mat-option>
                <mat-option value="Africa/Ceuta">Spain UTC +01:00</mat-option>
                <mat-option value="Atlantic/Canary">Spain UTC</mat-option>
                <mat-option value="Europe/Madrid">Spain UTC +01:00</mat-option>
                <mat-option value="Asia/Colombo">Sri Lanka UTC +05:30</mat-option>
                <mat-option value="Africa/Khartoum">Sudan UTC +02:00</mat-option>
                <mat-option value="America/Paramaribo">Suriname UTC -03:00</mat-option>
                <mat-option value="Arctic/Longyearbyen">Svalbard and Jan Mayen UTC +01:00</mat-option>
                <mat-option value="Africa/Mbabane">Swaziland UTC +02:00</mat-option>
                <mat-option value="Europe/Stockholm">Sweden UTC +01:00</mat-option>
                <mat-option value="Europe/Zurich">Switzerland UTC +01:00</mat-option>
                <mat-option value="Asia/Damascus">Syrian Arab Republic UTC +02:00</mat-option>
                <mat-option value="Asia/Taipei">Taiwan, Province of China UTC +08:00</mat-option>
                <mat-option value="Asia/Dushanbe">Tajikistan UTC +05:00</mat-option>
                <mat-option value="Africa/Dar_es_Salaam">Tanzania, United Republic of UTC +03:00</mat-option>
                <mat-option value="Asia/Bangkok">Thailand UTC +07:00</mat-option>
                <mat-option value="Asia/Dili">Timor-Leste UTC +09:00</mat-option>
                <mat-option value="Africa/Lome">Togo UTC</mat-option>
                <mat-option value="Pacific/Fakaofo">Tokelau UTC +13:00</mat-option>
                <mat-option value="Pacific/Tongatapu">Tonga UTC +13:00</mat-option>
                <mat-option value="America/Port_of_Spain">Trinidad and Tobago UTC -04:00</mat-option>
                <mat-option value="Africa/Tunis">Tunisia UTC +01:00</mat-option>
                <mat-option value="Europe/Istanbul">Turkey UTC +03:00</mat-option>
                <mat-option value="Asia/Ashgabat">Turkmenistan UTC +05:00</mat-option>
                <mat-option value="America/Grand_Turk">Turks and Caicos Islands UTC -05:00</mat-option>
                <mat-option value="Pacific/Funafuti">Tuvalu UTC +12:00</mat-option>
                <mat-option value="Africa/Kampala">Uganda UTC +03:00</mat-option>
                <mat-option value="Europe/Kiev">Ukraine UTC +02:00</mat-option>
                <mat-option value="Europe/Simferopol">Ukraine UTC +03:00</mat-option>
                <mat-option value="Europe/Uzhgorod">Ukraine UTC +02:00</mat-option>
                <mat-option value="Europe/Zaporozhye">Ukraine UTC +02:00</mat-option>
                <mat-option value="Asia/Dubai">United Arab Emirates UTC +04:00</mat-option>
                <mat-option value="Europe/London">United Kingdom UTC</mat-option>
                <mat-option value="America/Adak">United States UTC -10:00</mat-option>
                <mat-option value="America/Anchorage">United States UTC -09:00</mat-option>
                <mat-option value="America/Boise">United States UTC -07:00</mat-option>
                <mat-option value="America/Chicago">United States UTC -06:00</mat-option>
                <mat-option value="America/Denver">United States UTC -07:00</mat-option>
                <mat-option value="America/Detroit">United States UTC -05:00</mat-option>
                <mat-option value="America/Indiana/Indianapolis">United States UTC -05:00</mat-option>
                <mat-option value="America/Indiana/Knox">United States UTC -06:00</mat-option>
                <mat-option value="America/Indiana/Marengo">United States UTC -05:00</mat-option>
                <mat-option value="America/Indiana/Petersburg">United States UTC -05:00</mat-option>
                <mat-option value="America/Indiana/Tell_City">United States UTC -06:00</mat-option>
                <mat-option value="America/Indiana/Vevay">United States UTC -05:00</mat-option>
                <mat-option value="America/Indiana/Vincennes">United States UTC -05:00</mat-option>
                <mat-option value="America/Indiana/Winamac">United States UTC -05:00</mat-option>
                <mat-option value="America/Juneau">United States UTC -09:00</mat-option>
                <mat-option value="America/Kentucky/Louisville">United States UTC -05:00</mat-option>
                <mat-option value="America/Kentucky/Monticello">United States UTC -05:00</mat-option>
                <mat-option value="America/Los_Angeles">United States UTC -08:00</mat-option>
                <mat-option value="America/Menominee">United States UTC -06:00</mat-option>
                <mat-option value="America/Metlakatla">United States UTC -09:00</mat-option>
                <mat-option value="America/New_York">United States UTC -05:00</mat-option>
                <mat-option value="America/Nome">United States UTC -09:00</mat-option>
                <mat-option value="America/North_Dakota/Beulah">United States UTC -06:00</mat-option>
                <mat-option value="America/North_Dakota/Center">United States UTC -06:00</mat-option>
                <mat-option value="America/North_Dakota/New_Salem">United States UTC -06:00</mat-option>
                <mat-option value="America/Phoenix">United States UTC -07:00</mat-option>
                <mat-option value="America/Sitka">United States UTC -09:00</mat-option>
                <mat-option value="America/Yakutat">United States UTC -09:00</mat-option>
                <mat-option value="Pacific/Honolulu">United States UTC -10:00</mat-option>
                <mat-option value="Pacific/Midway">United States Minor Outlying Islands UTC -11:00</mat-option>
                <mat-option value="Pacific/Wake">United States Minor Outlying Islands UTC +12:00</mat-option>
                <mat-option value="America/Montevideo">Uruguay UTC -03:00</mat-option>
                <mat-option value="Asia/Samarkand">Uzbekistan UTC +05:00</mat-option>
                <mat-option value="Asia/Tashkent">Uzbekistan UTC +05:00</mat-option>
                <mat-option value="Pacific/Efate">Vanuatu UTC +11:00</mat-option>
                <mat-option value="America/Caracas">Venezuela, Bolivarian Republic of UTC -04:00</mat-option>
                <mat-option value="Asia/Ho_Chi_Minh">Viet Nam UTC +07:00</mat-option>
                <mat-option value="America/Tortola">Virgin Islands, British UTC -04:00</mat-option>
                <mat-option value="America/St_Thomas">Virgin Islands, U.S. UTC -04:00</mat-option>
                <mat-option value="Pacific/Wallis">Wallis and Futuna UTC +12:00</mat-option>
                <mat-option value="Africa/El_Aaiun">Western Sahara UTC +01:00</mat-option>
                <mat-option value="Asia/Aden">Yemen UTC +03:00</mat-option>
                <mat-option value="Africa/Lusaka">Zambia UTC +02:00</mat-option>
                <mat-option value="Africa/Harare">Zimbabwe UTC +02:00</mat-option>
                <mat-option value="Europe/Mariehamn">Aland Islands UTC +02:00</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>

          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Skype id</mat-label>
              <input matInput formControlName="skype_id">
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Phone</mat-label>
              <input matInput formControlName="phone">
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Alternate Phone</mat-label>
              <input matInput formControlName="phone_sec">
            </mat-form-field>
          </div>
          <div class="input-row">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12">Address</mat-label>
              <textarea matInput formControlName="address1"></textarea>
            </mat-form-field>
          </div>

          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">City</mat-label>
              <input matInput formControlName="city">
            </mat-form-field>
            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">State/Province</mat-label>
              <input matInput formControlName="state">
            </mat-form-field>

            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Postal Code</mat-label>
              <input matInput formControlName="postal_code">
            </mat-form-field>

            <mat-form-field fxFlex="80">
              <mat-label class="font-size-12">Country</mat-label>
              <input matInput formControlName="country">
            </mat-form-field>
          </div>
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="30">
              <mat-label class="font-size-12">Timezone</mat-label>
              <mat-select formControlName="timezone">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let timezones of timezoneData.data" [value]="timezones.zone">
                  {{timezones.country_name}} {{timezones.zone}} ({{timezones.gmt_offset}})
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="28">
              <mat-label class="font-size-12"> Alternative Email</mat-label>
              <input matInput formControlName="email_sec">
              <mat-error *ngIf="addTranslatorForm.get('email_sec').hasError('email')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="boxBody">
        <div class="boxBodyInn">
          <h4>Notification Settings</h4>
          <mat-checkbox formControlName="sms_notification" [value]="1" (change)="smsNotification($event)">SMS
          </mat-checkbox>
          <mat-checkbox formControlName="wtsp_notification" [value]="1" (change)="wtspNotification($event)">WhatsApp
          </mat-checkbox>

          <h4>Translation Service</h4>
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Native Language</mat-label>
              <mat-select formControlName="native_language_id">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let language of languageData.data" [value]="language.id">
                  {{language.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <div class="text-center">
            <ngx-spinner>
              <img src="assets/images/preloader_logo.gif" width="150" />
              <p style="font-size: 20px; color: white; align-content: center;">Loading...</p>
            </ngx-spinner>
          </div> -->
          <h4>Language</h4>
          <mat-table [dataSource]="dataSource" class="tableGrey bdr1">
            <!-- Position Column -->
            <!-- Name Column -->
            <ng-container matColumnDef="langPair">
              <mat-header-cell *matHeaderCellDef> Language Pair </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination">
                {{lang_combination.sourcelanguage.data.lang_name }} {{lang_combination.bi_direction}}
                {{lang_combination.targetlanguage.data.lang_name}}
              </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="translationRate">
              <mat-header-cell *matHeaderCellDef> Translation Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.translation_rate}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="proofindRate">
              <mat-header-cell *matHeaderCellDef> Proofreading Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.proofreading_rate}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="dtpRate">
              <mat-header-cell *matHeaderCellDef> Dtp Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.dtp_rate}} </mat-cell>
            </ng-container>
            <!-- Symbol Column -->
            <ng-container matColumnDef="qaRate">
              <mat-header-cell *matHeaderCellDef> MTPE Rate (USD) </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.qa_rate}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="speed">
              <mat-header-cell *matHeaderCellDef> Speed* </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> {{lang_combination.speed}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accredited">
              <mat-header-cell *matHeaderCellDef>Accredited</mat-header-cell>
              <mat-cell *matCellDef="let lang_combination" style="display: block;"> 
                <p *ngIf='lang_combination.sworn=="1"'>Sworn</p>
                <!-- <p *ngIf='lang_combination.sworn=="0"'>Sworn: No</p> -->
                <p *ngIf='lang_combination.ata_certified=="1"'>ATA certified</p>
                <!-- <p *ngIf='lang_combination.ata_certified=="0"'>ATA certified: No</p> -->
                <p *ngIf='lang_combination.cttic=="1"'>CTTIC</p>
                <!-- <p *ngIf='lang_combination.cttic=="0"'>CTTIC: No</p> -->
                <p *ngIf='lang_combination.naati=="1"'>NAATI</p>
                <!-- <p *ngIf='lang_combination.naati=="0"'>NAATI: No</p> -->
                <p *ngIf='lang_combination.atia=="1"'>ATIA</p>
                <!-- <p *ngIf='lang_combination.atia=="0"'>ATIA: No</p> -->
                <p *ngIf='lang_combination.atio=="1"'>ATIO</p>
                <!-- <p *ngIf='lang_combination.atio=="0"'>ATIO: No</p> -->
                <p *ngIf='lang_combination.najit=="1"'>NAJIT</p>
                <!-- <p *ngIf='lang_combination.najit=="0"'>NAJIT: No</p> -->
                <p *ngIf='lang_combination.atc=="1"'>ATC</p>
                <!-- <p *ngIf='lang_combination.atc=="0"'>ATC: No</p> -->
                <p *ngIf='lang_combination.euatc=="1"'>EUATC</p>
                <!-- <p *ngIf='lang_combination.euatc=="0"'>EUATC: No</p> -->
                <p *ngIf='lang_combination.atn=="1"'>ATN</p>
                <!-- <p *ngIf='lang_combination.atn=="0"'>ATN: No</p> -->
              </mat-cell>
            </ng-container>
            <!-- <ng-container matColumnDef="ata_certified">
              <mat-header-cell *matHeaderCellDef>ATA certified</mat-header-cell>
              <mat-cell *matCellDef="let lang_combination"> 
                <p *ngIf='lang_combination.ata_certified=="1"'>Yes</p>
                <p *ngIf='lang_combination.ata_certified=="0"'>No</p>
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef><span *ngIf="type == 'Update'">Actions</span> </mat-header-cell>
              <mat-cell *matCellDef="let lang_combination; let i=index;" fxHide fxShow.gt-xs>
                <button mat-stroked-button class="bicon" (click)="editData(lang_combination)" *ngIf="type == 'Update'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>
                </button>
                <button mat-stroked-button class="bicon" (click)="editData(lang_combination, i)" *ngIf="type == 'Add'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>
                </button>
                <button mat-stroked-button class="bicon" (click)="deleteData(lang_combination,i)">
                  <mat-icon matTooltip="Click here to Delete">
                    delete
                  </mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let lang_combination; columns: displayedColumns;"></mat-row>
          </mat-table>
          <div class="input-row pt-20 pb-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center"
            fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Source Language*</mat-label>
              <mat-select [(ngModel)]="data.source_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getSourceName($event)">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let res of languageData.data" [value]="res.id">
                  {{res.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Target Language*</mat-label>
              <mat-select [(ngModel)]="data.target_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getTargetName($event)">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let language_res of languageData.data" [value]="language_res.id">
                  {{language_res.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Translation Rate* </mat-label>
              <input matInput type="number" [(ngModel)]="data.translation_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Proofreading Rate*</mat-label>
              <input matInput type="number" [(ngModel)]="data.proofreading_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="6">
              <mat-label class="font-size-12">Dtp Rate</mat-label>
              <input matInput type="number" [(ngModel)]="data.dtp_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="6">
              <mat-label class="font-size-12">MTPE Rate</mat-label>
              <input matInput type="number" [(ngModel)]="data.qa_rate" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Word</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="6">
              <mat-label class="font-size-12">Speed</mat-label>
              <input matInput type="number" [(ngModel)]="data.speed" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">Per Day</mat-hint>
            </mat-form-field>

            <div fxFlex="10" class="pt-16 text-center">
              <mat-checkbox [(ngModel)]="data.bi_direction" [value]="data.bi_direction"
                [ngModelOptions]="{standalone: true}" class="mt-8">Vice-versa</mat-checkbox>
            </div>

            <!-- <div fxFlex="10" class="pt-16 text-center">
              <mat-checkbox [(ngModel)]="data.sworn" [value]="data.sworn"
                [ngModelOptions]="{standalone: true}" class="mt-8">Sworn</mat-checkbox>
            </div> -->

            <div fxFlex="10" class="pt-16 text-center">
            <ng-multiselect-dropdown-angular7 [(ngModel)]="data.certificates" [data]="certificates" 
            [settings]="dropdownSettings" [ngModelOptions]="{standalone: true}">
            </ng-multiselect-dropdown-angular7>
              <!-- <mat-checkbox [(ngModel)]="data.ata_certified" [value]="data.ata_certified"
                [ngModelOptions]="{standalone: true}" class="mt-8">ATA certified</mat-checkbox> -->
            </div>

            <div fxFlex="5" class="pt-16">
              <button mat-flat-button color="accent" class="bicon" type="button" *ngIf="type == 'Add'"
                (click)="addLanguageTable()">
                <!-- <i class="material-icons">add</i> -->
                Save
              </button>
              <button mat-flat-button color="accent" type="button" class="bicon" *ngIf="type == 'Update'"
                (click)="addLanguageTableApi()">Save
                <!-- <i class="material-icons"> add</i>  -->
              </button>
            </div>
          </div>
          <!-- <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
              <mat-checkbox >Vice-versa</mat-checkbox>
            </div> -->
          <mat-divider></mat-divider>
          <h4>Avt</h4>

          <mat-table [dataSource]="dataSubtitlerSource" class="tableGrey bdr1">
            <!-- Position Column -->
            <!-- Name Column -->
            <ng-container matColumnDef="langPair">
              <mat-header-cell *matHeaderCellDef>Language Pair </mat-header-cell>
              <mat-cell *matCellDef="let subtitler_lang_combination">
                {{subtitler_lang_combination.sourcelanguage.data.lang_name }}
                {{subtitler_lang_combination.bi_direction}}
                {{subtitler_lang_combination.targetlanguage.data.lang_name}}
              </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="service">
              <mat-header-cell *matHeaderCellDef> Service </mat-header-cell>
              <mat-cell *matCellDef="let subtitler_lang_combination">
                {{subtitler_lang_combination.skill_service_name}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subtitlerRate">
              <mat-header-cell *matHeaderCellDef> Rate Per min(USD) </mat-header-cell>
              <mat-cell *matCellDef="let subtitler_lang_combination">
                {{subtitler_lang_combination.subtitle_rate}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="perWord">
              <mat-header-cell *matHeaderCellDef> Rate Per Word(USD) </mat-header-cell>
              <mat-cell *matCellDef="let subtitler_lang_combination"> {{subtitler_lang_combination.per_word}}</mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="speed">
              <mat-header-cell *matHeaderCellDef> Speed* </mat-header-cell>
              <mat-cell *matCellDef="let subtitler_lang_combination"> {{subtitler_lang_combination.speed}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef><span *ngIf="type == 'Update'">Actions</span> </mat-header-cell>
              <mat-cell *matCellDef="let subtitler_lang_combination; let i=index;" fxHide fxShow.gt-xs>
                <button mat-stroked-button class="bicon 11" (click)="editSubtitlerData(subtitler_lang_combination)"
                  *ngIf="type == 'Update'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>
                </button>
                <button mat-stroked-button class="bicon 22" (click)="editSubtitlerData(subtitler_lang_combination, i)"
                  *ngIf="type == 'Add'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>
                </button>
                <button mat-stroked-button class="bicon" (click)="deleteSubtitlerData(subtitler_lang_combination,i)">
                  <mat-icon matTooltip="Click here to Delete">
                    delete
                  </mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedSubtitlerColumns"></mat-header-row>
            <mat-row *matRowDef="let subtitler_lang_combination; columns: displayedSubtitlerColumns;"></mat-row>
          </mat-table>
          <div class="input-row pt-20 pb-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center"
            fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Source Language*</mat-label>
              <mat-select [(ngModel)]="dataSubtitler.source_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getSubtitlerSourceName($event)">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let res of languageData.data" [value]="res.id">
                  {{res.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Target Language*</mat-label>
              <mat-select [(ngModel)]="dataSubtitler.target_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getSubtitlerTargetName($event)">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let language_res of languageData.data" [value]="language_res.id">
                  {{language_res.lang_name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Service</mat-label>
              <mat-select [(ngModel)]="dataSubtitler.skill_service_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getSubtitlerSkillName($event)">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let skill_res of skillData.data" [value]="skill_res.id">
                  {{skill_res.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Rate* Per Minute </mat-label>
              <input matInput type="number" [(ngModel)]="dataSubtitler.subtitle_rate"
                [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per minute</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="10" *ngIf="show_per_word">
              <mat-label class="font-size-12">Rate Per Word</mat-label>
              <input matInput type="number" [(ngModel)]="dataSubtitler.per_word" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per word</mat-hint>
            </mat-form-field>

            <mat-form-field fxFlex="15">
              <mat-label class="font-size-12">Speed</mat-label>
              <input matInput type="number" [(ngModel)]="dataSubtitler.speed" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">Per Day</mat-hint>
            </mat-form-field>

            <div fxFlex="15" class="pt-16 text-center">
              <mat-checkbox [(ngModel)]="dataSubtitler.bi_direction" [value]="dataSubtitler.bi_direction"
                [ngModelOptions]="{standalone: true}" class="mt-8">Vice-versa</mat-checkbox>
            </div>
            <div fxFlex="5" class="pt-16">
              <button mat-flat-button color="accent" class="bicon 1" type="button" *ngIf="type == 'Add'"
                (click)="addSubtitlerLanguageTable()"> Save </button>
              <button mat-flat-button color="accent" type="button" class="bicon 2" *ngIf="type == 'Update'"
                (click)="addSubtitlerLanguageTableApi()">Save </button>
            </div>
          </div>

          <mat-divider></mat-divider>
          <h4>Terping</h4>
          <mat-table [dataSource]="dataTrepingSource" class="tableGrey bdr1">
            <!-- Position Column -->
            <!-- Name Column -->
            <ng-container matColumnDef="trepingLangPair">
              <mat-header-cell *matHeaderCellDef>Language Pair </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination">
                {{treping_lang_combination.sourcelanguage.data.lang_name}}
                {{treping_lang_combination.bi_direction}}
                {{treping_lang_combination.targetlanguage.data.lang_name}}
              </mat-cell>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="service">
              <mat-header-cell *matHeaderCellDef> Service </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination">
                {{treping_lang_combination.skill_service_name}}
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="subject">
              <mat-header-cell *matHeaderCellDef> Subject </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination">
                {{treping_lang_combination.subject}}
              </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="perHourRate">
              <mat-header-cell *matHeaderCellDef> Rate Per hours(USD) </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination">
                {{treping_lang_combination.rate_hour}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="perMinutesRate">
              <mat-header-cell *matHeaderCellDef> Rate Per Minutes(USD) </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination"> {{treping_lang_combination.rate_min}}</mat-cell>
            </ng-container>
          
            <!-- Symbol Column -->
            <!-- <ng-container matColumnDef="speed">
              <mat-header-cell *matHeaderCellDef> Speed* </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination"> {{treping_lang_combination.speed}} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="flexibleRate">
              <mat-header-cell *matHeaderCellDef> Flexible_rate </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination"> 
                <p *ngIf='treping_lang_combination.flexible_rate=="1"'>Yes</p> 
                <p *ngIf='treping_lang_combination.flexible_rate=="0"'>No</p>
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="qualification">
              <mat-header-cell *matHeaderCellDef> Qualification </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination">
                {{treping_lang_combination.qualification}}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef><span *ngIf="type == 'Update'">Actions</span> </mat-header-cell>
              <mat-cell *matCellDef="let treping_lang_combination; let i=index;" fxHide fxShow.gt-xs>
                <button mat-stroked-button class="bicon 11" (click)="editTrepingData(treping_lang_combination)" *ngIf="type == 'Update'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>  
                </button>
                <button mat-stroked-button class="bicon 22" (click)="editTrepingData(treping_lang_combination, i)"  *ngIf="type == 'Add'">
                  <mat-icon matTooltip="Click here to Edit">
                    edit
                  </mat-icon>
                </button>
                <button mat-stroked-button class="bicon" (click)="deleteTrepingData(treping_lang_combination,i)">
                  <mat-icon matTooltip="Click here to Delete">
                    delete
                  </mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedTrepingColumns"></mat-header-row>
            <mat-row *matRowDef="let treping_lang_combination; columns: displayedTrepingColumns;"></mat-row>
          </mat-table>

          <div class="input-row pt-20 pb-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center" fxLayoutGap="10px"
            fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Source Language*</mat-label>
              <mat-select [(ngModel)]="dataTreping.source_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getTrepingSourceName($event)">
                <mat-option [value]="''">
                  Select
                </mat-option>
                <mat-option *ngFor="let res of languageData.data" [value]="res.id">
                  {{res.lang_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Target Language*</mat-label>
              <mat-select [(ngModel)]="dataTreping.target_lang_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getTrepingTargetName($event)">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let language_res of languageData.data" [value]="language_res.id">
                  {{language_res.lang_name}}</mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Service</mat-label>
              <mat-select [(ngModel)]="dataTreping.skill_service_id" [ngModelOptions]="{standalone: true}"
                (selectionChange)="getTrepingSkillName($event)">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let skill_res of skillTrepingData.data" [value]="skill_res.id">
                  {{skill_res.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          

            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Subject</mat-label>
              <mat-select [(ngModel)]="dataTreping.subject" [ngModelOptions]="{standalone: true}">
                <mat-option [value]="''">Select</mat-option>
                <mat-option [value]="'Legal'">Legal</mat-option>
                <mat-option [value]="'Medical'">Medical</mat-option>
                <mat-option [value]="'General'">General</mat-option>
                <mat-option [value]="'Social'">Social</mat-option>
                <mat-option [value]="'Other'">Other</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Rate* Per Hour </mat-label>
              <input matInput type="number" [(ngModel)]="dataTreping.rate_hour" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per Hour</mat-hint>
            </mat-form-field>
          
            <mat-form-field fxFlex="10" >
              <mat-label class="font-size-12">Rate Per minute</mat-label>
              <input matInput type="number" [(ngModel)]="dataTreping.rate_min" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">USD Per minute</mat-hint>
            </mat-form-field>
          
            <!-- <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Speed</mat-label>
              <input matInput type="number" [(ngModel)]="dataTreping.speed" [ngModelOptions]="{standalone: true}">
              <mat-hint class="mt-16">Per Day</mat-hint>
            </mat-form-field> -->
          
            <div fxFlex="15" class="pt-16 text-center">
              <mat-checkbox [(ngModel)]="dataTreping.flexible_rate" [value]="dataTreping.flexible_rate"
                [ngModelOptions]="{standalone: true}" class="mt-8">Flexible rate</mat-checkbox>
            </div>

            <div fxFlex="15" class="pt-16 text-center">
              <mat-checkbox [(ngModel)]="dataTreping.bi_direction" [value]="dataTreping.bi_direction"
                [ngModelOptions]="{standalone: true}" class="mt-8">Vice-versa</mat-checkbox>
            </div>
            
            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Qualification</mat-label>
              <mat-select [(ngModel)]="dataTreping.qualification" [ngModelOptions]="{standalone: true}">
                <mat-option [value]="''">Select</mat-option>
                <mat-option [value]="'Court Registered'">Court Registered</mat-option>
                <mat-option [value]="'Court Certified'">Court Certified</mat-option>
                <mat-option [value]="'Medical'">Medical</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlex="5" class="pt-16">
              <button mat-flat-button color="accent" class="bicon 1" type="button" *ngIf="type == 'Add'"
                (click)="addTrepingLanguageTable()"> Save </button>
              <button mat-flat-button color="accent" type="button" class="bicon 2" *ngIf="type == 'Update'"
                (click)="addTrepingLanguageTableApi()">Save </button>
            </div>
          </div>

          <mat-divider></mat-divider>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="100px"
            fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Avt Status</mat-label>
              <mat-select placeholder="Select avt status" formControlName="avt_status">
                <mat-option value="">Any</mat-option>
                <mat-option value="Leader">Leader</mat-option>
                <mat-option value="Probation">Probation</mat-option>
                <mat-option value="Junior"> Junior</mat-option>
                <mat-option value="Closed">Closed</mat-option>
                <mat-option value="Blacklisted"> Blacklisted</mat-option>
                <mat-option value="Unavailable">Unavailable</mat-option>
                <mat-option value="Temp">Temp</mat-option>
              </mat-select>
              <mat-error *ngIf="addTranslatorForm.get('avt_status').hasError('required')">
                Avt Status is required!
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Terping Status</mat-label>
              <mat-select placeholder="Select treping status" formControlName="treping_status">
                <mat-option value="">Any</mat-option>
                <mat-option value="Leader">Leader</mat-option>
                <mat-option value="Screen">Screen</mat-option>
                <mat-option value="Unscreened">Unscreened</mat-option>
                <mat-option value="Probation">Probation</mat-option>
                <mat-option value="Blacklisted"> Blacklisted</mat-option>
                <mat-option value="Unavailable">Unavailable</mat-option>
                <mat-option value="Closed">Closed</mat-option>
              </mat-select>
              <mat-error *ngIf="addTranslatorForm.get('treping_status').hasError('required')">
                Treping Status is required!
              </mat-error>
            </mat-form-field>
          </div>
          
          <mat-divider></mat-divider>
          <!-- <p><b>Speciality</b></p>
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <mat-form-field   fxFlex="40">
              <mat-label>Speciality Fields</mat-label>
              <mat-select multiple formControlName="specialities">
                <mat-option *ngFor="let speciality of specialityData" [value]="speciality.id">
                  {{speciality.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <div fxFlex="28">
              <p class="dLabel">Speciality</p>
              <ng-multiselect-dropdown-angular7 [data]="dropdownList" formControlName="specialities"
                [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
              </ng-multiselect-dropdown-angular7>
            </div>
          </div>

          <h4><b> Skills:</b></h4>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <section class="section">
              <mat-checkbox class="section-margin" [value]="1" [checked]="selected_skills.includes(1)"
                (change)="Skills($event)">Translation</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="2" [checked]="selected_skills.includes(2)"
                (change)="Skills($event)">Proofreading</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="3" [checked]="selected_skills.includes(3)"
                (change)="Skills($event)">DTP</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="4" [checked]="selected_skills.includes(4)"
                (change)="Skills($event)">QA</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="5" [checked]="selected_skills.includes(5)"
                (change)="Skills($event)">Translation Mailing</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="6" [checked]="selected_skills.includes(6)"
                (change)="Skills($event)">Notarization</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="7" [checked]="selected_skills.includes(7)"
                (change)="Skills($event)">Apostile</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="8" [checked]="selected_skills.includes(8)"
                (change)="Skills($event)">Subtitling</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="9" [checked]="selected_skills.includes(9)"
                (change)="Skills($event)">Localization </mat-checkbox>
              <mat-checkbox class="section-margin" [value]="10" [checked]="selected_skills.includes(10)"
                (change)="Skills($event)">Gaming</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="11" [checked]="selected_skills.includes(11)"
                (change)="Skills($event)">Transcription</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="12" [checked]="selected_skills.includes(12)"
                (change)="Skills($event)">Subtitling Embedding and Hardcoding</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="13" [checked]="selected_skills.includes(13)"
                (change)="Skills($event)">Voice Over</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="78" [checked]="selected_skills.includes(78)"
                (change)="Skills($event)">Terping</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="94" [checked]="selected_skills.includes(94)"
                (change)="Skills($event)">Interpreting</mat-checkbox>
              <!-- <mat-checkbox class="section-margin" [value]="14" [checked]="selected_skills.includes(14)"
                  (change)="Skills($event)">Engineering</mat-checkbox> -->
              <!-- <mat-checkbox class="section-margin" [value]="91" [checked]="selected_skills.includes(91)"
                  (change)="Skills($event)">Court</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="92" [checked]="selected_skills.includes(92)"
                    (change)="Skills($event)">Medical</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="93" [checked]="selected_skills.includes(93)"
                      (change)="Skills($event)">Legal</mat-checkbox>
              <mat-checkbox class="section-margin" [value]="94" [checked]="selected_skills.includes(94)"
                        (change)="Skills($event)">Immigration </mat-checkbox> -->
            </section>
          </div>
        </div>
      </div>


      <div class="boxBody">
        <div class="boxBodyInn">
          <h4>Other Information</h4>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <div fxFlex="40"><b>Payment method (We prefer PayPal) :</b> </div>
            <div fxFlex="60">
              <mat-radio-group formControlName="payment_method">
                <mat-radio-button value="PayPal">PayPal</mat-radio-button>
                <mat-radio-button value="Payoneer">Payoneer</mat-radio-button>
                <mat-radio-button value="Skrill">Skrill</mat-radio-button>
                <mat-radio-button value="Other">Other</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px" *ngIf="addTranslatorForm.get('payment_method').value=='Other'">
            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Other Payment Method</mat-label>
              <input matInput formControlName="other_payment_method">
            </mat-form-field>
          </div>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="40">
              <mat-label class="font-size-12">Payment Method Email</mat-label>
              <input matInput formControlName="payment_method_id">
              <mat-error *ngIf="addTranslatorForm.get('payment_method_id').hasError('email')"> Please enter a valid
                email address</mat-error>
            </mat-form-field>
          </div>

          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <div fxFlex="40"><b>Do you use CAT (Computer Assisted Translation) tools? :</b> </div>
            <div fxFlex="60">
              <mat-radio-group formControlName="translation_tools">
                <mat-radio-button value="1">No</mat-radio-button>
                <mat-radio-button value="2">Yes</mat-radio-button>
                <mat-radio-button value="3">Yes, but I will not use them unless requested.</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>


          <h4><b> Select tools that you use:</b></h4>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <section class="section">
              <mat-checkbox class="section-margin" value="WordFast" [checked]="selected_tools.includes('WordFast')"
                (change)="tools($event)">WordFast</mat-checkbox>
              <mat-checkbox class="section-margin" value="Trados" [checked]="selected_tools.includes('Trados')"
                (change)="tools($event)">Trados</mat-checkbox>
              <mat-checkbox class="section-margin" value="MemoQ" [checked]="selected_tools.includes('MemoQ')"
                (change)="tools($event)">MemoQ</mat-checkbox>
              <mat-checkbox class="section-margin" value="Phraseapp" [checked]="selected_tools.includes('Phraseapp')"
                (change)="tools($event)">Phraseapp</mat-checkbox>
              <mat-checkbox class="section-margin" value="Transifex" [checked]="selected_tools.includes('Transifex')"
                (change)="tools($event)">Transifex</mat-checkbox>
              <mat-checkbox class="section-margin" value="SmartCat" [checked]="selected_tools.includes('SmartCat')"
                (change)="tools($event)">SmartCat</mat-checkbox>
              <mat-checkbox class="section-margin" value="Other" [checked]="selected_tools.includes('Other')"
                (change)="tools($event)">Other</mat-checkbox>
            </section>
          </div>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
            <div>
              <b> Boostlingo Affiliation :</b>
            </div>
            <div>
              <mat-radio-group formControlName="boostlingo_affiliation">
                <mat-radio-button value="0">No</mat-radio-button>
                <mat-radio-button value="1">Yes</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
            <div>
              <b> Valid license :</b>
            </div>
            <div>
              <mat-radio-group formControlName="tool_licence">
                <mat-radio-button value="0">No</mat-radio-button>
                <mat-radio-button value="1">Yes</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </div>


      <div class="boxBody">
        <div class="boxBodyInn">
          <h4> Availability and Notes </h4>
          <p><b>Days Available</b></p>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <section class="section">
              <mat-checkbox class="section-margin" value="Sunday" [checked]="selected_days.includes('Sunday')"
                (change)="Available_days($event)">Sunday</mat-checkbox>
              <mat-checkbox class="section-margin" value="Monday" [checked]="selected_days.includes('Monday')"
                (change)="Available_days($event)">Monday</mat-checkbox>
              <mat-checkbox class="section-margin" value="Tuesday" [checked]="selected_days.includes('Tuesday')"
                (change)="Available_days($event)">Tuesday</mat-checkbox>
              <mat-checkbox class="section-margin" value="Wednesday" [checked]="selected_days.includes('Wednesday')"
                (change)="Available_days($event)">Wednesday</mat-checkbox>
              <mat-checkbox class="section-margin" value="Thursday" [checked]="selected_days.includes('Thursday')"
                (change)="Available_days($event)">Thursday</mat-checkbox>
              <mat-checkbox class="section-margin" value="Friday" [checked]="selected_days.includes('Friday')"
                (change)="Available_days($event)">Friday</mat-checkbox>
              <mat-checkbox class="section-margin" value="Saturday" [checked]="selected_days.includes('Saturday')"
                (change)="Available_days($event)">Saturday</mat-checkbox>
            </section>
          </div>

          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12"> Voice Sample</mat-label>
              <textarea matInput formControlName="voice_sample" class="h150">
              </textarea>
              <mat-hint>0/500 words</mat-hint>
            </mat-form-field>
          </div>

          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12"> Education & Certifications</mat-label>
              <textarea matInput formControlName="education" class="h150">
              </textarea>
              <mat-hint>0/500 words</mat-hint>
            </mat-form-field>
          </div>


          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12"> Detailed Cover Letter </mat-label>
              <textarea matInput formControlName="cover_letter" class="h150"></textarea>
              <mat-hint>0/500 words</mat-hint>
            </mat-form-field>
          </div>

          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12">Important Notes </mat-label>
              <textarea matInput formControlName="important_note" class="h150"></textarea>
            </mat-form-field>
          </div>

          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12">PR Scores </mat-label>
              <textarea matInput formControlName="pr_scores" class="h150"></textarea>
            </mat-form-field>
          </div>

          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="100px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="50">
              <mat-label class="font-size-12">Minimum Fee</mat-label>
              <input matInput formControlName="info">
            </mat-form-field>

            <mat-form-field fxFlex="50">
              <mat-label class="font-size-12">Program/Software used for subtitles</mat-label>
              <input matInput formControlName="subtitles_program_software">
            </mat-form-field>
          </div>

          <div class="box-blank" *ngIf="uploaded_files_array.length>0">
            <p *ngFor="let res of uploaded_files_array">
              <span *ngFor="let res1 of res">{{res1.name}}</span>
            </p>
          </div>
          <div class="box-blank" *ngIf="uploaded_files_array.length == 0 && vendor_resume_name!=null">
            <p *ngIf="vendor_resume_name!=null">{{vendor_resume_name}} <a [href]="vendor_resume_data" target="_blank"
                download>
                <mat-icon>vertical_align_bottom</mat-icon>
              </a></p>
          </div>

          <div class="input-row mat-form-field-wrapper">

            <button mat-flat-button color="primary" type="button" (click)="onFileUpload()">Upload Resume</button>
            <input type="file" id="docUpload" name="docUpload" style="display:none;" />
            <div>
              <mat-error>{{fileError}}</mat-error>
              <span style="color: green;">{{fileSuccess}}</span>
            </div>
          </div>


          <!-- <div class="input-row mat-form-field-wrapper">
            <section class="section">
              <mat-checkbox  formControlName="privacy" class="section-margin" (change)="privacy_test($event)">
                Yes, I understand and agree to the Daytranslations Terms of Service, including the User Agreement and
                Privacy Policy.
              </mat-checkbox>
            </section>
          </div> -->
          <!-- coa start-->
          <div class="box-blank" *ngIf="uploaded_coa_array.length == 0 && coa_array.length>0">
            <span *ngFor="let resCoa of coa_array">
              <p>{{resCoa.name}} <mat-icon matSuffix style="cursor: pointer;"
                  class="secondary-buttons-card font-size-18" (click)="deleteCoaEdit(resCoa)">delete</mat-icon>
              </p>
            </span>
          </div>
          <div class="box-blank" *ngIf="uploaded_coa_array.length>0">
            <!-- <p *ngFor="let res of uploaded_coa_array"> -->
            <span *ngFor="let resCoa of uploaded_coa_array">
              <p>{{resCoa.name}} <mat-icon matSuffix style="cursor: pointer;"
                  class="secondary-buttons-card font-size-18" (click)="deleteCoa(resCoa.id)">delete</mat-icon>
              </p>
            </span>
            <!-- </p>  -->
          </div>
          <div class="input-row mat-form-field-wrapper">

            <button mat-flat-button color="primary" type="button" (click)="onCoaUpload()">Upload COA</button>
            <input type="file" id="coaUpload" name="coaUpload" style="display:none;" multiple />
            <div>
              <mat-error>{{fileError1}}</mat-error>
              <span style="color: green;" *ngIf="uploaded_coa_array.length>0">{{fileSuccess1}}</span>
            </div>
          </div>

          <!-- coa end -->

          <!-- extra document start-->
          <div class="box-blank" *ngIf="uploaded_extra_files_array.length > 0">
            <p *ngFor="let res of uploaded_extra_files_array">
              <span *ngFor="let res1 of res">{{res1.name}}<mat-icon matSuffix style="cursor: pointer;"
                  class="secondary-buttons-card font-size-18" (click)="deleteExtraEdit(res1)">delete</mat-icon></span>
            </p>
          </div>
          <div class="box-blank" *ngIf="uploaded_extra_files_array.length == 0 && vendor_extra_document_name!=null">
            <p *ngIf="vendor_extra_document_name!=null">{{vendor_extra_document_name}}
              <!-- <a [href]="vendor_extra_document_data" target="_blank" download><mat-icon>vertical_align_bottom</mat-icon>
              </a> -->
              <mat-icon matSuffix style="cursor: pointer;" class="secondary-buttons-card font-size-18"
                (click)="deleteExtra(vendor_extra_document_id)">
                delete</mat-icon>
            </p>
          </div>
          <div class="input-row mat-form-field-wrapper">
            <button mat-flat-button color="primary" type="button" (click)="onExtraFileUpload()">Extra Document</button>
            <input type="file" id="extraDocUpload" name="extraDocUpload" style="display:none;" />
            <div>
              <mat-error>{{fileError}}</mat-error>
              <span style="color: green;">{{extraFileSuccess}}</span>
            </div>
          </div>

          <!-- extra document end-->

          <!-- contract document start-->
          <div class="box-blank" *ngIf="uploaded_contract_files_array.length>0">
            <p *ngFor="let res of uploaded_contract_files_array">
              <span *ngFor="let res1 of res">{{res1.name}}</span>
            </p>
          </div>
          <div class="box-blank"
            *ngIf="uploaded_contract_files_array.length == 0 && vendor_contract_document_name!=null">
            <p *ngIf="vendor_contract_document_name!=null">{{vendor_contract_document_name}} <a
                [href]="vendor_contract_document_data" target="_blank" download>
                <mat-icon>vertical_align_bottom</mat-icon>
              </a></p>
          </div>
          <div class="input-row mat-form-field-wrapper">
            <button mat-flat-button color="primary" type="button" (click)="onContractFileUpload()">Contract</button>
            <input type="file" id="contractUpload" name="contractUpload" style="display:none;" />
            <div>
              <mat-error>{{fileError}}</mat-error>
              <span style="color: green;">{{contractFileSuccess}}</span>
            </div>
          </div>


          <!-- contract document end-->
          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12">VO sample</mat-label>
              <input matInput formControlName="vo_sample" placeholder="Please enter a link here">
            </mat-form-field>
          </div>
          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12">Voiceover Category</mat-label>
              <input matInput class="h100" formControlName="voiceover_category" placeholder="">
            </mat-form-field>
          </div>
          <div class="input-row mat-form-field-wrapper">
            <mat-form-field fxFlex="100">
              <mat-label class="font-size-12">Voiceover Notes</mat-label>
              <input matInput class="h100" formControlName="voiceover_notes" placeholder="">
            </mat-form-field>
          </div>
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Untimed VO Recording Rate</mat-label>
              <input type="number" matInput formControlName="untimed_vo_recording">
            </mat-form-field>
            <mat-form-field fxFlex="10">
              <mat-label class="font-size-12">Timed VO Recording Rate</mat-label>
              <input type="number" matInput formControlName="timed_vo_recording">
            </mat-form-field>
          </div>

        <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="100px"
          fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex="20">
            <mat-label class="font-size-12">Minimum time</mat-label>
            <input type="number" matInput formControlName="minimum_time">
          </mat-form-field>

          <mat-form-field fxFlex="20">
            <mat-label class="font-size-12">Travel cost</mat-label>
            <input type="number" matInput formControlName="travel_cost">
          </mat-form-field>

          <mat-form-field fxFlex="20">
            <mat-label class="font-size-12">Distance willing to travel</mat-label>
            <input type="number" matInput formControlName="distance_willing_to_travel">
          </mat-form-field>

          <mat-form-field fxFlex="20">
            <mat-label class="font-size-12">Cancellation cost</mat-label>
            <input type="text" matInput formControlName="cancellation_cost">
          </mat-form-field>

        </div>


          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="20">
              <mat-label class="font-size-12">Category Level</mat-label>
              <mat-select placeholder="Select category level" formControlName="category_level">
                <mat-option value="">Any</mat-option>
                <mat-option value="Leader">Leader</mat-option>
                <mat-option value="Probation">Probation</mat-option>
                <mat-option value="Junior"> Junior</mat-option>
                <mat-option value="Closed">Closed</mat-option>
                <mat-option value="Blacklisted"> Blacklisted</mat-option>
                <mat-option value="Unavailable">Unavailable</mat-option>
                <mat-option value="Temp">Temp</mat-option>
              </mat-select>
              <mat-error *ngIf="addTranslatorForm.get('category_level').hasError('required')">
                Category level is required!
              </mat-error>
            </mat-form-field>

            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start" fxLayoutGap="10px">
              <h4>Status</h4>
              <mat-radio-group fxFlex="100" fxFlex.gt-sm="100" class="mt-16" formControlName="is_active">
                <mat-radio-button value="1" class="pr-20">Active</mat-radio-button>
                <mat-radio-button value="0" class="pr-20">Inactive </mat-radio-button>
              </mat-radio-group>
            </div>

          </div>
          <!-- Vendor Doc start -->
          <div class="input-row mat-form-field-wrapper" *ngIf="id!=null" fxLayout="row" fxLayout.lt-md="column"
            fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
            <div class="white-box" style="width: 100%;">
              <div class="tHeader wMenu">
                <div class="container" fxLayout="row" fxLayoutAlign="left center" style="position: relative;"
                  fxLayoutGap="10px">
                  <div fxFlex="30">
                    <h2>Translator Doc</h2>
                  </div>
                  <div fxFlex="70" class="text-right">
                    <button class="mr-4" mat-stroked-button (click)="onCreate()"><i class="material-icons"> cloud_upload
                      </i> Add Files </button>
                  </div>
                </div>
              </div>

              <div class="boxBody">
                <div class="boxBodyInn" *ngIf="file_data?.length>0">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Last Modified</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let file_res of file_data">
                        <td>
                          <a [href]="BaseUrl_file + file_res?.vendorfile?.path" target="_blank"
                            [download]="BaseUrl_file + file_res?.vendorfile?.path">
                            {{file_res?.vendorfile.name}}</a>

                        </td>
                        <td>
                          <p *ngIf="file_res?.type=='1'">Certificate</p>
                          <p *ngIf="file_res?.type=='2'">Contract</p>
                          <p *ngIf="file_res?.type=='3'">Identity Document</p>
                          <p *ngIf="file_res?.type=='4'">COA's</p>
                          <p *ngIf="file_res?.type=='5'">Other</p>
                        </td>
                        <td>{{file_res?.comment}}</td>
                        <td>{{file_res?.created_at | date: 'yyyy-MM-dd'}} {{file_res?.created_at | date:"HH:mm"}}</td>
                        <td> <button mat-icon-button class="remove-color-button secondary-text" matTooltip="Delete"
                            (click)="removeVendorDocFile(file_res)">
                            <mat-icon class="s-20">delete</mat-icon>
                          </button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="noDataFound" *ngIf="file_data?.length==0"> No data available!!
                </div>
              </div>

            </div>

          </div>

          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <h4> Send Email to Blacklisted </h4>
            <mat-radio-group fxFlex="80" fxFlex.gt-sm="80" class="mt-16" formControlName="send_email_status">
              <mat-radio-button value="1" class="pr-20">Yes</mat-radio-button>
              <mat-radio-button value="0" class="pr-20">No </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- Vendor Doc End-->
          <div class="input-row mat-form-field-wrapper" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px">
            <h4> Onboarding </h4>
            <mat-form-field>
              <input matInput (click)="dp1.open()" [matDatepicker]="dp1" formControlName="on_boarding_date">
              <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Vendor Doc End-->
          

        </div>
      </div>
      <div class="actWraper">
        <button mat-flat-button color="accent" type="button" (click)="submit()" [disabled]="addTranslatorForm.invalid"
          class="mr-8">
          <mat-label class="Text_Color">{{type}}</mat-label>
        </button>
        <button mat-flat-button (click)="Reset()">Cancel</button>
      </div>
    </form>
  </div>
</div>