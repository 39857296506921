import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from '../../shared.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BulkEmailTranslatorComponent } from './bulk-email-translator/bulk-email-translator.component';
@Component({
  selector: 'app-translator',
  templateUrl: './translator.component.html',
  styleUrls: ['./translator.component.scss']
})
export class TranslatorComponent implements OnInit {
  dataSource: any;
  paginate: any = {};
  pageIndex: number = 1;
  query_approve: string = '';
  filterSpecialization: string = '';
  notArchive: string = 'Unavailable,Blacklisted,Closed';
  filter_src_lang: string = '';
  filter_target_lang: string = '';
  fQuery: string = '';
  ids_progress: any = [];
  all_freelancer_id: any = [];
  bulk_selected_progress: boolean = false;
  dataSource1: any;
  send_email_progress_data: any = {};
  emailTemplateData: string = '';
  login_enable: Boolean = true;
  specialityData: any = [];
  query_user_sort: string = '';
  languageData: any = [];
  pageSize: number = 10;
  filter_native_lang: number = null;
  fdata: any =
    {
      specialization: '',
      Source: '',
      Target: '',
      Native: ''
    }
  ApprovaldisplayedColumns = ['ref', 'first_name', 'native_language', 'category_level', 'language', 'Email', 'status'];
  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatSort)
  sort2: MatSort;
  @ViewChild(MatSort)
  sort3: MatSort;

  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;

  constructor(private _fuseNavigationService: FuseNavigationService,
    private vendorService: VendorService, private projectsService: ProjectsService,
    private toastr: ToastrService, private router: Router, private dialog: MatDialog) {


  }

  ngOnInit() {
    this.getFreelancerEmailTemplate();
    this.getLanguage();
    this.getSpeciality();
    this.getTranslators();
    this.getAllVendorIds();
  }
  applyFilterApproved(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res) => {
        if (res) {
          this.languageData = res;
        }
      });
  }

  getAllVendorIds() {
    let query = '?page=1&per_page=1000&not_archive=' + this.notArchive;
    this.vendorService.getAllTranslator(query).subscribe((res: any) => {
        res.data.forEach(elem => {
          this.all_freelancer_id.push(elem.id);
        });
        
      });
  }


  getSpeciality() {
    this.vendorService.getSpeciality()
      .subscribe((res: any) => {
        if (res) {
          res.forEach(element => {
            element.name = element.name.charAt(0).toUpperCase() + element.name.slice(1);;
          });
          this.specialityData = res;
        }
      });
  }

  getTranslators() {
    let query = '';
    let t_specialization = localStorage.getItem('t_specialization');
    let specializationFilter = JSON.parse(t_specialization);
    if (specializationFilter !== '' || specializationFilter !== null) {
      this.fdata.specialization = specializationFilter;
    }
    let t_source_lang = localStorage.getItem('t_source_lang');
    let source_langFilter = JSON.parse(t_source_lang);
    if (source_langFilter !== '' || source_langFilter !== null) {
      this.fdata.Source = source_langFilter;
    }

    let t_target_lang = localStorage.getItem('t_target_lang');
    let target_langFilter = JSON.parse(t_target_lang);
    if (target_langFilter !== '' || target_langFilter !== null) {
      this.fdata.Target = target_langFilter;
    }

    let t_native_filter = localStorage.getItem('t_native_filter');
    let nativeFilter = JSON.parse(t_native_filter);
    if (nativeFilter !== '' || nativeFilter !== null) {
      this.fdata.Native = nativeFilter;
    }


    let translatorFilter = localStorage.getItem('filter');
    let filterObject = JSON.parse(translatorFilter);
    let translatorPagination = localStorage.getItem('pagination');
    let translatorObject = JSON.parse(translatorPagination);
    if (translatorFilter != null && filterObject.filter) {
      query = filterObject.filter;
    } else if (translatorPagination !== null && translatorObject.table_name == 'translatorPagination') {
      query = '?page=' + translatorObject.page + '&per_page=' + translatorObject.per_page + '&not_archive=' + this.notArchive;
    } else {
      query = '?page=1&per_page=200&not_archive=' + this.notArchive;

    }
    this.vendorService.getAllTranslator(query)
      .subscribe((response) => {
        if (response) {
          this.dataSource1 = response;
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -2);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value1 = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }
              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }

              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }

              lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1);

            }
            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
        }
      });
  }

  //sorting

  sortUser(evt) {
    if (evt.direction == '') {
      evt.direction = 'asc';
    }

    this.query_user_sort = '?page=' + this.pageIndex + '&per_page=' + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction + '&not_archive=' + this.notArchive;
    this.vendorService.getAllTranslator(this.query_user_sort)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -2);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value1 = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }

              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }

              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }

              lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)

            }

            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
        }
      });
  }


  paginateData(event) {
    let aQuery = '';
    this.pageIndex = event.pageIndex + 1;
    if (this.pageIndex < 0) {
      this.pageIndex = 1;
    }
    this.pageSize = event.pageSize;
    this.query_approve = '?page=' + this.pageIndex + '&per_page=' + this.pageSize + '&not_archive=' + this.notArchive;
    var pagination = { table_name: 'translatorPagination', page: this.pageIndex, per_page: this.pageSize };
    SharedService.set(SharedService.pagination, JSON.stringify(pagination));
    if (this.filterSpecialization !== '') {
      aQuery += '&specialization=' + this.filterSpecialization;
      var Specialization = this.filterSpecialization;
      SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
    } else if (this.fdata.specialization) {
      aQuery += '&specialization=' + this.fdata.specialization;

    }

    if (this.filter_src_lang !== '') {
      aQuery += '&source_lang=' + this.filter_src_lang;
      var filter_src_lang = this.filter_src_lang;
      SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
    } else if (this.fdata.Source) {
      aQuery += '&source_lang=' + this.fdata.Source;

    }

    if (this.filter_target_lang !== '') {
      aQuery += '&target_lang=' + this.filter_target_lang;
      var filter_target_lang = this.filter_target_lang;
      SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
    } else if (this.fdata.Target) {
      aQuery += '&target_lang=' + this.fdata.Target;

    }

    if (this.filter_native_lang !== null) {
      aQuery += '&native_lang=' + this.filter_native_lang;
      var filter_native_lang = this.filter_native_lang;
      SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
    } else if (this.fdata.Native) {
      aQuery += '&native_lang=' + this.fdata.Native;

    }
    this.query_approve += aQuery;
    var Filter = { table_name: 'translatorFilter', filter: this.query_approve };
    SharedService.set(SharedService.Filter, JSON.stringify(Filter));
    this.vendorService.getAllTranslator(this.query_approve)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }
            });
            elem.specs = specs.slice(0, -2);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value1 = '';
            elem.vendortranslationlangs.data.forEach(res => {
              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }
              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }

              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }

              lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1)
            }

            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
        }
      });
  }

  editData(value) {
    this.router.navigate(['/hr/translator/' + value.id]);

  }

  onFilter(evt, type) {
    this.fQuery = '?page=1&per_page=10'
    if ((type == 'speciality' && evt.value != '' && evt.value !== undefined)) {
      this.filterSpecialization = evt.value;

      this.fQuery += '&specialization=' + this.filterSpecialization;
      var Specialization = this.filterSpecialization;
      SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
    }
    else if (type == 'speciality' && evt.value == '') {
      this.filterSpecialization = evt.value;
      var Specialization = this.filterSpecialization;
      SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
    }
    else if (this.filterSpecialization !== '') {
      this.fQuery += '&specialization=' + this.filterSpecialization;
      var Specialization = this.filterSpecialization;
      SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
    } else if (this.fdata.specialization) {
      this.fQuery += '&specialization=' + this.fdata.specialization;

    }


    if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
      this.filter_src_lang = evt.value;
      this.fQuery += '&source_lang=' + this.filter_src_lang;
      var filter_src_lang = this.filter_src_lang;
      SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
    }
    else if (type == 's_lang' && evt.value == '') {
      this.filter_src_lang = evt.value;
      var filter_src_lang = this.filter_src_lang;
      SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
    }
    else if (this.filter_src_lang !== '') {
      this.fQuery += '&source_lang=' + this.filter_src_lang;
      var filter_src_lang = this.filter_src_lang;
      SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
    } else if (this.fdata.Source) {
      this.fQuery += '&source_lang=' + this.fdata.Source;

    }

    if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
      this.filter_target_lang = evt.value;
      this.fQuery += '&target_lang=' + this.filter_target_lang;
      var filter_target_lang = this.filter_target_lang;
      SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
    }
    else if (type == 't_lang' && evt.value == '') {
      this.filter_target_lang = evt.value;
      var filter_target_lang = this.filter_target_lang;
      SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
    }
    else if (this.filter_target_lang !== '') {
      this.fQuery += '&target_lang=' + this.filter_target_lang;
      var filter_target_lang = this.filter_target_lang;
      SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
    } else if (this.fdata.Target) {
      this.fQuery += '&target_lang=' + this.fdata.Target;

    }

    if ((type == 'native_lang' && evt.value != null && evt.value !== undefined)) {
      this.filter_native_lang = evt.value;
      this.fQuery += '&native_lang=' + this.filter_native_lang;
      var filter_native_lang = this.filter_native_lang;
      SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
    }
    else if (type == 'native_lang' && evt.value == null) {
      this.filter_native_lang = evt.value;
      var filter_native_lang = this.filter_native_lang;
      SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
    }
    else if (this.filter_native_lang != null) {
      this.fQuery += '&native_lang=' + this.filter_native_lang;
      var filter_native_lang = this.filter_native_lang;
      SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
    } else if (this.fdata.Native) {
      this.fQuery += '&native_lang=' + this.fdata.Native;

    }

    var Filter = { table_name: 'translatorFilter', filter: this.fQuery };
    SharedService.set(SharedService.Filter, JSON.stringify(Filter));
    let query = this.fQuery;
    this.vendorService.getAllTranslator(query)
      .subscribe((response) => {
        if (response) {
          response.data.forEach(elem => {
            let specs = '';
            elem.vendorspecialities.data.forEach(res => {
              if (res.specialities != null) {
                specs += res.specialities.data.name + ', ';
              }

            });
            elem.specs = specs.slice(0, -1);
          });
          response.data.forEach(elem => {
            let lang = '';
            let lang_array = [];
            let translation_rate_value1 = '';
            elem.vendortranslationlangs.data.forEach(res => {

              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }

              if (res.bi_direction == 2) {
                res.bi_direction = '<>';
              } else {
                res.bi_direction = '>';
              }

              if (res.translation_rate != null) {
                translation_rate_value1 += res.translation_rate;
              }

              lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
              lang_array.push(lang);
            });
            if (lang_array.length > 0) {
              let test;
              test = lang_array[lang_array.length - 1];
              lang_array[lang_array.length - 1] = test.slice(0, -1);
            }
            elem.lang_array = lang_array;
            response.data.lang_array = lang_array;
          });
          this.dataSource = new MatTableDataSource(response.data);
          this.paginate = response.meta.pagination;
        }
      });
  }

  getFreelancerEmailTemplate() {
    this.projectsService.getFreelancerEmailTemplate()
      .subscribe((response: any) => {
        if (response) {  
          this.emailTemplateData = response.data;
        };
      });
  }

  selectMailProgress(evt: any, val) {
    if (evt.checked && this.ids_progress.indexOf(val.id == -1)) {
      this.ids_progress.push(val.id);
      this.login_enable = false;
    } else if (
      !evt.checked &&
      this.ids_progress.indexOf(val.id != -1)
    ) {
      let index = this.ids_progress.indexOf(val.id);
      this.ids_progress.splice(index, 1);
    }
    if (this.ids_progress.length == 0) {
      this.bulk_selected_progress = false;
      this.login_enable = true;
    }
    this.send_email_progress_data.freelacner_id = this.ids_progress;
  }

  selectBulkProgress(evt) {
    let check = false;
    this.dataSource1.data.forEach((element, key) => {
      element['check'] = evt.checked;
      if (evt.checked) {
        element.check = true;
        this.login_enable = false;
        this.ids_progress.push(element.id);
      } else {
        element.check = false;
        this.ids_progress = [];
        this.login_enable = true;
      }
    });
    if (check) {
      this.bulk_selected_progress = true;
    }
    this.send_email_progress_data.freelacner_id = this.ids_progress;
  }

  openDialogAddTranslator() {
    const dailogRef = this.dialog.open(BulkEmailTranslatorComponent, {
      width: '1000px',
      height: '600px',
      data: {templateData: this.emailTemplateData, freelacner: this.send_email_progress_data.freelacner_id}
    });

    dailogRef.afterClosed().subscribe((response) => {
          this.dataSource1.data.forEach((element, key) => {
            element['check'] = false;
          });
          this.bulk_selected_progress = false;
    });
  }


  openDialogAllTranslator(){
    this.send_email_progress_data.freelacner_id = this.all_freelancer_id;
    const dailogRef = this.dialog.open(BulkEmailTranslatorComponent, {
      width: '1000px',
      height: '600px',
      data: {templateData: this.emailTemplateData, freelacner: this.send_email_progress_data.freelacner_id}
    });

  }  


}


