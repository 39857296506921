import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { VendorService } from 'app/main/Services/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { MatTableDataSource } from '@angular/material';
import { environment } from 'environments/environment';
import { AddVendorDocComponent } from './add-vendor-doc/add-vendor-doc.component';
import { debug } from 'util';
@Component({
  selector: 'app-add-translator',
  templateUrl: './add-translator.component.html',
  styleUrls: ['./add-translator.component.scss']
})
export class AddTranslatorComponent implements OnInit {
  file_data: any = [];
  type: string = 'Add';
  addTranslatorForm: any;
  stage_id: any;
  reference_id: any;
  addIndex: any;
  file_id_data: any = [];
  table_data: any = [];
  table_subtitler_data: any = [];
  table_treping_data: any = [];
  table_vendor_marketing_skillprice_data: any = {
    'social_media_rate':'',
    'podcast_rate':'',
    'skill_service_id':'',
    'event_rate':'',
    'webinar_rate':'',
    'reel_rate':'',
    'ads_rate':'',
    'youtube_rate':'',
    'post600_rate':'',
    'post900_rate':'',
    'post1500_rate':'',
    'casestudy_rate':'',
    'scripts_rate':''
  };
  final_table_data: any = [];
  lang_name_update = [];
  today: Date = new Date();
  bulk_selected_progress: boolean = false;
  minDate = this.today;
  lang_combination_id: number;
  subtitler_lang_combination_id: number;
  treping_lang_combination_id: number;
  bulk_selected: boolean = false;
  displayedColumns = ['langPair', 'translationRate', 'proofindRate', 'dtpRate', 'qaRate', 'speed','accredited', 'actions'];
  displayedSubtitlerColumns = ['langPair','service', 'subtitlerRate','perWord', 'speed', 'actions'];
  displayedTrepingColumns = ['trepingLangPair','service', 'perHourRate','perMinutesRate', 'speed', 'flexibleRate','actions'];
  displayedMarketingSkillPriceColumns = [{label:'Social Media',key:'social_media_rate',service_id:12},{label:'Podcast',key:'podcast_rate',service_id:12}, {label:'Event',key:'event_rate',service_id:12},{label:'Webinar',key:'webinar_rate',service_id:12}, {label:'Reel',key:'reel_rate',service_id:12}, {label:'Ads',key:'ads_rate',service_id:12}, {label:'Youtube',key:'youtube_rate',service_id:12}, {label:'Post 600',key:'post600_rate',service_id:13}, {label:'Post 900',key:'post900_rate',service_id:13}, {label:'Post 1500',key:'post1500_rate',service_id:13},{label:'Case Study',key:'casestudy_rate',service_id:13},{label:'Scripts',key:'scripts_rate',service_id:13}];
  languageData: any = [];
  timezoneData: any = [];
  skillData: any = [];
  skillTrepingData: any = [];
  speciality_new_ids: any = [];
  speciality_new_data: any = [];
  specialityData: any = [];
  language: any = [];
  Subtitlerlanguage: any = [];
  Trepinglanguage: any = [];
  fileError: string = '';
  file_id: number;
  file_multiple_data: any = [];
  file_id_array: any = [];
  extra_file_id: any = [];
  contract_file_id: any = [];
  uploaded_files_array: any = [];
  uploaded_extra_files_array: any = [];
  uploaded_contract_files_array: any = [];
  selectedItems = [];
  // Coa start
  file_multiple_data1: any = [];
  file_multiple_data2: any = [];
  file_multiple_data3: any = [];
  fileError1: string = '';
  fileSuccess1: string;
  uploaded_coa_array: any = [];
  coa_id_array: any = [];
  per_word_cat_array: any = [68,69,70,71,72];
  show_per_word: boolean = false;
  certificates=[{id:'ATA',name:'ATA'},{id:'CTTIC',name:'CTTIC'},{id:'NAATI',name:'NAATI'},{id:'ATIA',name:'ATIA'},{id:'ATIO',name:'ATIO'},{id:'NAJIT',name:'NAJIT'},{id:'ATC',name:'ATC'},{id:'EUATC',name:'EUATC'},{id:'ATN',name:'ATN'},{id:'SWORN',name:'SWORN'}];
  //Coa end
  data: any =
    {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      translation_rate: '',
      proofreading_rate: '',
      dtp_rate: '',
      qa_rate: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
    };
  dataSubtitler: any =
    {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      skill_service_id: '',
      subtitle_rate: '',
      per_word: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
  };

  dataTreping: any =
    {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      skill_service_id: '',
      rate_hour: '',
      rate_min: '',
      speed: '',
      flexible_rate: false,
      bi_direction: false,
      bi_direction_test: ''
  };

  coa_array: any = [];
  dataSource: any;
  dataSubtitlerSource: any;
  dataTrepingSource: any;
  selected_tools: any = [];
  selected_days: any = [];
  final_data: any = {};
  removed_extra_document_data: any = {};
  fileSuccess: string;
  extraFileSuccess: string;
  contractFileSuccess: string;
  checked: boolean = true;
  id: number;
  selected_skills: any = [];
  dropdownSettings = {};
  dropdownList: any = [];
  BaseUrl_file: any;
  vendor_resume_data: string;
  vendor_extra_document_data: string;
  vendor_contract_document_data: string;
  coa_url: string
  coa_name: string;
  vendor_resume_name: string;
  vendor_extra_document_name: string;
  vendor_extra_document_id: any;
  vendor_contract_document_name: string;
  sms_notification: any;
  wtsp_notification: any;
  vo_sample: any;
  voiceover_category: any;
  voiceover_notes: any;
  untimed_vo_recording: any;
  timed_vo_recording: any;
  minimum_time: any;
  travel_cost: any;
  distance_willing_to_travel: any;
  cancellation_cost: any;



  constructor(private fb: FormBuilder, private router: Router, private vendorService: VendorService,
    private route: ActivatedRoute,
    private toastr: ToastrService, private dialog: MatDialog,
    private location: Location) { this.BaseUrl_file = environment.baseUrl_file; }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {

      this.addTranslatorForm = this.fb.group({
        password: [''],
        confirm_password: [''],
        first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        email: ['', [Validators.required, Validators.email]],
        email_sec: ['', [Validators.email]],
        middle_name: [''],
        gender: [''],
        timezone: [''],
        skype_id: [''],
        phone: [''],
        phone_sec: [''],
        address1: [''],
        city: [''],
        state: [''],
        postal_code: [''],
        country: [''],
        payment_method: ['PayPal'],
        translation_tools: ['1'],
        native_language_id: [''],
        education: [''],
        cover_letter: [''],
        payment_method_id: ['', [Validators.email]],
        tool_licence: ['1'],
        title: [''],
        specialities: [''],
        important_note: [''],
        pr_scores: [''],
        info: [''],
        subtitles_program_software: [''],
        category_level: [''],
        avt_status: [''],
        treping_status: [''],
        is_active: ['1'],
        on_boarding_date: [''],
        dob: [''],
        sms_notification: ['',[Validators.required]],
        wtsp_notification: ['',[Validators.required]],
        vo_sample: [''],
        voiceover_category: [''],
        voiceover_notes: [''],
        untimed_vo_recording: [''],
        timed_vo_recording: [''],
        minimum_time: [''],
        travel_cost: [''],
        distance_willing_to_travel: [''],
        cancellation_cost: [''],
        send_email_status: ['']
      });

      this.getTranslatorData();
      this.getLanguage();
      this.getTimezone();
      this.getSkillService();
      this.getTrepingSkillService();
      this.getSpeciality();
      this.getTranslatorStatus();
      this.getVendorDoc();
    }
    else {
      this.getSkillService();
      this.getTrepingSkillService();
      this.getLanguage();
      this.getTimezone();
      this.getSpeciality();
      // this.country_code=Dial.response;
      this.addTranslatorForm = this.fb.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirm_password: [
          '',
          [
            confirmPasswordValidator,
            Validators.required,
            Validators.minLength(6)
          ]
        ],
        first_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        last_name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
        email: ['', [Validators.required, Validators.email]],
        email_sec: ['', [Validators.email]],
        middle_name: [''],
        gender: [''],
        timezone: [''],
        skype_id: [''],
        phone: [''],
        phone_sec: [''],
        address1: [''],
        city: [''],
        state: [''],
        postal_code: [''],
        country: [''],
        payment_method_id: ['', [Validators.email]],
        payment_method: ['PayPal'],
        translation_tools: ['1'],
        native_language_id: [''],
        education: [''],
        cover_letter: [''],
        tool_licence: ['1'],
        // title: ['', Validators.compose([Validators.required])],
        title: [''],
        specialities: [''],
        important_note: [''],
        pr_scores: [''],
        info: [''],
        subtitles_program_software: [''],
        category_level: [''],
        avt_status: [''],
        treping_status: [''],
        is_active: ['1'],
        on_boarding_date: [''],
        dob: [''],
        sms_notification: [''],
        wtsp_notification: [''],
        vo_sample: [''],
        voiceover_category: [''],
        voiceover_notes: [''],
        untimed_vo_recording: [''],
        timed_vo_recording: [''],
        minimum_time: [''],
        travel_cost: [''],
        distance_willing_to_travel: [''],
        cancellation_cost: [''],
        send_email_status: ['0']
      });
    }

    this.dropdownSettings = {
      singleSelection: false,
      textId: 'id',
      textField: 'name',
      allowSearchFilter: true
    };
  }

  get f() {
    return this.addTranslatorForm.controls;
  }

  submit() {
    if (this.type == 'Add') {
      this.addData();
    } else {
      this.updateData();
    }
  }


  getTranslatorData() {
    this.coa_array = [];
    this.table_data = [];
    this.table_subtitler_data = [];
    this.table_treping_data = [];
    this.table_vendor_marketing_skillprice_data=[];
    this.speciality_new_ids = [];
    this.coa_id_array = [];
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          //debugger;
          this.dataSource = [];
          this.dataSubtitlerSource = [];
          this.dataTrepingSource = [];
          this.getSpeciality();
          this.type = 'Update';
         
          this.stage_id = response.data.stage_id;
          this.reference_id = response.data.reference_id;
          this.sms_notification = response.data.sms_notification;
          this.wtsp_notification = response.data.wtsp_notification;
          this.vo_sample = response.data.vo_sample;
          this.voiceover_category = response.data.voiceover_category;
          this.voiceover_notes = response.data.voiceover_notes;
          //this.untimed_vo_recording = response.data.untimed_vo_recording !=null ? response.data.untimed_vo_recording.toString() : response.data.untimed_vo_recording;
          //this.timed_vo_recording = response.data.timed_vo_recording !=null ? response.data.timed_vo_recording.toString() : response.data.timed_vo_recording.toString();
          if (response.data.tool_names) {
            this.selected_tools = response.data.tool_names.split(',');
          }
          let service_id="";
          response.data.vendorskill.data.forEach(element => {
            service_id=element.skill.data.service_id;
            this.selected_skills.push(element.skill_id);
          });
          if(service_id){
            this.dataSubtitler.skill_service_id=service_id;
            this.getSubtitlerSkillName({value:service_id})
          }
          response.data.vendortranslationlangs.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            this.table_data.push(element);
          });
          this.dataSource = new MatTableDataSource(this.table_data);

          response.data.vendorSubtitler.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            if(element.skillservice.data.name !=''){
              element.skill_service_name = element.skillservice.data.name;
            }
            this.table_subtitler_data.push(element);
          });
          this.dataSubtitlerSource = new MatTableDataSource(this.table_subtitler_data);

          response.data.vendorTreping.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            if(element.trepingSkillservice.data.name !=''){
              element.skill_service_name = element.trepingSkillservice.data.name;
            }
            this.table_treping_data.push(element);
          });

          if(response.data.vendorMarketingSkillPrice.data.length>0){
            this.table_vendor_marketing_skillprice_data=response.data.vendorMarketingSkillPrice.data[0];
          }else{
            this.table_vendor_marketing_skillprice_data={
              'social_media_rate':'',
              'podcast_rate':'',
              'skill_service_id':'',
              'event_rate':'',
              'webinar_rate':'',
              'reel_rate':'',
              'ads_rate':'',
              'youtube_rate':'',
              'post600_rate':'',
              'post900_rate':'',
              'post1500_rate':'',
              'casestudy_rate':'',
              'scripts_rate':''
            };
          }
          
          this.dataTrepingSource = new MatTableDataSource(this.table_treping_data);

          response.data.vendoravailabilities.data.forEach(element => {
            this.selected_days.push(element.day_name);
          });

          response.data.vendorspecialities.data.forEach(element => {
            this.speciality_new_ids.push(element.specialities.data);

          });

          if (response.data.vendorresume.data.length != 0) {
            response.data.vendorresume.data.forEach(element => {
              this.vendor_resume_data = this.BaseUrl_file + element.path;
              let resume_name = element.path.split('/');
              this.vendor_resume_name = resume_name[2];
            });
          }

          if (response.data.extradocument != null) {
              this.vendor_extra_document_data = this.BaseUrl_file + response.data.extradocument.data.path;
              this.vendor_extra_document_name = response.data.extradocument.data.name;
              this.vendor_extra_document_id = response.data.extradocument.data.id;
          }

          if (response.data.vendorcontract.data.length != 0) {
              response.data.vendorcontract.data.forEach(element =>{
                this.vendor_contract_document_data = this.BaseUrl_file + element.path;
                this.vendor_contract_document_name = element.name;
              });
             
          }

          if (response.data.vendorcoafiles.data.length != 0) {
            response.data.vendorcoafiles.data.forEach(element => {
              this.coa_url = this.BaseUrl_file + element.coafile.data.path;
              let coa_name = element.coafile.data.path.split('/');
              this.coa_name = coa_name[2];
              let coa_obj = {
                id: element.id,
                url: this.coa_url,
                name: this.coa_name,
                coa_file_id: element.coa_file_id
              }
              // this.coa_id_array()
              this.coa_id_array.push(element.coa_file_id);
              this.coa_array.push(coa_obj);
            });
          }

          this.addTranslatorForm.patchValue({
            address1: response.data.address1,
            email: response.data.email,
            email_sec: response.data.email_sec,
            gender: response.data.gender,
            timezone: response.data.timezone,
            city: response.data.city,
            country: response.data.country,
            cover_letter: response.data.cover_letter,
            education: response.data.education,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            middle_name: response.data.middle_name,
            native_language_id: response.data.native_language_id,
            payment_method: response.data.payment_method,
            payment_method_id: response.data.payment_method_id,
            phone: response.data.phone,
            phone_sec: response.data.phone_sec,
            postal_code: response.data.postal_code,
            skype_id: response.data.skype_id,
            state: response.data.state,
            title: response.data.title,
            specialities: this.speciality_new_ids,
            important_note: response.data.important_note,
            pr_scores: response.data.pr_scores,
            info: response.data.info,
            subtitles_program_software: response.data.subtitles_program_software,
            category_level: response.data.category_level,
            avt_status: response.data.avt_status,
            treping_status: response.data.treping_status,
            on_boarding_date: response.data.on_boarding_date,
            dob: response.data.dob,
            sms_notification: response.data.sms_notification,
            wtsp_notification: response.data.wtsp_notification,
            vo_sample: response.data.vo_sample,
            voiceover_category: response.data.voiceover_category,
            voiceover_notes: response.data.voiceover_notes,
            untimed_vo_recording: response.data.untimed_vo_recording,
            timed_vo_recording: response.data.timed_vo_recording,
            minimum_time: response.data.minimum_time,
            travel_cost: response.data.travel_cost,
            distance_willing_to_travel: response.data.distance_willing_to_travel,
            cancellation_cost: response.data.cancellation_cost,
          });  
          //console.log(this.addTranslatorForm);
          if (response.data.translation_tools != null) {
            this.addTranslatorForm.patchValue({
              translation_tools: response.data.translation_tools.toString(),
            });
          }

          if (response.data.tool_licence != null) {
            this.addTranslatorForm.patchValue({
              tool_licence: response.data.tool_licence.toString(),
            });
          }

          if (response.data.send_email_status != null) {
            this.addTranslatorForm.patchValue({
              send_email_status: response.data.send_email_status.toString(),
            });
          }

        }
      });
  }

  edit_getTranslatorData() {
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          this.dataSource = [];
          this.type = 'Update';
          response.data.vendortranslationlangs.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            this.table_data.push(element);
          });
          this.dataSource = new MatTableDataSource(this.table_data);
        }
      });
  }

  edit_getSubtitlerData() {
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          this.dataSubtitlerSource = [];
          this.type = 'Update';
          response.data.vendorSubtitler.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            if(element.skillservice.data.name !=''){
              element.skill_service_name = element.skillservice.data.name;
            }
            this.table_subtitler_data.push(element);
          });
          this.dataSubtitlerSource = new MatTableDataSource(this.table_subtitler_data);
        }
      });
  }

  edit_getTrepingData() {
   // debugger;
    this.vendorService.get(this.id)
      .subscribe((response) => {
        if (response) {
          this.dataTrepingSource = [];
          this.type = 'Update';
          response.data.vendorTreping.data.forEach(element => {
            if (element.bi_direction == 2) {
              element.bi_direction = '<>';
            }
            else if (element.bi_direction == 1 || element.bi_direction == 0) {
              element.bi_direction = '>';
            }
            if(element.trepingSkillservice.data.name !=''){
              element.skill_service_name = element.trepingSkillservice.data.name;
            }
            this.table_treping_data.push(element);
          });
          this.dataTrepingSource = new MatTableDataSource(this.table_treping_data);
        }
      });
  }

  editData(value, index) {
    this.addIndex = index;
    if(this.type == 'Add') {
      this.data.source_lang_id = value.source_lang_id;
      this.data.sourcelanguage = value.sourcelanguage;
      this.data.target_lang_id = value.target_lang_id;
      this.data.targetlanguage = value.targetlanguage;
      this.data.translation_rate = value.translation_rate;
      this.data.proofreading_rate = value.proofreading_rate;
      this.data.dtp_rate = value.dtp_rate;
      this.data.qa_rate = value.qa_rate;
      this.data.speed = value.speed;
      this.lang_combination_id = value.id;
      if ((typeof value.bi_direction=='string' && value.bi_direction == '>') || (typeof value.bi_direction=='boolean' && !value.bi_direction)) {
        this.data.bi_direction = false;
      }
      else {
        this.data.bi_direction = true;
      }
      this.data.certificates=[];
      if (value.sworn == '1') {
        this.data.certificates.push('SWORN')
      }

      if (value.ata_certified == '1') {
        this.data.certificates.push('ATA')
      }

      if (value.cttic == '1') {
        this.data.certificates.push('CTTIC')
      }

      if (value.naati == '1') {
        this.data.certificates.push('NAATI')
      }

      if (value.atia == '1') {
        this.data.certificates.push('ATIA')
      }

      if (value.atio == '1') {
        this.data.certificates.push('ATIO')
      }

      if (value.najit == '1') {
        this.data.certificates.push('NAJIT')
      }

      if (value.atc == '1') {
        this.data.certificates.push('ATC')
      }

      if (value.euatc == '1') {
        this.data.certificates.push('EUATC')
      }

      if (value.atn == '1') {
        this.data.certificates.push('ATN')
      }



      
    } else {
      this.data.source_lang_id = value.sourcelanguage.data.id;
      this.data.target_lang_id = value.targetlanguage.data.id;
      this.data.translation_rate = value.translation_rate;
      this.data.proofreading_rate = value.proofreading_rate;
      this.data.dtp_rate = value.dtp_rate;
      this.data.qa_rate = value.qa_rate;
      this.data.speed = value.speed;
      this.lang_combination_id = value.id;
      if ((typeof value.bi_direction=='string' && value.bi_direction == '>') || (typeof value.bi_direction=='boolean' && !value.bi_direction)) {
        this.data.bi_direction = false;
      }
      else {
        this.data.bi_direction = true;
      }this.data.certificates=[];
      if (value.sworn == '1') {
        this.data.certificates.push('SWORN')
      }
      
      if (value.ata_certified == '1') {
        this.data.certificates.push('ATA')
      }

      if (value.cttic == '1') {
        this.data.certificates.push('CTTIC')
      }

      if (value.naati == '1') {
        this.data.certificates.push('NAATI')
      }

      if (value.atia == '1') {
        this.data.certificates.push('ATIA')
      }

      if (value.atio == '1') {
        this.data.certificates.push('ATIO')
      }

      if (value.najit == '1') {
        this.data.certificates.push('NAJIT')
      }

      if (value.atc == '1') {
        this.data.certificates.push('ATC')
      }

      if (value.euatc == '1') {
        this.data.certificates.push('EUATC')
      }

      if (value.atn == '1') {
        this.data.certificates.push('ATN')
      }
    }
    
  }


  editSubtitlerData(value, index) {
    //debugger;
    this.addIndex = index;
    if(this.type == 'Add') {
      
      let per_word_price = null;
      this.per_word_cat_array.forEach(ele => {
        if(ele == value.skill_service_id){
            //console.log(value.skill_service_id);
            this.show_per_word = true;
            per_word_price = value.per_word;
        }
      });

      this.dataSubtitler.source_lang_id = value.source_lang_id;
      this.dataSubtitler.sourcelanguage = value.sourcelanguage;
      this.dataSubtitler.target_lang_id = value.target_lang_id;
      this.dataSubtitler.targetlanguage = value.targetlanguage;
      this.dataSubtitler.skill_service_id = value.skill_service_id;
      this.dataSubtitler.subtitle_rate = value.subtitle_rate;
      this.dataSubtitler.per_word = per_word_price;
      this.dataSubtitler.speed = value.speed;
      this.subtitler_lang_combination_id = value.id;
      if (value.bi_direction == '>') {
        this.dataSubtitler.bi_direction = false;
      }
      else {
        this.dataSubtitler.bi_direction = true;
      }
    } else {
      let per_word_price = null;
      this.per_word_cat_array.forEach(ele => {
        if(ele == value.skill_service_id){
           // console.log(value.skill_service_id);
            this.show_per_word = true;
            per_word_price = value.per_word;
        }
      });

      this.dataSubtitler.source_lang_id = value.sourcelanguage.data.id;
      this.dataSubtitler.target_lang_id = value.targetlanguage.data.id;
      this.dataSubtitler.skill_service_id = value.skill_service_id;
      this.dataSubtitler.subtitle_rate = value.subtitle_rate;
      this.dataSubtitler.per_word = per_word_price;
      this.dataSubtitler.speed = value.speed;
      this.subtitler_lang_combination_id = value.id;
      if (value.bi_direction == '>') {
        this.dataSubtitler.bi_direction = false;
      }
      else {
        this.dataSubtitler.bi_direction = true;
      }
    }
    
  }

  editTrepingData(value, index) {
    this.addIndex = index;
    if(this.type == 'Add') {
      this.dataTreping.source_lang_id = value.source_lang_id;
      this.dataTreping.sourcelanguage = value.sourcelanguage;
      this.dataTreping.target_lang_id = value.target_lang_id;
      this.dataTreping.targetlanguage = value.targetlanguage;
      this.dataTreping.skill_service_id = value.skill_service_id;
      this.dataTreping.rate_hour = value.rate_hour;
      this.dataTreping.rate_min = value.rate_min;
      this.dataTreping.speed = value.speed;
      this.dataTreping.flexible_rate = value.flexible_rate;
      this.treping_lang_combination_id = value.id;
      if (value.bi_direction == '>') {
        this.dataTreping.bi_direction = false;
      }
      else {
        this.dataTreping.bi_direction = true;
      }
      if (value.flexible_rate == "1") {
        this.dataTreping.flexible_rate = true;
      }
      else {
        this.dataTreping.flexible_rate = false;
      }

    } else {
      this.dataTreping.source_lang_id = value.sourcelanguage.data.id;
      this.dataTreping.target_lang_id = value.targetlanguage.data.id;
      this.dataTreping.skill_service_id = value.skill_service_id;
      this.dataTreping.rate_hour = value.rate_hour;
      this.dataTreping.rate_min = value.rate_min;
      this.dataTreping.speed = value.speed;
      this.dataTreping.flexible_rate = value.flexible_rate;
      this.treping_lang_combination_id = value.id;
      if (value.bi_direction == '>') {
        this.dataTreping.bi_direction = false;
      }
      else {
        this.dataTreping.bi_direction = true;
      }

      if (value.flexible_rate == "1") {
        this.dataTreping.flexible_rate = true;
      }
      else {
        this.dataTreping.flexible_rate = false;
      }

    }
    
  }

  updateData() {

    this.final_data = {};
    //debugger
    this.final_data = this.addTranslatorForm.value;
    //this.final_data.extra_document = this.extra_file_id;
    
    this.final_data.sms_notification = this.sms_notification;
    this.final_data.wtsp_notification = this.wtsp_notification;
    this.final_data.translation_tools = this.addTranslatorForm.value.translation_tools;
    this.final_data.on_boarding_date = this.dateFormat(this.addTranslatorForm.value.on_boarding_date);
    this.final_data.dob = this.dateFormat(this.addTranslatorForm.value.dob);
    this.final_data.specialities = this.getSpecialityId(this.addTranslatorForm.value.specialities);
    if (this.selected_tools.length > 0) {
      this.final_data.tool_names = this.selected_tools.toString();
    } else {
      this.final_data.tool_names = this.selected_tools;
    }
    this.final_data.vendor_skill = this.selected_skills;
    if (this.selected_days.length > 0) {
      this.final_data.availability = this.selected_days.toString();
    }
    else {
      this.final_data.availability = this.selected_days;
    }
    this.final_data.vendor_skill = this.selected_skills;
    if (this.file_id_array.length > 0) {
      this.final_data.resume_id = this.file_id_array;
    }

    if (this.contract_file_id.length > 0) {
       this.final_data.contract_file_id = this.contract_file_id;
    }

    if (this.extra_file_id.length > 0) {
       this.final_data.extra_document = this.extra_file_id;
    }

    if (this.coa_id_array.length > 0) {
      this.final_data.coa_files = this.coa_id_array;
    } else {
      this.final_data.coa_files = [];
    }
    this.final_data.file_id = [];
    this.final_data.stage_id = this.stage_id;
    this.final_data.vendor_marketing_price=[this.table_vendor_marketing_skillprice_data];
    //console.log(this.final_data);
    this.vendorService.updateTranslator(this.id, this.final_data).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success('Translator updated successfully');
         // this.location.back();
         this.router.navigate(['/marketing/marketing-dashboard']);
        }
      }
    );

  }

  // date format
  dateFormat(data) {
    let date = new Date(data);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return year + '-' + month + '-' + dt;
  }

  getSourceName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.data.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }


  getSubtitlerSourceName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.dataSubtitler.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getTrepingSourceName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.dataTreping.sourcelanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getTargetName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.data.targetlanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getSubtitlerTargetName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.dataSubtitler.targetlanguage.data.lang_name = element.lang_name;
      }
    });
  }
  getTrepingTargetName(evt) {
    this.languageData.data.forEach(element => {
      if (element.id == evt.value) {
        this.dataTreping.targetlanguage.data.lang_name = element.lang_name;
      }
    });
  }

  getTrepingSkillName(evt) {
   // debugger;
    // if(this.per_word_cat_array.indexOf(evt.value) !== -1){
    //     this.show_per_word = true;
    // }else{
    //     this.show_per_word = false;
    // }

    this.skillTrepingData.data.forEach(element => {
      if (element.id == evt.value) {
        this.dataTreping.skill_service_name = element.name;
        this.dataTreping.skill_service_id = element.id;
      }
    });
    //console.log(this.skillTrepingData);
  }
  getSubtitlerSkillName(evt) {
    debugger;
    this.vendorService.getSkillServiceById(evt.value)
      .subscribe((res) => {
        if (res) {
          this.skillData = res;
          if(this.per_word_cat_array.indexOf(evt.value) !== -1){
              this.show_per_word = true;
          }else{
              this.show_per_word = false;
          }
          this.skillData.data.forEach(element => {
            if (element.id == evt.value) {
              this.dataSubtitler.skill_service_name = element.name;
              this.dataSubtitler.skill_service_id = element.id;
            }
          });
        }
      });
    
  }

  

  addLanguageTable() {
    let bi_direction: String;
    let sworn: String;
    let ata_certified: String;
    let cttic: String;
    let naati: String;
    let atia: String;
    let atio: String;
    let najit: String;
    let atc: String;
    let euatc: String;
    let atn: String;
    this.data.certificates=Array.isArray(this.data.certificates)?this.data.certificates:[];
    this.dataSource = [];
    if (this.data.bi_direction == false) {
      bi_direction = '>'
      this.data.bi_direction = bi_direction;
    } else {
      bi_direction = '<>'
      this.data.bi_direction = bi_direction;
    }

    if (this.data.certificates.includes('SWORN')) {
      sworn = '1'
      this.data.sworn = sworn;
    } else {
      sworn = '0'
      this.data.sworn = sworn;
    }

    if (this.data.certificates.includes('ATA')) {
      ata_certified = '1'
      this.data.ata_certified = ata_certified;
    } else {
      ata_certified = '0'
      this.data.ata_certified = ata_certified;
    }

    if (this.data.certificates.includes('CTTIC')) {
      cttic = '1'
      this.data.cttic = cttic;
    } else {
      cttic = '0'
      this.data.cttic = cttic;
    }
    if (this.data.certificates.includes('NAATI')) {
      naati = '1'
      this.data.naati = naati;
    } else {
      naati = '0'
      this.data.naati = naati;
    }
    if (this.data.certificates.includes('ATIA')) {
      atia = '1'
      this.data.atia = atia;
    } else {
      atia = '0'
      this.data.atia = atia;
    }
    if (this.data.certificates.includes('ATIO')) {
      atio = '1'
      this.data.atio = atio;
    } else {
      atio = '0'
      this.data.atio = atio;
    }
    if (this.data.certificates.includes('NAJIT')) {
      najit = '1'
      this.data.najit = najit;
    } else {
      najit = '0'
      this.data.najit = najit;
    }
    if (this.data.certificates.includes('ATC')) {
      atc = '1'
      this.data.atc = atc;
    } else {
      atc = '0'
      this.data.atc = atc;
    }
    if (this.data.certificates.includes('EUATC')) {
      euatc = '1'
      this.data.euatc = euatc;
    } else {
      euatc = '0'
      this.data.euatc = euatc;
    }
    if (this.data.certificates.includes('ATN')) {
      atn = '1'
      this.data.atn = atn;
    } else {
      atn = '0'
      this.data.atn = atn;
    }


    if (this.data.sourcelanguage.data.lang_name == '') {
      this.toastr.error('please select source language');
      return false;
    }

    if (this.data.targetlanguage.data.lang_name != '' && this.data.sourcelanguage.data.lang_name != '') {
      if (this.data.sourcelanguage.data.lang_name == this.data.targetlanguage.data.lang_name) {
        this.toastr.error('please select unique language pair !!');
        return false;
      }

    }
    if (this.data.targetlanguage.data.lang_name == '') {
      this.toastr.error('please select target language');
      return false;
    }
    if (this.data.translation_rate == '') {
      this.toastr.error('please select translation rate');
      return false;
    }
    if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
      this.toastr.error('Translation rate never be negative or zero');
      return false;
    }
    if (this.data.proofreading_rate == '') {
      this.toastr.error('please select proofreading rate');
      return false;
    }

    if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
      this.toastr.error('Proofreading rate never be negative or zero');
      return false;
    }

    // if (this.data.dtp_rate == '') {
    //   this.toastr.error('please select Dtp rate');
    //   return false;
    // }

    // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
    //   this.toastr.error('Dtp rate never be negative or zero');
    //   return false;
    // }

    // if (this.data.qa_rate == '') {
    //   this.toastr.error('please select Dtp rate');
    //   return false;
    // }

    // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
    //   this.toastr.error('MTPE rate never be negative or zero');
    //   return false;
    // }
    if (this.data.speed == '') {
      this.toastr.error('please select speed');
      return false;
    }
    if(this.language.length > 0 && this.language[this.addIndex] !== undefined) {
      this.language[this.addIndex].source_lang_id = this.data.source_lang_id;
      this.language[this.addIndex].sourcelanguage = this.data.sourcelanguage;
      this.language[this.addIndex].target_lang_id = this.data.target_lang_id; 
      this.language[this.addIndex].targetlanguage= this.data.targetlanguage;
      this.language[this.addIndex].translation_rate = this.data.translation_rate; 
      this.language[this.addIndex].proofreading_rate = this.data.proofreading_rate;
      this.language[this.addIndex].dtp_rate =  this.data.dtp_rate;
      this.language[this.addIndex].qa_rate = this.data.qa_rate;
      this.language[this.addIndex].speed =  this.data.speed; 
      this.language[this.addIndex].lang_combination_id = this.lang_combination_id; 
      this.language[this.addIndex].bi_direction = bi_direction;
      this.language[this.addIndex].sworn = sworn;
      this.language[this.addIndex].ata_certified = ata_certified;
      this.language[this.addIndex].cttic = cttic;
      this.language[this.addIndex].naati = naati;
      this.language[this.addIndex].atia = atia;
      this.language[this.addIndex].atio = atio;
      this.language[this.addIndex].najit = najit;
      this.language[this.addIndex].atc = atc;
      this.language[this.addIndex].euatc = euatc;
      this.language[this.addIndex].atn = atn;
      if (this.data.bi_direction == '>') {
        this.data.bi_direction = false;
      }
      else {
        this.data.bi_direction = true;
      }
    } else {
      this.language.push(this.data);
    }
    
    
    this.dataSource = new MatTableDataSource(this.language);
    this.data = {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      translation_rate: '',
      dtp_rate: '',
      proofreading_rate: '',
      qa_rate: '',
      speed: '',
      bi_direction: false,
      sworn: false,
      ata_certified: false,
      certificates:[],
      bi_direction_test: ''
    };
    this.addIndex = null;
  }

  addSubtitlerLanguageTable() {
    //debugger;
    let bi_direction: String;
    this.dataSubtitlerSource = [];
    if (this.dataSubtitler.bi_direction == false) {
      bi_direction = '>'
      this.dataSubtitler.bi_direction = bi_direction;
    } else {
      bi_direction = '<>'
      this.dataSubtitler.bi_direction = bi_direction;
    }
    if (this.dataSubtitler.sourcelanguage.data.lang_name == '') {
      this.toastr.error('please select avt source language');
      return false;
    }

    // if (this.dataSubtitler.targetlanguage.data.lang_name != '' && this.dataSubtitler.sourcelanguage.data.lang_name != '') {
    //   if (this.dataSubtitler.sourcelanguage.data.lang_name == this.dataSubtitler.targetlanguage.data.lang_name) {
    //     this.toastr.error('please select avt unique language pair !!');
    //     return false;
    //   }

    // }

    if (this.dataSubtitler.targetlanguage.data.lang_name == '') {
      this.toastr.error('please select avt target language');
      return false;
    }

    if (this.dataSubtitler.skill_service_id == '') {
      this.toastr.error('please select avt service');
      return false;
    }

    // if (this.dataSubtitler.subtitle_rate == '') {
    //   this.toastr.error('please select avt rate');
    //   return false;
    // }
    // if (this.dataSubtitler.subtitle_rate < 0 || this.dataSubtitler.subtitle_rate == 0) {
    //   this.toastr.error('Avt rate never be negative or zero');
    //   return false;
    // }
    // if (this.dataSubtitler.per_word == '') {
    //   this.toastr.error('please select avt rate per word');
    //   return false;
    // }
    // if (this.dataSubtitler.per_word < 0 || this.dataSubtitler.per_word == 0 || this.dataSubtitler.per_word != null) {
    //   this.toastr.error('Avt rate per word never be negative or zero');
    //   return false;
    // }
    
    if (this.dataSubtitler.speed == '') {
      this.toastr.error('please select avt speed');
      return false;
    }
    if(this.Subtitlerlanguage.length > 0 && this.Subtitlerlanguage[this.addIndex] !== undefined) {
      this.Subtitlerlanguage[this.addIndex].source_lang_id = this.dataSubtitler.source_lang_id;
      this.Subtitlerlanguage[this.addIndex].sourcelanguage = this.dataSubtitler.sourcelanguage
      this.Subtitlerlanguage[this.addIndex].target_lang_id = this.dataSubtitler.target_lang_id 
      this.Subtitlerlanguage[this.addIndex].targetlanguage = this.dataSubtitler.targetlanguage 
      this.Subtitlerlanguage[this.addIndex].skill_service_id  = this.dataSubtitler.skill_service_id 
      this.Subtitlerlanguage[this.addIndex].skill_service_name  = this.dataSubtitler.skill_service_name 
      this.Subtitlerlanguage[this.addIndex].subtitle_rate  = this.dataSubtitler.subtitle_rate 
      this.Subtitlerlanguage[this.addIndex].per_word  = this.dataSubtitler.per_word 
      this.Subtitlerlanguage[this.addIndex].speed          =  this.dataSubtitler.speed 
      this.Subtitlerlanguage[this.addIndex].subtitler_lang_combination_id = this.subtitler_lang_combination_id 
      this.Subtitlerlanguage[this.addIndex].bi_direction = bi_direction
      if (this.dataSubtitler.bi_direction == '>') {
        this.dataSubtitler.bi_direction = false;
      }
      else {
        this.dataSubtitler.bi_direction = true;
      }
    } else {
      this.Subtitlerlanguage.push(this.dataSubtitler);
    }
    this.dataSubtitlerSource = new MatTableDataSource(this.Subtitlerlanguage);
    this.dataSubtitler = {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      skill_service_id: '',
      subtitle_rate: '',
      per_word: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
    };
    this.addIndex = null;
  }

  addTrepingLanguageTable() {
    //debugger;
    let bi_direction: String;
    this.dataTrepingSource = [];
    if (this.dataTreping.bi_direction == false) {
      bi_direction = '>'
      this.dataTreping.bi_direction = bi_direction;
    } else {
      bi_direction = '<>'
      this.dataTreping.bi_direction = bi_direction;
    }
    if (this.dataTreping.sourcelanguage.data.lang_name == '') {
      this.toastr.error('please select treping source language');
      return false;
    }

    // if (this.dataSubtitler.targetlanguage.data.lang_name != '' && this.dataSubtitler.sourcelanguage.data.lang_name != '') {
    //   if (this.dataSubtitler.sourcelanguage.data.lang_name == this.dataSubtitler.targetlanguage.data.lang_name) {
    //     this.toastr.error('please select avt unique language pair !!');
    //     return false;
    //   }

    // }

    if (this.dataTreping.targetlanguage.data.lang_name == '') {
      this.toastr.error('please select treping target language');
      return false;
    }

    if (this.dataTreping.skill_service_id == '') {
      this.toastr.error('please select treping service');
      return false;
    }

    if (this.dataTreping.speed == '') {
      this.toastr.error('please select treping speed');
      return false;
    }
    if(this.Trepinglanguage.length > 0 && this.Trepinglanguage[this.addIndex] !== undefined) {
      this.Trepinglanguage[this.addIndex].source_lang_id          = this.dataTreping.source_lang_id;
      this.Trepinglanguage[this.addIndex].sourcelanguage          = this.dataTreping.sourcelanguage
      this.Trepinglanguage[this.addIndex].target_lang_id          = this.dataTreping.target_lang_id 
      this.Trepinglanguage[this.addIndex].targetlanguage          = this.dataTreping.targetlanguage 
      this.Trepinglanguage[this.addIndex].skill_service_id        = this.dataTreping.skill_service_id 
      this.Trepinglanguage[this.addIndex].skill_service_name      = this.dataTreping.skill_service_name 
      this.Trepinglanguage[this.addIndex].rate_hour               = this.dataTreping.rate_hour 
      this.Trepinglanguage[this.addIndex].rate_min                = this.dataTreping.rate_min 
      this.Trepinglanguage[this.addIndex].speed                   =  this.dataTreping.speed 
      this.Trepinglanguage[this.addIndex].flexible_rate           =  this.dataTreping.flexible_rate 
      this.Trepinglanguage[this.addIndex].treping_lang_combination_id = this.treping_lang_combination_id 
      this.Trepinglanguage[this.addIndex].bi_direction = bi_direction
      if (this.dataTreping.bi_direction == '>') {
        this.dataTreping.bi_direction = false;
      }
      else {
        this.dataTreping.bi_direction = true;
      }
    } else {
      this.Trepinglanguage.push(this.dataTreping);
    }
    console.log(this.Trepinglanguage);
    this.dataTrepingSource = new MatTableDataSource(this.Trepinglanguage);
    this.dataTreping = {
      source_lang_id: '',
      sourcelanguage: {
        data: {
          lang_name: ''
        }
      },
      targetlanguage: {
        data: {
          lang_name: ''
        }
      },
      target_lang_id: '',
      target_lang_name: '',
      skill_service_id: '',
      subtitle_rate: '',
      per_word: '',
      speed: '',
      bi_direction: false,
      bi_direction_test: ''
    };
    this.addIndex = null;
  }

  addLanguageTableApi() {
    let bi_direction:String;
    let sworn: String;
    let ata_certified: String;
    let cttic: String;
    let naati: String;
    let atia: String;
    let atio: String;
    let najit: String;
    let atc: String;
    let euatc: String;
    let atn: String;
    this.dataSource = [];
    this.data.certificates=Array.isArray(this.data.certificates)?this.data.certificates:[];
    if(typeof this.data.bi_direction=='string' && this.data.bi_direction=='>'){
      this.data.bi_direction=false;
    }
    if (this.data.bi_direction == false) {
      bi_direction = '>'
    } else {
      bi_direction = '<>'
    }

    if (this.data.certificates.includes('SWORN')) {
      sworn = '1'
      this.data.sworn = sworn;
    } else {
      sworn = '0'
      this.data.sworn = sworn;
    }

    if (this.data.certificates.includes('ATA')) {
      ata_certified = '1'
      this.data.ata_certified = ata_certified;
    } else {
      ata_certified = '0'
      this.data.ata_certified = ata_certified;
    }

    if (this.data.certificates.includes('CTTIC')) {
      cttic = '1'
      this.data.cttic = cttic;
    } else {
      cttic = '0'
      this.data.cttic = cttic;
    }
    if (this.data.certificates.includes('NAATI')) {
      naati = '1'
      this.data.naati = naati;
    } else {
      naati = '0'
      this.data.naati = naati;
    }
    if (this.data.certificates.includes('ATIA')) {
      atia = '1'
      this.data.atia = atia;
    } else {
      atia = '0'
      this.data.atia = atia;
    }
    if (this.data.certificates.includes('ATIO')) {
      atio = '1'
      this.data.atio = atio;
    } else {
      atio = '0'
      this.data.atio = atio;
    }
    if (this.data.certificates.includes('NAJIT')) {
      najit = '1'
      this.data.najit = najit;
    } else {
      najit = '0'
      this.data.najit = najit;
    }
    if (this.data.certificates.includes('ATC')) {
      atc = '1'
      this.data.atc = atc;
    } else {
      atc = '0'
      this.data.atc = atc;
    }
    if (this.data.certificates.includes('EUATC')) {
      euatc = '1'
      this.data.euatc = euatc;
    } else {
      euatc = '0'
      this.data.euatc = euatc;
    }
    if (this.data.certificates.includes('ATN')) {
      atn = '1'
      this.data.atn = atn;
    } else {
      atn = '0'
      this.data.atn = atn;
    }

    //debugger;
    if (this.lang_combination_id) {

      this.table_data = [];
      if (this.data) {
        this.vendorService.updateLanguage(this.lang_combination_id, this.data)
          .subscribe((response) => {
            if (response) {
              this.lang_combination_id = null;
              this.edit_getTranslatorData();
              this.data = {
                source_lang_id: '',
                sourcelanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                targetlanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                target_lang_id: '',
                target_lang_name: '',
                translation_rate: '',
                proofreading_rate: '',
                qa_rate: '',
                dtp_rate: '',
                speed: '',
                certificates:[],
                bi_direction: false,
                bi_direction_test: ''
              };
            }
          }, (error) => {
            if (error) {
              this.toastr.error('Please first select all fields.');
            }
          });
      }

    }
    else {
      this.table_data = [];
      if (this.data.sourcelanguage.data.lang_name == '') {
        this.toastr.error('please select source language');
        return false;
      }
      if (this.data.targetlanguage.data.lang_name == '') {
        this.toastr.error('please select target language');
        return false;
      }
      if (this.data.translation_rate == '') {
        this.toastr.error('please select translation rate');
        return false;
      }
      if (this.data.translation_rate < 0 || this.data.translation_rate == 0) {
        this.toastr.error('Translation rate never be negative or zero');
        return false;
      }
      if (this.data.proofreading_rate == '') {
        this.toastr.error('please select proofreading rate');
        return false;
      }

      if (this.data.proofreading_rate < 0 || this.data.proofreading_rate == 0) {
        this.toastr.error('Proofreading rate never be negative or zero');
        return false;
      }

      // if (this.data.dtp_rate == '') {
      //   this.toastr.error('please select Dtp rate');
      //   return false;
      // }

      // if (this.data.dtp_rate < 0 || this.data.dtp_rate == 0) {
      //   this.toastr.error('Dtp rate never be negative or zero');
      //   return false;
      // }

      // if (this.data.qa_rate == '') {
      //   this.toastr.error('please select Dtp rate');
      //   return false;
      // }

      // if (this.data.qa_rate < 0 || this.data.qa_rate == 0) {
      //   this.toastr.error('MTPE rate never be negative or zero');
      //   return false;
      // }
      if (this.data.speed == '') {
        this.toastr.error('please select speed');
        return false;
      }

      if (this.data.speed < 0 || this.data.speed == 0) {
        this.toastr.error('Speed never be negative or zero');
        return false;
      }
      this.data.vendor_id = this.id;
      this.data.table_data = this.table_data;
      this.vendorService.addLanguage_edit(this.data)
        .subscribe((response) => {
          if (response) {
            this.edit_getTranslatorData();
            this.data = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              certificates:[],
              target_lang_id: '',
              target_lang_name: '',
              translation_rate: '',
              proofreading_rate: '',
              dtp_rate: '',
              qa_rate: '',
              speed: '',
              bi_direction: false,
              bi_direction_test: ''
            };
          }
        });
    }
  }


  addSubtitlerLanguageTableApi() {
   //debugger;
    if (this.subtitler_lang_combination_id) {
      this.table_subtitler_data = [];
      if (this.dataSubtitler) {
        this.vendorService.updateSubtitlerLanguage(this.subtitler_lang_combination_id, this.dataSubtitler)
          .subscribe((response) => {
            if (response) {
              this.subtitler_lang_combination_id = null;
              this.edit_getSubtitlerData();
              this.dataSubtitler = {
                source_lang_id: '',
                sourcelanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                targetlanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                target_lang_id: '',
                target_lang_name: '',
                skill_service_id: '',
                subtitle_rate: '',
                per_word: '',
                speed: '',
                bi_direction: false,
                bi_direction_test: ''
              };
            }
          }, (error) => {
            if (error) {
              this.toastr.error('Please first select all fields.');
            }
          });
      }

    }
    else {
      //debugger;
      this.table_subtitler_data = [];
      if (this.dataSubtitler.sourcelanguage.data.lang_name == '') {
        this.toastr.error('please select avt source language');
        return false;
      }
      if (this.dataSubtitler.targetlanguage.data.lang_name == '') {
        this.toastr.error('please select avt target language');
        return false;
      }
      
      if (this.dataSubtitler.skill_service_id == '') {
        this.toastr.error('please select avt service');
        return false;
      }
      // if (this.dataSubtitler.subtitle_rate == '') {
      //   this.toastr.error('please select avt rate');
      //   return false;
      // }
      // if (this.dataSubtitler.subtitle_rate < 0 || this.dataSubtitler.subtitle_rate == 0) {
      //   this.toastr.error('Avt rate never be negative or zero');
      //   return false;
      // }
      // if (this.dataSubtitler.per_word == '') {
      //   this.toastr.error('please select avt rate per word');
      //   return false;
      // }
      // if (this.dataSubtitler.per_word < 0 || this.dataSubtitler.per_word == 0) {
      //   this.toastr.error('Avt rate per word never be negative or zero');
      //   return false;
      // }

      if (this.dataSubtitler.speed == '') {
        this.toastr.error('please select avt speed');
        return false;
      }

      if (this.dataSubtitler.speed < 0 || this.dataSubtitler.speed == 0) {
        this.toastr.error('Speed never be negative or zero');
        return false;
      }
      this.dataSubtitler.vendor_id = this.id;
      this.dataSubtitler.table_subtitler_data = this.table_subtitler_data;
      this.vendorService.addSubtitlerLanguage_edit(this.dataSubtitler)
        .subscribe((response) => {
          if (response) {
            this.edit_getSubtitlerData();
            this.dataSubtitler = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              target_lang_id: '',
              target_lang_name: '',
              skill_service_id: '',
              subtitle_rate: '',
              per_word: '',
              speed: '',
              bi_direction: false,
              bi_direction_test: ''
            };
          }
        });
    }
  }
  addTrepingLanguageTableApi() {
   //debugger;
    if (this.treping_lang_combination_id) {
      this.table_treping_data = [];
      if (this.dataTreping) {
        this.vendorService.updateTrepingLanguage(this.treping_lang_combination_id, this.dataTreping)
          .subscribe((response) => {
            if (response) {
              this.treping_lang_combination_id = null;
              this.edit_getTrepingData();
              //this.getTranslatorData();
              this.dataTreping = {
                source_lang_id: '',
                sourcelanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                targetlanguage: {
                  data: {
                    lang_name: ''
                  }
                },
                target_lang_id: '',
                target_lang_name: '',
                skill_service_id: '',
                rate_hour: '',
                rate_min: '',
                speed: '',
                bi_direction: false,
                flexible_rate: false,
                bi_direction_test: ''
              };
            }
          }, (error) => {
            if (error) {
              this.toastr.error('Please first select all fields.');
            }
          });
      }

    } else {
      //debugger;
      this.table_treping_data = [];
      if (this.dataTreping.sourcelanguage.data.lang_name == '') {
        this.toastr.error('please select treping source language');
        return false;
      }
      if (this.dataTreping.targetlanguage.data.lang_name == '') {
        this.toastr.error('please select treping target language');
        return false;
      }
      
      if (this.dataTreping.skill_service_id == '') {
        this.toastr.error('please select treping service');
        return false;
      }

      if (this.dataTreping.rate_hour == '' && this.dataTreping.rate_min == '' ) {
        this.toastr.error('please select atlest one rate either hour rate or minute rate');
        return false;
      }

      if (this.dataTreping.speed == '') {
        this.toastr.error('please select treping speed');
        return false;
      }

      if (this.dataTreping.speed < 0 || this.dataTreping.speed == 0) {
        this.toastr.error('Speed never be negative or zero');
        return false;
      }
      this.dataTreping.vendor_id = this.id;
      this.dataTreping.table_subtitler_data = this.table_subtitler_data;
      this.vendorService.addTrepingLanguage_edit(this.dataTreping)
        .subscribe((response) => {
          if (response) {
            this.edit_getTrepingData();
            this.dataTreping = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              target_lang_id: '',
              target_lang_name: '',
              skill_service_id: '',
              rate_hour: '',
              rate_min: '',
              speed: '',
              bi_direction: false,
              flexible_rate: false,
              bi_direction_test: ''
            };
          }
        });
    }
  }

  deleteSubtitlerData(obj: any, index: any) {
    if (this.id) {
      this.vendorService.deleteVendorSubtitlerLanguage(obj.id)
        .subscribe((response) => {
          this.toastr.success("delete successfully");
          this.getTranslatorData();
          this.data = {
            source_lang_id: '',
            sourcelanguage: {
              data: {
                lang_name: ''
              }
            },
            targetlanguage: {
              data: {
                lang_name: ''
              }
            },
            certificates:[],
            target_lang_id: '',
            target_lang_name: '',
            skill_service_id: '',
            subtitle_rate: '',
            per_word: '',
            speed: '',
            bi_direction: false,
            bi_direction_test: ''
          };
        });
    }
    else {
      this.Subtitlerlanguage.splice(index, 1);
      this.dataSubtitlerSource = new MatTableDataSource(this.Subtitlerlanguage);
    }


  }

  deleteTrepingData(obj: any, index: any){
     if (this.id) {
      this.vendorService.deleteVendorTrepingLanguage(obj.id)
        .subscribe((response) => {
          this.toastr.success("delete successfully");
          this.getTranslatorData();
          this.dataTreping = {
              source_lang_id: '',
              sourcelanguage: {
                data: {
                  lang_name: ''
                }
              },
              targetlanguage: {
                data: {
                  lang_name: ''
                }
              },
              target_lang_id: '',
              target_lang_name: '',
              skill_service_id: '',
              rate_hour: '',
              rate_min: '',
              speed: '',
              bi_direction: false,
              flexible_rate: false,
              bi_direction_test: ''
            };
        });
    }
    else {
      this.Trepinglanguage.splice(index, 1);
      this.dataSubtitlerSource = new MatTableDataSource(this.Trepinglanguage);
    }
  }

  deleteData(obj: any, index: any) {
    if (this.id) {
      this.vendorService.deleteVendorLanguage(obj.id)
        .subscribe((response) => {
          this.toastr.success("delete successfully");
          this.getTranslatorData();

          this.data = {
            source_lang_id: '',
            sourcelanguage: {
              data: {
                lang_name: ''
              }
            },
            targetlanguage: {
              data: {
                lang_name: ''
              }
            },
            target_lang_id: '',
            target_lang_name: '',
            translation_rate: '',
            proofreading_rate: '',
            qa_rate: '',
            dtp_rate: '',
            speed: '',
            bi_direction: false,
            sworn: false,
            ata_certified: false,
            cttic : false,
            naati : false,
            atia : false,
            atio : false,
            najit : false,
            atc : false,
            euatc : false,
            atn : false,
            certificates:[],
            bi_direction_test: ''
          };
        });
    }
    else {
      this.language.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.language);

    }


  }

  tools(evt: any) {
    if (evt.checked && this.selected_tools.indexOf(evt.source.value == -1)) {
      this.selected_tools.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_tools.indexOf(evt.source.value != -1)) {
      let index = this.selected_tools.indexOf(evt.source.value);
      this.selected_tools.splice(index, 1);
    }
  }

  Available_days(evt: any) {
    if (evt.checked && this.selected_days.indexOf(evt.source.value == -1)) {
      this.selected_days.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_days.indexOf(evt.source.value != -1)) {
      let index = this.selected_days.indexOf(evt.source.value);
      this.selected_days.splice(index, 1);
    }
  }

  getLanguage() {
    this.vendorService.getAllLanguages()
      .subscribe((res) => {
        if (res) {
          this.languageData = res;
        }
      });
  }

  getTimezone(){
    this.vendorService.getAllTimezone()
      .subscribe((res) => {
        if (res) {
          this.timezoneData = res;
        }
      });
  }
  getSkillService(){
    if(this.dataSubtitler.skill_service_id){
      this.vendorService.getSkillServiceById(this.dataSubtitler.skill_service_id).subscribe((res) => {
          if (res) {
            this.skillData = res;
          }
      });
    }
  }

  getTrepingSkillService(){
    this.vendorService.getTrepingSkillService()
      .subscribe((res) => {
        if (res) {
          this.skillTrepingData = res;
        }
      });
  }



  getSpeciality() {
    this.vendorService.getSpeciality()
      .subscribe((res) => {
        if (res) {
          this.specialityData = res;
          this.dropdownList = res;
        }
      });
  }

  getTranslatorStatus() {
    this.vendorService.getTranslatorStatus(this.id)
      .subscribe((res) => {
        if (res) {
          this.addTranslatorForm.patchValue({
            is_active: res.user_detail.status.toString()
          });
        }
      });
  }

  getVendorDoc() {
    this.vendorService.getVendorDoc(this.id)
      .subscribe((res) => {
        if (res) {
          this.file_data = res.vendor_docs;
        }
      });
  }
  addData() {
    this.final_data = {};
    this.final_data = this.addTranslatorForm.value;
    this.final_data.extra_document = this.extra_file_id;
    this.final_data.contract_file_id = this.contract_file_id;
    this.final_data.sms_notification = this.sms_notification;
    this.final_data.wtsp_notification = this.wtsp_notification;
    // this.final_data.vo_sample = this.vo_sample;
    // this.final_data.voiceover_category = this.voiceover_category;
    // this.final_data.voiceover_notes = this.voiceover_notes;
    // this.final_data.untimed_vo_recording = this.untimed_vo_recording;
    // this.final_data.timed_vo_recording = this.timed_vo_recording;
    this.final_data.specialities = this.getSpecialityId(this.addTranslatorForm.value.specialities);
    this.final_data.availability = this.selected_days.toString();
    this.final_data.vendor_skill = this.selected_skills;
    if (this.selected_tools.length > 0) {
      this.final_data.tool_names = this.selected_tools.toString();
    } else {
      this.final_data.tool_names = this.selected_tools;
    }
    this.final_data.vendor_skill = this.selected_skills;
    if (this.selected_days.length > 0) {
      this.final_data.availability = this.selected_days.toString();
    }
    else {
      this.final_data.availability = this.selected_days;
    }
    if (this.file_id_array.length > 0) {
      this.final_data.resume_id = this.file_id_array;
    }
    if (this.coa_id_array.length > 0) {
      this.final_data.coa_files = this.coa_id_array;
    }
    else {
      this.final_data.coa_files = [];
    }
    this.final_data.file_id = [];
    this.final_data.language = this.language.map(lang=>{
      lang.bi_direction=(lang.bi_direction==false || lang.bi_direction=='>')?false:true;
      return lang;
    });;
    this.final_data.vendor_subtitler = this.Subtitlerlanguage;
    this.final_data.vendor_treping = this.Trepinglanguage;
    this.final_data.vendor_marketing_price=[this.table_vendor_marketing_skillprice_data];
    //console.log('this.final_data');
    //console.log(this.final_data);
    this.vendorService.add(this.final_data,'marketing').subscribe(
      resposne => {
        if (resposne) {
          if (resposne.status == 409) {
            this.toastr.error(resposne.message);
          }
          else {
            this.toastr.success(resposne.message);
            this.router.navigate(['marketing/freelancer']);
          }
        }
      }
    );
  }

  getSpecialityId(res: any) {
    if (res.constructor === String) {
      return res;
    }
    else if (res.length > 0 && res.constructor === Array) {
      let id = '';
      res.forEach(element => {
        id += element.id + ', ';
      });
      id = id.slice(0, -2);
      return id;
    }

  }

  onFileUpload() {
    this.file_multiple_data = [];
    const fileUpload = document.getElementById('docUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = 'File size should not be more than 20MB.';
            return false;
          }
          this.file_multiple_data.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addDocument(this.file_multiple_data);
    };
    fileUpload.click();
  }


  addDocument(file) {
    this.fileSuccess = '';
    this.uploaded_files_array = [];
    this.file_id_array = [];
    const uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }

    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_files_array.push(response.data);
        response.data.forEach(element => {
          this.file_id_array.push(element.id);
        });
        this.fileSuccess = 'Uploaded successfully!!';
      });
  }

  onExtraFileUpload() {
    this.file_multiple_data2 = [];
    const fileUpload = document.getElementById('extraDocUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = 'File size should not be more than 20MB.';
            return false;
          }
          this.file_multiple_data2.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }

      this.addExtraDocument(this.file_multiple_data2);
    };
    fileUpload.click();
  }

  addExtraDocument(file) {
    this.extraFileSuccess = '';
    this.uploaded_extra_files_array = [];
    this.extra_file_id = [];
    const uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }

    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {
        this.uploaded_extra_files_array.push(response.data);
        response.data.forEach(element => {
          this.extra_file_id.push(element.id);
          //this.extra_file_id = element.id;
        });
        this.extraFileSuccess = 'Uploaded successfully!!';
      });
  }

  onContractFileUpload() {
    this.file_multiple_data3 = [];
    const fileUploads = document.getElementById('contractUpload') as HTMLInputElement;
    fileUploads.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUploads.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError = 'File size should not be more than 20MB.';
            return false;
          }
          this.file_multiple_data3.push(file);
        }
        else {
          this.fileError = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }

      this.addContractDocument(this.file_multiple_data3);
    };
    fileUploads.click();
  }

  addContractDocument(file) {
    this.contractFileSuccess = '';
    this.uploaded_contract_files_array = [];
    this.contract_file_id = [];
    const uploadContractData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadContractData.append('file[]', file[x]);
    }

    this.vendorService.uploadFile(uploadContractData)
      .subscribe((response) => {
        this.uploaded_contract_files_array.push(response.data);
        response.data.forEach(element => {
          this.contract_file_id.push(element.id);
          //this.contract_file_id = element.id;
        });
        this.contractFileSuccess = 'Uploaded successfully!!';
      });
  }

  // COA FILE UPLOAD
  onCoaUpload() {
    this.file_multiple_data1 = [];
    const fileUpload = document.getElementById('coaUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      let filecheck = ['jpeg', 'jpg', 'png', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip', 'txt'];
      var re = /(?:\.([^.]+))?$/;
      var files = fileUpload.files;
      var file;
      // loop through files
      for (var i = 0; i < files.length; i++) {
        // get item
        file = files.item(i);
        //or
        file = files[i];
        var ext = re.exec(file.name)[1];
        if (file && filecheck.indexOf(ext) != -1) {
          let size = file.size / 1024 / 1024;
          if (size > 20) {
            this.fileError1 = 'File size should not be more than 20MB.';
            return;
          }
          this.file_multiple_data1.push(file);
        }
        else {
          this.fileError1 = 'Only accepts jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx, zip, ppt, txt or pptx format.';
        }
      }
      this.addCoa(this.file_multiple_data1);

    };

    fileUpload.click();
  }

  // addCoa
  addCoa(file) {
    this.fileSuccess1 = '';
    // this.uploaded_coa_array = [];
    // this.coa_id_array = [];
    var uploadData = new FormData();
    var ins = file.length;
    for (var x = 0; x < ins; x++) {
      uploadData.append('file[]', file[x]);
    }
    this.vendorService.uploadFile(uploadData)
      .subscribe((response) => {

        response.data.forEach(element => {
          if (this.id) {
            this.coa_array.push(element);
          } else {
            this.uploaded_coa_array.push(element);
          }

          this.coa_id_array.push(element.id);
        });

        if (this.id) {
          this.vendorCoaFile(this.coa_id_array);
        }
        this.fileSuccess1 = 'Uploaded successfully!!';
      });
  }

  vendorCoaFile(coaFileId: any) {
    let obj = {
      coa_files: coaFileId
    }
    this.vendorService.VendorsCoaFile(this.id, obj)
      .subscribe((response) => {
        this.getTranslatorData();
      });
  }

  deleteCoa(id: any) {
    const coa_array_index = this.uploaded_coa_array.findIndex(
      (x) => x.id === id
    );
    if (coa_array_index !== -1) {
      this.uploaded_coa_array.splice(coa_array_index, 1);
    }

    const coa_id_index = this.coa_id_array.findIndex(
      (x) => x === id
    );
    if (coa_id_index !== -1) {
      this.coa_id_array.splice(coa_id_index, 1);
    }
  }

  deleteExtra(id){
    this.removed_extra_document_data = {};
    this.removed_extra_document_data.vendor_id = this.id;
    this.removed_extra_document_data.extra_document = id;
    //console.log(this.removed_extra_document_data);
    this.vendorService.removeExtradocument(this.removed_extra_document_data).subscribe(
      resposne => {
        if (resposne) {
          this.toastr.success(resposne.message);
          //window.location.reload();
          this.getTranslatorData();
          this.getLanguage();
          this.getTimezone();
          this.getSkillService();
          this.getTrepingSkillService();
          this.getSpeciality();
          this.getTranslatorStatus();
          this.getVendorDoc();
          this.uploaded_extra_files_array = [];
          this.vendor_extra_document_name = null;
          this.extraFileSuccess = '';
          this.file_multiple_data2 = [];
          this.extra_file_id = [];
        }
      });
  }

  deleteCoaEdit(obj: any) {
    this.vendorService.deleteCoaFile(obj.id)
      .subscribe((response) => {
        this.toastr.success("delete successfully");
        this.deleteCoaEditArray(obj);
      });
  }

  deleteExtraEdit(obj: any)
  {
    //debugger;
    //console.log(obj);
    this.uploaded_extra_files_array = [];
    this.vendor_extra_document_name = null;
    this.extraFileSuccess = '';
    this.file_multiple_data2 = [];
    this.extra_file_id = [];
  
  }

  deleteCoaEditArray(obj: any) {
    const coa_array_index = this.coa_array.findIndex(
      (x) => x.id === obj.id
    );
    if (coa_array_index !== -1) {
      this.coa_array.splice(coa_array_index, 1);
    }

    const coa_id_index = this.coa_id_array.findIndex(
      (x) => x === obj.coa_file_id
    );
    if (coa_id_index !== -1) {
      this.coa_id_array.splice(coa_id_index, 1);
    }
  }

  // COA END
  back() {
    this.location.back();
  }

  Reset() {
    this.addTranslatorForm.reset();
    this.location.back();
  }


  onCreate() {
    const dailogRef = this.dialog.open(AddVendorDocComponent, {
      width: '70%',
      data: { vendor_id: this.id, reference_id: this.reference_id }
    })

    dailogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.getVendorDoc();
      }
    });
  }

  Skills(evt: any) {
    if (evt.checked && this.selected_skills.indexOf(evt.source.value == -1)) {
      this.selected_skills.push(evt.source.value);
    }
    else if (!evt.checked && this.selected_skills.indexOf(evt.source.value != -1)) {
      let index = this.selected_skills.indexOf(evt.source.value);
      this.selected_skills.splice(index, 1);
    }
  }

  smsNotification(evt:any){
    if (evt.checked) {
      this.sms_notification = 1;
    }else{
      this.sms_notification = 0;
    }
  }

  wtspNotification(evt:any){
    if (evt.checked) {
      this.wtsp_notification = 1;
    }else{
      this.wtsp_notification = 0;
    }
  }

  onItemSelect(item: any) {

  }
  onSelectAll(items: any) {

  }

  onItemDeSelect(items: any) {

  }

  onDeSelectAll(items: any) {
  }

  selectFile(evt, res) {
    //debugger;
    //console.log(res);
    if (evt.checked && this.file_id_data.indexOf(res.id == -1)) {
      this.file_id_data.push(res.uuid);
    }
    else if (!evt.checked && this.file_id_data.indexOf(res.id != -1)) {
      let index = this.file_id_data.indexOf(res.uuid);
      this.file_id_data.splice(index, 1);
    }
    //console.log(this.file_id_data);
    //this.DownloadZip();
  }


  // Remove Project File
  removeVendorDocFile(res: any) {
    this.vendorService.deleteVendorDoc(res.uuid).subscribe(
      (response) => {
        if (response) {
          this.toastr.success(" Delete Vendor Doc File  Successfully!!");
          this.getVendorDoc();
        }
      }
    );
  }

}   

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }
  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('confirm_password');
  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }
  return { passwordsNotMatching: true };
};

