import { OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from '../../shared.service';
import { VendorService } from 'app/main/Services/vendor.service';
import { ProjectsService } from 'app/main/Services/projects.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BulkEmailTranslatorComponent } from './bulk-email-translator/bulk-email-translator.component';
var TranslatorComponent = /** @class */ (function () {
    function TranslatorComponent(_fuseNavigationService, vendorService, projectsService, toastr, router, dialog) {
        this._fuseNavigationService = _fuseNavigationService;
        this.vendorService = vendorService;
        this.projectsService = projectsService;
        this.toastr = toastr;
        this.router = router;
        this.dialog = dialog;
        this.paginate = {};
        this.pageIndex = 1;
        this.query_approve = '';
        this.filterSpecialization = '';
        this.notArchive = 'Unavailable,Blacklisted,Closed';
        this.filter_src_lang = '';
        this.filter_target_lang = '';
        this.fQuery = '';
        this.ids_progress = [];
        this.all_freelancer_id = [];
        this.bulk_selected_progress = false;
        this.send_email_progress_data = {};
        this.emailTemplateData = '';
        this.login_enable = true;
        this.specialityData = [];
        this.query_user_sort = '';
        this.languageData = [];
        this.pageSize = 10;
        this.filter_native_lang = null;
        this.fdata = {
            specialization: '',
            Source: '',
            Target: '',
            Native: ''
        };
        this.ApprovaldisplayedColumns = ['ref', 'first_name', 'native_language', 'category_level', 'language', 'Email', 'status'];
    }
    TranslatorComponent.prototype.ngOnInit = function () {
        this.getFreelancerEmailTemplate();
        this.getLanguage();
        this.getSpeciality();
        this.getTranslators();
        this.getAllVendorIds();
    };
    TranslatorComponent.prototype.applyFilterApproved = function (filterValue) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    TranslatorComponent.prototype.getLanguage = function () {
        var _this = this;
        this.vendorService.getAllLanguages()
            .subscribe(function (res) {
            if (res) {
                _this.languageData = res;
            }
        });
    };
    TranslatorComponent.prototype.getAllVendorIds = function () {
        var _this = this;
        var query = '?page=1&per_page=1000&not_archive=' + this.notArchive;
        this.vendorService.getAllTranslator(query).subscribe(function (res) {
            res.data.forEach(function (elem) {
                _this.all_freelancer_id.push(elem.id);
            });
        });
    };
    TranslatorComponent.prototype.getSpeciality = function () {
        var _this = this;
        this.vendorService.getSpeciality()
            .subscribe(function (res) {
            if (res) {
                res.forEach(function (element) {
                    element.name = element.name.charAt(0).toUpperCase() + element.name.slice(1);
                    ;
                });
                _this.specialityData = res;
            }
        });
    };
    TranslatorComponent.prototype.getTranslators = function () {
        var _this = this;
        var query = '';
        var t_specialization = localStorage.getItem('t_specialization');
        var specializationFilter = JSON.parse(t_specialization);
        if (specializationFilter !== '' || specializationFilter !== null) {
            this.fdata.specialization = specializationFilter;
        }
        var t_source_lang = localStorage.getItem('t_source_lang');
        var source_langFilter = JSON.parse(t_source_lang);
        if (source_langFilter !== '' || source_langFilter !== null) {
            this.fdata.Source = source_langFilter;
        }
        var t_target_lang = localStorage.getItem('t_target_lang');
        var target_langFilter = JSON.parse(t_target_lang);
        if (target_langFilter !== '' || target_langFilter !== null) {
            this.fdata.Target = target_langFilter;
        }
        var t_native_filter = localStorage.getItem('t_native_filter');
        var nativeFilter = JSON.parse(t_native_filter);
        if (nativeFilter !== '' || nativeFilter !== null) {
            this.fdata.Native = nativeFilter;
        }
        var translatorFilter = localStorage.getItem('filter');
        var filterObject = JSON.parse(translatorFilter);
        var translatorPagination = localStorage.getItem('pagination');
        var translatorObject = JSON.parse(translatorPagination);
        if (translatorFilter != null && filterObject.filter) {
            query = filterObject.filter;
        }
        else if (translatorPagination !== null && translatorObject.table_name == 'translatorPagination') {
            query = '?page=' + translatorObject.page + '&per_page=' + translatorObject.per_page + '&not_archive=' + this.notArchive;
        }
        else {
            query = '?page=1&per_page=200&not_archive=' + this.notArchive;
        }
        this.vendorService.getAllTranslator(query)
            .subscribe(function (response) {
            if (response) {
                _this.dataSource1 = response;
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -2);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    //sorting
    TranslatorComponent.prototype.sortUser = function (evt) {
        var _this = this;
        if (evt.direction == '') {
            evt.direction = 'asc';
        }
        this.query_user_sort = '?page=' + this.pageIndex + '&per_page=' + this.pageSize + '&sort=' + evt.active + '&order=' + evt.direction + '&not_archive=' + this.notArchive;
        this.vendorService.getAllTranslator(this.query_user_sort)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -2);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    TranslatorComponent.prototype.paginateData = function (event) {
        var _this = this;
        var aQuery = '';
        this.pageIndex = event.pageIndex + 1;
        if (this.pageIndex < 0) {
            this.pageIndex = 1;
        }
        this.pageSize = event.pageSize;
        this.query_approve = '?page=' + this.pageIndex + '&per_page=' + this.pageSize + '&not_archive=' + this.notArchive;
        var pagination = { table_name: 'translatorPagination', page: this.pageIndex, per_page: this.pageSize };
        SharedService.set(SharedService.pagination, JSON.stringify(pagination));
        if (this.filterSpecialization !== '') {
            aQuery += '&specialization=' + this.filterSpecialization;
            var Specialization = this.filterSpecialization;
            SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
        }
        else if (this.fdata.specialization) {
            aQuery += '&specialization=' + this.fdata.specialization;
        }
        if (this.filter_src_lang !== '') {
            aQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            aQuery += '&source_lang=' + this.fdata.Source;
        }
        if (this.filter_target_lang !== '') {
            aQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            aQuery += '&target_lang=' + this.fdata.Target;
        }
        if (this.filter_native_lang !== null) {
            aQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            aQuery += '&native_lang=' + this.fdata.Native;
        }
        this.query_approve += aQuery;
        var Filter = { table_name: 'translatorFilter', filter: this.query_approve };
        SharedService.set(SharedService.Filter, JSON.stringify(Filter));
        this.vendorService.getAllTranslator(this.query_approve)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -2);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    TranslatorComponent.prototype.editData = function (value) {
        this.router.navigate(['/hr/translator/' + value.id]);
    };
    TranslatorComponent.prototype.onFilter = function (evt, type) {
        var _this = this;
        this.fQuery = '?page=1&per_page=10';
        if ((type == 'speciality' && evt.value != '' && evt.value !== undefined)) {
            this.filterSpecialization = evt.value;
            this.fQuery += '&specialization=' + this.filterSpecialization;
            var Specialization = this.filterSpecialization;
            SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
        }
        else if (type == 'speciality' && evt.value == '') {
            this.filterSpecialization = evt.value;
            var Specialization = this.filterSpecialization;
            SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
        }
        else if (this.filterSpecialization !== '') {
            this.fQuery += '&specialization=' + this.filterSpecialization;
            var Specialization = this.filterSpecialization;
            SharedService.set(SharedService.t_specialization, JSON.stringify(Specialization));
        }
        else if (this.fdata.specialization) {
            this.fQuery += '&specialization=' + this.fdata.specialization;
        }
        if ((type == 's_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_src_lang = evt.value;
            this.fQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (type == 's_lang' && evt.value == '') {
            this.filter_src_lang = evt.value;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.filter_src_lang !== '') {
            this.fQuery += '&source_lang=' + this.filter_src_lang;
            var filter_src_lang = this.filter_src_lang;
            SharedService.set(SharedService.t_source_lang, JSON.stringify(filter_src_lang));
        }
        else if (this.fdata.Source) {
            this.fQuery += '&source_lang=' + this.fdata.Source;
        }
        if ((type == 't_lang' && evt.value != '' && evt.value !== undefined)) {
            this.filter_target_lang = evt.value;
            this.fQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (type == 't_lang' && evt.value == '') {
            this.filter_target_lang = evt.value;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.filter_target_lang !== '') {
            this.fQuery += '&target_lang=' + this.filter_target_lang;
            var filter_target_lang = this.filter_target_lang;
            SharedService.set(SharedService.t_target_lang, JSON.stringify(filter_target_lang));
        }
        else if (this.fdata.Target) {
            this.fQuery += '&target_lang=' + this.fdata.Target;
        }
        if ((type == 'native_lang' && evt.value != null && evt.value !== undefined)) {
            this.filter_native_lang = evt.value;
            this.fQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (type == 'native_lang' && evt.value == null) {
            this.filter_native_lang = evt.value;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.filter_native_lang != null) {
            this.fQuery += '&native_lang=' + this.filter_native_lang;
            var filter_native_lang = this.filter_native_lang;
            SharedService.set(SharedService.t_native_filter, JSON.stringify(filter_native_lang));
        }
        else if (this.fdata.Native) {
            this.fQuery += '&native_lang=' + this.fdata.Native;
        }
        var Filter = { table_name: 'translatorFilter', filter: this.fQuery };
        SharedService.set(SharedService.Filter, JSON.stringify(Filter));
        var query = this.fQuery;
        this.vendorService.getAllTranslator(query)
            .subscribe(function (response) {
            if (response) {
                response.data.forEach(function (elem) {
                    var specs = '';
                    elem.vendorspecialities.data.forEach(function (res) {
                        if (res.specialities != null) {
                            specs += res.specialities.data.name + ', ';
                        }
                    });
                    elem.specs = specs.slice(0, -1);
                });
                response.data.forEach(function (elem) {
                    var lang = '';
                    var lang_array = [];
                    var translation_rate_value1 = '';
                    elem.vendortranslationlangs.data.forEach(function (res) {
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        if (res.bi_direction == 2) {
                            res.bi_direction = '<>';
                        }
                        else {
                            res.bi_direction = '>';
                        }
                        if (res.translation_rate != null) {
                            translation_rate_value1 += res.translation_rate;
                        }
                        lang = res.sourcelanguage.data.lang_name + res.bi_direction + res.targetlanguage.data.lang_name + ',';
                        lang_array.push(lang);
                    });
                    if (lang_array.length > 0) {
                        var test = void 0;
                        test = lang_array[lang_array.length - 1];
                        lang_array[lang_array.length - 1] = test.slice(0, -1);
                    }
                    elem.lang_array = lang_array;
                    response.data.lang_array = lang_array;
                });
                _this.dataSource = new MatTableDataSource(response.data);
                _this.paginate = response.meta.pagination;
            }
        });
    };
    TranslatorComponent.prototype.getFreelancerEmailTemplate = function () {
        var _this = this;
        this.projectsService.getFreelancerEmailTemplate()
            .subscribe(function (response) {
            if (response) {
                _this.emailTemplateData = response.data;
            }
            ;
        });
    };
    TranslatorComponent.prototype.selectMailProgress = function (evt, val) {
        if (evt.checked && this.ids_progress.indexOf(val.id == -1)) {
            this.ids_progress.push(val.id);
            this.login_enable = false;
        }
        else if (!evt.checked &&
            this.ids_progress.indexOf(val.id != -1)) {
            var index = this.ids_progress.indexOf(val.id);
            this.ids_progress.splice(index, 1);
        }
        if (this.ids_progress.length == 0) {
            this.bulk_selected_progress = false;
            this.login_enable = true;
        }
        this.send_email_progress_data.freelacner_id = this.ids_progress;
    };
    TranslatorComponent.prototype.selectBulkProgress = function (evt) {
        var _this = this;
        var check = false;
        this.dataSource1.data.forEach(function (element, key) {
            element['check'] = evt.checked;
            if (evt.checked) {
                element.check = true;
                _this.login_enable = false;
                _this.ids_progress.push(element.id);
            }
            else {
                element.check = false;
                _this.ids_progress = [];
                _this.login_enable = true;
            }
        });
        if (check) {
            this.bulk_selected_progress = true;
        }
        this.send_email_progress_data.freelacner_id = this.ids_progress;
    };
    TranslatorComponent.prototype.openDialogAddTranslator = function () {
        var _this = this;
        var dailogRef = this.dialog.open(BulkEmailTranslatorComponent, {
            width: '1000px',
            height: '600px',
            data: { templateData: this.emailTemplateData, freelacner: this.send_email_progress_data.freelacner_id }
        });
        dailogRef.afterClosed().subscribe(function (response) {
            _this.dataSource1.data.forEach(function (element, key) {
                element['check'] = false;
            });
            _this.bulk_selected_progress = false;
        });
    };
    TranslatorComponent.prototype.openDialogAllTranslator = function () {
        this.send_email_progress_data.freelacner_id = this.all_freelancer_id;
        var dailogRef = this.dialog.open(BulkEmailTranslatorComponent, {
            width: '1000px',
            height: '600px',
            data: { templateData: this.emailTemplateData, freelacner: this.send_email_progress_data.freelacner_id }
        });
    };
    return TranslatorComponent;
}());
export { TranslatorComponent };
