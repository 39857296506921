import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { CrmService } from 'app/main/Services/crm.service'; // Replace with your actual service path
import { TableUtil } from 'app/utils/tableUtil'; // Replace with your actual utility path

@Component({
  selector: 'app-gclid',
  templateUrl: './gclid.component.html',
  styleUrls: ['./gclid.component.scss']
})
export class GclidComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  totalRecords: number = 0;
  pageSize: number = 10;
  currentPage: number = 0;
  sortDirection: string = '';
  sortField: string = '';
  selectedType: string = 'ld';
  types = [
    { value: 'ld', viewValue: 'Lead' },
    { value: 'op', viewValue: 'Opportunity' }
  ];

  filterfieldMapping={'amount':"amount","date_entered":"date_entered","gclid":"gclid_c","msclkid":"msclkid_c","payment_status":"payment_status_c","currency":"currencyName","utm_medium_c":"utm_medium_c","utm_source_c":"utm_source_c"};
  ApprovaldisplayedColumns: string[] = ['type', 'gclid', 'msclkid','utm_medium_c','utm_source_c', 'currency', 'amount', 'payment_status','tracking_description_c', 'date_entered'];
  dataSource1: any;

  constructor(private crmService: CrmService) {}

  ngOnInit() {
    this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
  }

  getGclids(page: number, size: number, sortField: string = '', sortDirection: string = '') {
    let queryParams = `?page=${page + 1}&per_page=${size}`;

    if (this.selectedType) {
      queryParams += `&type=${this.selectedType}`;
    }

    if (sortField && sortDirection) {
      queryParams += `&sort_by=${sortField}&sort_direction=${sortDirection}`;
    }

    this.crmService.getAllGclid(queryParams).subscribe((response: any) => {
      this.totalRecords = response.total; // Assuming your backend returns total record count
      this.dataSource1 = response.data; // Assuming your backend returns paginated data in "data"
      this.dataSource = new MatTableDataSource(this.dataSource1);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter() {
    // Reset to first page when filter is applied
    this.currentPage = 0;
    this.paginator.firstPage();
    this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
  }

  onSortChange(sort: Sort) {
    this.sortField = this.filterfieldMapping[sort.active];
    this.sortDirection = sort.direction;
    this.getGclids(this.currentPage, this.pageSize, this.sortField, this.sortDirection);
  }

  export() {
    let data = [['Type', 'Google Click ID (GCLID)', 'Microsoft Click ID (MSCLKID)','UTM Medium','UTM Source', 'Currency', 'Amount', 'Payment Status', 'Date Entered']];
    this.dataSource1.forEach((record: any) => {
      let paymentDetail = `Method: ${record.payment_method_c} Status: ${record.payment_status_c} / ${record.approval_payment_dd_c}`;
      data.push([
        record.type == 'ld' ? 'Lead' : 'Opportunity',
        record.gclid_c,
        record.msclkid_c,
        record.utm_medium_c,
        record.utm_source_c,
        record.currencyName ? record.currencyName + ':' + record.currencySymbol : 'US Dollars : $',
        record.type == 'ld' ? record.opportunity_amount : record.amount,
        paymentDetail,
        record.date_entered
      ]);
    });
    TableUtil.exportArrayToExcel(data);
  }
}
